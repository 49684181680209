<script setup>
import { ref, watch } from "vue";
import { useCollection } from "@/services/useCollection";
import AppMenuItem from "./AppMenuItem.vue";

// Define fixed price ranges
const priceRanges = ref([
  { id: 1, range: "0 - 1,000" },
  { id: 2, range: "1,000 - 5,000" },
  { id: 5, range: "5,000 - 10,000 " },
  { id: 6, range: "10,000 - 50,000 " },
  { id: 7, range: "50,000 - 100,000 " },
  { id: 8, range: "100,000+ " },
  // Add more price ranges as needed
]);

const model = ref([
  {
    label: "Home",
    items: [
      {
        label: "Dashboard",
        icon: "pi pi-fw pi-home",
        to: "/auction/dashboard",
      },
      {
        label: "My bids",
        icon: "pi pi-fw pi-bookmark",
        to: "/auction/mybids",
      },
    ],
  },
  {
    label: "Category",
    items: [],
  },
  {
    label: "Price Ranges",
    items: [],
  },
]);

// Manually add an item to the "Category" label's items array
model.value
  .find((item) => item.label === "Category")
  .items.push({
    label: "Show All",
    icon: "pi pi-fw pi-tags",
    //to: "/manual/item",
    category: 123,
    to: "/auction/category/All",
  });

// Use the custom hook to fetch category items
const { collectionData: categoryItems, loading } = useCollection("category");

watch(categoryItems, (newItems) => {
  if (!loading.value) {
    model.value
      .find((item) => item.label === "Category")
      .items.push(
        ...newItems.map((item) => ({
          label: item.name,
          icon: "pi pi-fw pi-tag",
          category: item.id,
          categoryName: item.name,
        }))
      );
    model.value.find((item) => item.label === "Price Ranges").items =
      priceRanges.value.map((range) => ({
        label: range.range,
        range: range.range,
      }));
  }
});
</script>

<template>
  <div>
    <ul class="layout-menu">
      <template v-for="(item, i) in model" :key="item.label">
        <app-menu-item
          v-if="!item.separator"
          :item="item"
          :index="i"
        ></app-menu-item>
        <li v-if="item.separator" class="menu-separator"></li>
      </template>
    </ul>
  </div>
</template>

<style lang="scss" scoped></style>
