<template>
  <div class="card shadow-lg mx-4">
    <div class="card-body p-3">
      <div class="row gx-4">
        <div class="col-auto my-auto">
          <div class="h-100">
            <h5 class="mb-1">Manually Add Bid for User</h5>
            <p class="mb-0 text-sm font-weight-bold">
              Select a user, auction item, and enter the bid amount
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="multisteps-form">
          <form class="mb-8 multisteps-form__form" @submit.prevent="submitBid">
            <div id="add-bid" class="card mt-4">
              <div class="card-header col-12 col-sm-6">
                <label class="text-lg">Step 1. Select User</label>
                <div class="listbox-container">
                  <input
                    type="text"
                    placeholder="Filter users"
                    @input="filterUsers"
                    class="listbox-filter"
                  />
                  <Listbox
                    v-model="selectedUser"
                    :options="filteredUsers"
                    optionLabel="name"
                    class="custom-listbox"
                    listStyle="max-height:250px"
                    ><template #option="slotProps">
                      <div class="flex items-center">
                        <div>
                          {{ slotProps.option.firstName }}
                          {{ slotProps.option.lastName }}
                        </div>
                      </div>
                    </template>
                  </Listbox>
                </div>
              </div>
              <div class="card-header col-12 col-sm-6">
                <label class="text-lg">Step 2. Select Auction Item</label>
                <div class="listbox-container">
                  <input
                    type="text"
                    placeholder="Filter auction items"
                    @input="filterAuctionItems"
                    class="listbox-filter"
                  />
                  <Listbox
                    v-model="selectedItem"
                    :options="filteredAuctionItems"
                    optionLabel="name"
                    class="custom-listbox"
                    listStyle="max-height:250px"
                  >
                    <template #option="slotProps">
                      <div class="flex items-center">
                        <div>
                          {{ slotProps.option.itemNumber }} -
                          {{ slotProps.option.name }}
                        </div>
                      </div>
                    </template>
                  </Listbox>
                </div>
                <!-- Show current bid -->
                <div v-if="currentBid" class="current-bid">
                  <Message severity="info"
                    >Current Bid is: {{ currentBid }}</Message
                  >
                </div>
              </div>

              <div class="sm:col-12">
                <BiddingActions
                  v-if="
                    selectedItem && selectedUser && !selectedItem.isDonationItem
                  "
                  :item="selectedItem"
                  :user="selectedUser"
                />
                <BiddingDonationActions
                  v-if="
                    selectedItem && selectedUser && selectedItem.isDonationItem
                  "
                  :item="selectedItem"
                  :user="selectedUser"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onBeforeMount, onMounted, onBeforeUnmount } from "vue";
import { useStore } from "vuex";

import Message from "primevue/message";
import BiddingActions from "@/views/auction/components/BiddingActions.vue";
import BiddingDonationActions from "@/views/auction/components/BiddingActionsDonation.vue";

import Listbox from "primevue/listbox";
import {
  collection,
  addDoc,
  getDocs,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase"; // Ensure you have your Firebase config correctly imported

const store = useStore();

const users = ref([]);
const auctionItems = ref([]);
const filteredUsers = ref([]);
const filteredAuctionItems = ref([]);
const selectedUser = ref(null);
const selectedItem = ref(null);
const bidAmount = ref("");
const isBidAmountError = ref(false);
const isAnonymous = ref(false);
const feedbackMessage = ref("");
const feedbackType = ref(""); // "success" or "error"
const currentBid = ref(0);
const bidCounter = ref(0);

const fetchUsers = async () => {
  const querySnapshot = await getDocs(collection(db, "users"));
  users.value = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    name: `${doc.data().firstName} ${doc.data().lastName}`,
    ...doc.data(),
  }));

  // Sort users by name A-Z
  users.value.sort((a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });

  filteredUsers.value = users.value; // Initialize filteredUsers with all users
};

const fetchAuctionItems = async () => {
  const querySnapshot = await getDocs(collection(db, "auctionItems"));
  auctionItems.value = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    itemNumber: doc.data().itemNumber, // Ensure itemNumber is fetched
    name: doc.data().name,
    ...doc.data(),
  }));

  // Sort auction items by itemNumber (assuming itemNumber is a number)
  auctionItems.value.sort((a, b) => a.itemNumber - b.itemNumber);

  filteredAuctionItems.value = auctionItems.value; // Initialize filteredAuctionItems with all auction items
};

// Fetch the current bid for the selected auction item
const fetchCurrentBid = async (itemId) => {
  const itemDoc = await getDoc(doc(db, "auctionItems", itemId));
  const itemData = itemDoc.data();
  currentBid.value = itemData.latestBid || itemData.startbid;
  bidCounter.value = itemData.bidCount || 0;
};

// Watch for changes to the selected item and fetch the current bid
watch(selectedItem, async (newValue) => {
  if (newValue) {
    await fetchCurrentBid(newValue.id);
  }
});

// Watch for changes to the selected user and clear the selected item
watch(selectedUser, () => {
  selectedItem.value = null; // Clear the selected item when the selected user changes
});

// Filter users for listbox
const filterUsers = (event) => {
  const query = event.target.value.toLowerCase();
  filteredUsers.value = users.value.filter((user) => {
    return user.name.toLowerCase().includes(query);
  });
};

// Filter auction items for listbox
const filterAuctionItems = (event) => {
  const query = event.target.value.toLowerCase();

  filteredAuctionItems.value = auctionItems.value.filter((item) => {
    const itemNumberString = item.itemNumber.toString().toLowerCase();
    const itemNameString = item.name.toLowerCase();

    return itemNumberString.includes(query) || itemNameString.includes(query);
  });
};

// Submit the bid
const submitBid = async () => {
  if (!bidAmount.value.trim() || isNaN(Number(bidAmount.value))) {
    isBidAmountError.value = true; // Set error flag if bid amount is invalid
    feedbackMessage.value = "Please enter a valid bid amount.";
    feedbackType.value = "error";
    return;
  }

  const newBidAmount = Number(bidAmount.value);
  if (newBidAmount <= currentBid.value) {
    isBidAmountError.value = true; // Set error flag if bid amount is less than or equal to the current bid
    feedbackMessage.value = `Bid amount must be higher than the current bid of ${currentBid.value}.`;
    feedbackType.value = "error";
    return;
  } else {
    isBidAmountError.value = false; // Reset error flag if validation passes
  }

  try {
    const itemRef = doc(db, "auctionItems", selectedItem.value.id);
    const bidderName = isAnonymous.value
      ? "Anonymous"
      : selectedUser.value.name;

    await addDoc(collection(itemRef, "bids"), {
      userBid: newBidAmount,
      maxBid: newBidAmount,
      comment: "Manual bid added",
      timestamp: new Date(),
      user: {
        uid: selectedUser.value.id,
        name: bidderName,
      },
    });

    await updateDoc(itemRef, {
      latestBid: newBidAmount, // Update the latestBid on the auction item
      latestBidder: bidderName, // Update the latestBidder on the auction item
      latestBidderID: selectedUser.value.id, // Update the latestBidderID on the auction item
      bidCount: bidCounter.value + 1, // Increment the bid count
    });

    console.log("Bid added for auction item ID: ", selectedItem.value.id);
    feedbackMessage.value = "Bid successfully added!";
    feedbackType.value = "success";

    // Clear all inputs
    selectedUser.value = null;
    selectedItem.value = null;
    bidAmount.value = "";
    isAnonymous.value = false;
    currentBid.value = 0;
  } catch (e) {
    console.error("Error adding bid: ", e);
    feedbackMessage.value =
      "Error adding bid. Verify that User and Auction Item has been selected. Please try again.";
    feedbackType.value = "error";
  }
};

const body = document.getElementsByTagName("body")[0];

onBeforeMount(() => {
  store.state.layout = "custom";
  store.state.showNavbar = false;
  store.state.showFooter = true;
  store.state.hideConfigButton = true;
});

onMounted(async () => {
  await fetchUsers();
  await fetchAuctionItems();
  store.state.isAbsolute = true;
});

onBeforeUnmount(() => {
  store.state.isAbsolute = false;
  store.state.layout = "default";
  store.state.showNavbar = true;
  store.state.showFooter = true;
  store.state.hideConfigButton = false;
  body.classList.remove("new-product");
});
</script>
<style scoped>
.listbox-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.listbox-filter {
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.custom-listbox .p-listbox-list {
  max-height: 200px; /* Adjust this value as needed */
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.custom-listbox .p-listbox-item {
  padding: 0.5rem;
  cursor: pointer;
}

.custom-listbox .p-listbox-item.p-highlight {
  background-color: #007bff;
  color: white;
}

.custom-listbox .p-listbox-item:hover {
  background-color: #f1f1f1;
}

.current-bid {
  margin-top: 1rem;
  font-weight: bold;
}

.alert {
  margin-top: 1rem;
  padding: 0.75rem 1.25rem;
  border: 1px solid transparent;
  border-radius: 4px;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
</style>
