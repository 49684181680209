<script setup>
import { ref, computed, onMounted } from "vue";
import { useCollection } from "vuefire";
import { collection, query, orderBy } from "firebase/firestore";
import { useStore } from "vuex";
import { db } from "@/firebase";

// Import components and composables
import Card from "./components/cards.vue";

// Firebase query with sorting by itemNumber
const auctionItemsQuery = query(
  collection(db, "auctionItems"),
  orderBy("itemNumber", "asc") // Sort by itemNumber in ascending order
);

const auctionItems = useCollection(auctionItemsQuery); // Fetch sorted items
const selectedCategory = ref(null);

const store = useStore(); // Access Vuex store

// Computed property for filtered auction items (already sorted by Firestore)
const filteredAuctionItems = computed(() => {
  if (!selectedCategory.value) return auctionItems.value;
  return auctionItems.value.filter(
    (item) => item.categoryId === selectedCategory.value
  );
});

// Lifecycle hooks
onMounted(async () => {
  await store.dispatch("fetchCategories"); // Ensure categories are fetched
});
</script>

<template>
  <div class="layout-main show-all-items">
    <!-- Items -->
    <div class="grid">
      <div
        v-for="item in filteredAuctionItems"
        :key="item.id"
        class="col-6 col-lg-3 col-lg-3"
      >
        <Card :item="item" :getCategoryName="store.getters.getCategoryName" />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.show-all-items .p-card-body {
  padding: 0.5rem;
  gap: 0.2rem;
}
@media (max-width: 768px) {
  .show-all-items .col-6 {
    padding: 0rem;
  }
}
</style>