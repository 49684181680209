<template>
  <div v-if="showPopup" class="modal-overlay">
    <div class="modal-content">
      <h2>Terms and Conditions</h2>
      <p>
        Please be aware that all bids placed on this site are considered real
        and binding.
      </p>

      <p>
        Once the auction ends, the highest bid is legally enforceable, and the
        winning bidder is obligated to complete the purchase.
      </p>
      <p>
        By participating in our auctions, you agree to these terms and
        understand that failure to fulfill this obligation may result in legal
        consequences.
      </p>
      <p>
        Please agree to our terms and conditions to continue using the site.
      </p>
      <div class="modal-actions">
        <argon-button
          type="button"
          color="success"
          variant="gradient"
          class="ms-auto"
          @click="agreeToTerms"
          >I Agree</argon-button
        >
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "@/firebase";
import ArgonButton from "@/components/ArgonButton.vue";
// Receive userDocId as a prop
const props = defineProps({
  userId: String,
});

const showPopup = ref(true);

const agreeToTerms = async () => {
  if (props.userId) {
    const userRef = doc(db, "users", props.userId);
    await updateDoc(userRef, {
      agreedToTerms: true,
    });
    showPopup.value = false;
  } else {
    console.error("No user ID provided for updating terms agreement.");
  }
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  text-align: center;
}
</style>
