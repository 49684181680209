// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from "firebase/storage";
import { getAuth, setPersistence, browserLocalPersistence } from "firebase/auth";

//import { getAnalytics } from "firebase/analytics";
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBPLHxchxECGW8VYNUiVFofupQJadWnMQQ",
  authDomain: "the-perfect-world-b8d5a.firebaseapp.com",
  projectId: "the-perfect-world-b8d5a",
  storageBucket: "the-perfect-world-b8d5a.appspot.com",
  messagingSenderId: "861625618251",
  appId: "1:861625618251:web:db4266785702af5d9162ce",
  measurementId: "G-50RLPGF8FT"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Firestore
const db = getFirestore(app);
export { db };

// Initialize Firebase Auth
export const auth = getAuth(app);
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    // Persistence set successfully
  })
  .catch((error) => {
    console.error('Error setting persistence:', error);
  });

// Initialize Firebase Storage and export it
export const storage = getStorage(app);