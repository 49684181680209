<template>
  <div class="autocomplete">
    <input
      type="text"
      :value="query"
      @input="onInput($event)"
      @keydown.down="onArrowDown"
      @keydown.up="onArrowUp"
      @keydown.enter="onEnter"
      @focus="onFocus"
      placeholder="Type to search..."
      class="form-control form-control-default"
    />
    <ul
      v-if="filteredSuggestions.length && showSuggestions"
      ref="suggestionsList"
      class="suggestions-list"
    >
      <li
        v-for="(suggestion, index) in filteredSuggestions"
        :key="index"
        :class="{ active: index === selectedIndex }"
        @click="onSelect(suggestion)"
        @touchstart="onTouchStart(suggestion)"
        class="p-dropdown-item"
      >
        {{ suggestion.fullName }}
      </li>
    </ul>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, onBeforeUnmount } from "vue";

const props = defineProps({
  suggestions: {
    type: Array,
    default: () => [],
  },
  modelValue: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["update:modelValue", "select"]);

const query = ref(props.modelValue);
const showSuggestions = ref(false);
const selectedIndex = ref(-1);
const suggestionsList = ref(null); // Add a ref for the suggestions list

const onTouchStart = (suggestion) => {
  onSelect(suggestion);
};

watch(query, (newQuery) => {
  emit("update:modelValue", newQuery);
  showSuggestions.value = !!newQuery;
  selectedIndex.value = -1;
});

const filteredSuggestions = computed(() => {
  if (!query.value) {
    return [];
  }
  const searchQuery = query.value.toLowerCase();
  return props.suggestions.filter((suggestion) =>
    suggestion.fullName.toLowerCase().includes(searchQuery)
  );
});

const onInput = (event) => {
  query.value = event.target.value;
  showSuggestions.value = query.value.length >= 3;
};

const onArrowDown = () => {
  if (selectedIndex.value < filteredSuggestions.value.length - 1) {
    selectedIndex.value++;
  }
};

const onArrowUp = () => {
  if (selectedIndex.value > 0) {
    selectedIndex.value--;
  }
};

const onEnter = () => {
  if (selectedIndex.value !== -1) {
    onSelect(filteredSuggestions.value[selectedIndex.value]);
  }
};

const onSelect = (suggestion) => {
  query.value = suggestion.fullName; // Set the selected value
  showSuggestions.value = false; // Hide the dropdown
  if (suggestionsList.value) {
    suggestionsList.value.style.display = "none"; // Set display to none
  }
  emit("select", suggestion); // Emit the selected suggestion
};

// Hide suggestions when clicking outside of the component
const onClickOutside = (event) => {
  const autocomplete = document.querySelector(".autocomplete");
  if (autocomplete && !autocomplete.contains(event.target)) {
    showSuggestions.value = false;
  }
};

onMounted(() => {
  document.addEventListener("click", onClickOutside);
});

onBeforeUnmount(() => {
  document.removeEventListener("click", onClickOutside);
});
</script>

<style scoped>
.autocomplete {
  position: relative;
  width: 100%;
}

input[type="text"] {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  font-size: 1rem;
}

.suggestions-list {
  position: absolute;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background: white;
  border: 1px solid #ccc;
  border-top: none;
  z-index: 1000;
  list-style: none;
  padding: 0;
  margin: 0;
}

.suggestions-list li {
  padding: 8px;
  cursor: pointer;
}

.suggestions-list li.active,
.suggestions-list li:hover {
  background: #e9e9e9;
}
</style>
