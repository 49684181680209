import { getDocs, collection, query, orderBy } from "firebase/firestore";
import { db } from "@/firebase";
const state = {
    auctionItems: [],
  };
  
  const mutations = {
    SET_AUCTION_ITEMS(state, items) {
      state.auctionItems = items;
    },
  };
  
  const actions = {
    async fetchAllAuctionItems({ commit, state }) {
      if (state.auctionItems.length === 0) {
        const q = query(collection(db, "auctionItems"), orderBy("itemNumber"));
        const querySnapshot = await getDocs(q);
        const items = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        commit('SET_AUCTION_ITEMS', items);
      }
    },
  };
  
  const getters = {
    getAuctionItems: (state) => state.auctionItems,
  };
  
  export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
  };