<template>
  <Card class="col-12 col-lg-6 mt-5">
    <template #header><h5>Add Manual Donation</h5></template>
    <template #content>
      <div class="field">
        <label for="selectItem">Select Donation Item</label>
        <Dropdown
          v-model="selectedItem"
          :options="donationItems"
          optionLabel="name"
          placeholder="Select a Donation Item"
        />
      </div>

      <div class="field mt-3">
        <label for="donorName">Donor Name</label>
        <InputText v-model="donorName" placeholder="Enter Donor Name" />
      </div>

      <div class="field mt-3">
        <label for="purchaseAmount">Purchase Amount</label>
        <InputText
          v-model="formattedPurchaseAmount"
          @input="formatPurchaseAmount"
          placeholder="Enter Purchase Amount"
        />
      </div>

      <div class="field mt-3">
        <label for="tableSelection">Select Table</label>
        <Dropdown
          v-model="selectedTable"
          :options="tables"
          optionLabel="name"
          placeholder="Select a Table"
        />
      </div>
    </template>
    <template #footer>
      <argon-button
        type="button"
        variant="gradient"
        class="ms-auto btn-success"
        @click="submitDonation"
        >Submit Donation</argon-button
      >
    </template>
  </Card>
</template>

<script>
import {
  collection,
  getDocs,
  addDoc,
  Timestamp,
  query,
  where,
} from "firebase/firestore";
import { db } from "@/firebase";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import ArgonButton from "@/components/ArgonButton.vue";
import Card from "primevue/card";

export default {
  name: "ManualDonation",
  components: {
    InputText,
    Dropdown,
    ArgonButton,
    Card,
  },
  data() {
    return {
      donationItems: [],
      tables: [],
      selectedItem: null,
      donorName: "",
      purchaseAmount: "", // raw value without formatting
      formattedPurchaseAmount: "", // formatted value with commas
      selectedTable: null,
    };
  },
  methods: {
    async fetchDonationItems() {
      try {
        const auctionItemsCollection = collection(db, "auctionItems");
        const donationItemsQuery = query(
          auctionItemsCollection,
          where("isDonationItem", "==", true)
        );
        const donationItemsSnapshot = await getDocs(donationItemsQuery);
        this.donationItems = donationItemsSnapshot.docs.map((doc) => ({
          id: doc.id,
          name: doc.data().name,
        }));
      } catch (error) {
        console.error("Error fetching donation items:", error);
      }
    },
    async fetchTables() {
      try {
        const tablesCollection = collection(db, "tables");
        const tablesSnapshot = await getDocs(tablesCollection);
        this.tables = tablesSnapshot.docs
          .map((doc) => ({
            id: doc.id,
            name: doc.data().name,
          }))
          .sort((a, b) => this.compareTables(a.name, b.name));
      } catch (error) {
        console.error("Error fetching tables:", error);
      }
    },
    compareTables(a, b) {
      const numA = parseInt(a.match(/\d+/) ? a.match(/\d+/)[0] : 0, 10);
      const numB = parseInt(b.match(/\d+/) ? b.match(/\d+/)[0] : 0, 10);
      return numA - numB;
    },
    formatPurchaseAmount(event) {
      // Get raw value without commas
      const rawValue = event.target.value.replace(/,/g, "");
      this.purchaseAmount = rawValue;

      // Format the value with commas
      this.formattedPurchaseAmount = Number(rawValue).toLocaleString("en-US");
    },
    async submitDonation() {
      if (
        !this.selectedItem ||
        !this.donorName ||
        !this.purchaseAmount ||
        !this.selectedTable
      ) {
        alert("Please fill in all fields.");
        return;
      }

      try {
        const purchaseRef = collection(
          db,
          `auctionItems/${this.selectedItem.id}/purchase`
        );
        const purchaseData = {
          comment: "New Manual Donation",
          itemName: "Donation Item",
          timestamp: Timestamp.now(),
          userBid: Number(this.purchaseAmount),
          user: {
            name: this.donorName,
            table: this.selectedTable.name,
            tableId: this.selectedTable.id,
          },
        };

        await addDoc(purchaseRef, purchaseData);

        // Reset form fields after submission
        this.selectedItem = null;
        this.donorName = "";
        this.purchaseAmount = "";
        this.formattedPurchaseAmount = "";
        this.selectedTable = null;

        alert("Donation added successfully.");
      } catch (error) {
        console.error("Error submitting donation:", error);
        alert("An error occurred while submitting the donation.");
      }
    },
  },
  async mounted() {
    this.fetchDonationItems();
    this.fetchTables();
  },
};
</script>

<style scoped>
.field {
  margin-bottom: 15px;
}

.field label {
  font-weight: bold;
}

.field .p-inputtext,
.field .p-dropdown {
  width: 100%;
}

.mt-3 {
  margin-top: 1rem;
}
</style>
