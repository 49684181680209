<script setup>
import { ref, watch, reactive, onMounted, onUnmounted, computed } from "vue";
import {
  doc,
  getDoc,
  updateDoc,
  collection,
  addDoc,
  query,
  orderBy,
  limit,
  getDocs,
  onSnapshot,
  setDoc,
} from "firebase/firestore";
import { useStore } from "vuex";
import { db } from "@/firebase";
import Card from "primevue/card";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import { SwipeModal } from "@takuma-ru/vue-swipe-modal";
import CountdownBid from "./CountdownBid.vue";
import InputSwitch from "primevue/inputswitch";
import PriceDisplay from "@/views/auction/components/DisplayPrice.vue";
import { v4 as uuidv4 } from "uuid";

const props = defineProps({
  item: {
    type: Object,
    required: true,
    default: () => ({
      id: "",
      latestBid: 0,
      startbid: 0,
      enddate: { seconds: 0 },
    }), // Provide default values
  },
  user: {
    type: Object,
    required: true,
    default: () => ({ id: "", name: "", firstName: "", lastName: "" }), // Provide default values
  },
});
//const emit = defineEmits(["bidPosted"]);
const emit = defineEmits(["modalStatusChanged"]);

const store = useStore();

const isBidding = ref(false);
const bidAmount = ref("");
const errorMessage = ref("");

const isOpen = ref(false);
const postAnonymously = ref(false); // New reactive property for the InputSwitch
const autoBidPosted = ref(false);
const reactiveItem = reactive({ ...props.item });
const reactiveUser = reactive({ ...props.user });

const userDocId = props.user.id;

const generateUniqueBidId = () => {
  return uuidv4(); // Generates a unique UUID
};

const showHighestBidderPopup = ref(false); // Flag for showing the popup
const bidIncrement = computed(() => {
  if (reactiveItem.latestBid < 10000) {
    return 500;
  } else if (reactiveItem.latestBid < 50000) {
    return 1000;
  } else if (reactiveItem.latestBid < 100000) {
    return 2000;
  } else {
    return 5000;
  }
});
let unsubscribe;

const subscribeToBids = (itemDocRef) => {
  const bidsCollectionRef = collection(itemDocRef, "bids");

  // Create a query to get the highest maxBid by ordering and limiting to 1 result
  const highestBidQuery = query(
    bidsCollectionRef,
    orderBy("maxBid", "desc"),
    orderBy("timestamp", "desc"),
    limit(1)
  );

  // Listen to real-time updates from the query to get the highest bid
  return onSnapshot(highestBidQuery, (querySnapshot) => {
    if (!querySnapshot.empty) {
      // Get the highest (latest) bid
      const highestBidDoc = querySnapshot.docs[0];
      const highestMaxBid = highestBidDoc.data().maxBid;

      //console.log("Updated Highest Max Bid:", highestMaxBid);

      // Update the reactive state with the highest max bid
      reactiveItem.highestMaxBid = highestMaxBid;

      // Log to confirm that canShowBuyNow is updating correctly
      //console.log("Can Show Buy Now:", canShowBuyNow.value);
    }
  });
};

const subscribeToItem = (itemId) => {
  const itemDocRef = doc(db, "auctionItems", itemId);

  // Listen for real-time updates to the auction item document
  unsubscribe = onSnapshot(itemDocRef, async (doc) => {
    if (doc.exists()) {
      const itemData = doc.data();

      // Update the reactive state
      Object.assign(reactiveItem, {
        latestBid: Number(itemData.latestBid),
        latestBidder: itemData.latestBidder,
        description: itemData.description,
        enddate: itemData.enddate,
        startbid: Number(itemData.startbid),
        currency: itemData.currency,
        hasBuyNowBid: itemData.hasBuyNowBid,
        buyNowPrice: itemData.buyNowPrice,
        isBuyNowEnabled: itemData.isBuyNowEnabled,
        highestMaxBid: reactiveItem.highestMaxBid, // Retain the highestMaxBid value
      });

      // Set up a listener on the bids sub-collection
      subscribeToBids(itemDocRef);
    }
  });
};

const canShowBuyNow = computed(() => {
  /*console.log("isBuyNowEnabled:", reactiveItem.isBuyNowEnabled);
  console.log("hasBuyNowBid:", reactiveItem.hasBuyNowBid);
  console.log("highestMaxBid:", reactiveItem.highestMaxBid); */

  // Check if highestMaxBid is a number and not undefined or null
  const highestMaxBidExists = typeof reactiveItem.highestMaxBid === "number";

  return (
    reactiveItem.isBuyNowEnabled === true && // Explicitly check if isBuyNowEnabled is true
    !reactiveItem.hasBuyNowBid && // Ensure that hasBuyNowBid is not true
    (!highestMaxBidExists ||
      reactiveItem.highestMaxBid < reactiveItem.buyNowPrice) // Compare only if highestMaxBid exists
  );
});

const formatBidAmount = (value) => {
  if (!value) return "";
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const parseBidAmount = (value) => {
  return value.toString().replace(/,/g, "");
};

watch(bidAmount, (newVal) => {
  const parsedValue = parseBidAmount(newVal);
  bidAmount.value = formatBidAmount(parsedValue);
});

watch(
  () => props.item,
  (newItem) => {
    Object.assign(reactiveItem, newItem);
    if (unsubscribe) unsubscribe();
    subscribeToItem(newItem.id);
  },
  { immediate: true }
);

watch(
  () => props.user,
  (newUser) => {
    Object.assign(reactiveUser, newUser);
  },
  { immediate: true }
);

// Watch for changes in isOpen and emit the change to the parent
watch(isOpen, (newVal) => {
  emit("modalStatusChanged", newVal);
});

function capitalizeFirstLetter(string) {
  if (typeof string !== "string" || string.length === 0) {
    return "";
  }
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

function getFormattedName(firstName, lastName) {
  const formattedFirstName = capitalizeFirstLetter(firstName);
  const formattedLastName = capitalizeFirstLetter(lastName);
  return `${formattedFirstName} ${formattedLastName}`.trim();
}

function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

// Function to add a notification
const notifyUser = async (phoneNumber, message, itemId, notificationId) => {
  try {
    if (!phoneNumber) {
      //console.log("Phone number is missing, skipping notification.");
      return null; // or return a specific value indicating the notification was skipped
    }

    const itemUrl = `https://theperfectauction.com/auction/item/${itemId}`;
    const fullMessage = `${message} Click here to place a higher bid: ${itemUrl}`;

    const notificationsRef = doc(db, "notifications", notificationId);
    const notification = {
      phoneNumber,
      message: fullMessage,
      itemId,
      timestamp: Date.now(),
      read: false,
    };
    await setDoc(notificationsRef, notification);
    console.log("Notification added to Firestore:", notification);
    return notificationId;
  } catch (error) {
    console.error("Error adding notification:", error);
  }
};

async function fetchLatestBid(itemDocRef) {
  const itemSnapshot = await getDoc(itemDocRef);
  if (itemSnapshot.exists()) {
    return itemSnapshot.data();
  } else {
    throw new Error("Item not found.");
  }
}

async function fetchHighestBid(itemDocRef) {
  const bidsCollection = collection(itemDocRef, "bids");
  const q = query(
    bidsCollection,
    orderBy("maxBid", "desc"),
    orderBy("timestamp", "desc"),
    limit(1)
  );

  const querySnapshot = await getDocs(q);
  if (!querySnapshot.empty) {
    return querySnapshot.docs[0].data();
  }
  return null;
}

function validateBid(
  userPostBid,
  latestBid,
  startBid,
  highestMaxBid,
  currUserIsMaxBidder,
  bidData
) {
  const increment = bidIncrement.value; // Use the computed bid increment

  if (isNaN(userPostBid) || userPostBid <= 0) {
    throw new Error("Please enter a valid number.");
  }
  if (userPostBid < startBid && bidData === null) {
    throw new Error("Your bid must be higher than the starting bid.");
  }
  if (userPostBid <= latestBid && bidData !== null) {
    throw new Error("Your bid must be higher than the current bid.");
  }
  if (userPostBid <= highestMaxBid && currUserIsMaxBidder) {
    throw new Error(
      `Your new bid must be higher than your current existing bid ${highestMaxBid}.`
    );
  }
  if (userPostBid < latestBid + increment && bidData !== null) {
    throw new Error(
      `Your bid must be at least ${increment} higher than the current highest bid.`
    );
  }
}

async function placeNewBid(
  bidsCollection,
  userPostBid,
  user,
  comment,
  latestBid,
  userDocId,
  anonymous
) {
  if (latestBid === undefined) latestBid = userPostBid;

  if (!userDocId) {
    throw new Error("User UID is missing.");
  }

  const bidData = {
    itemName: props.item.name,
    itemID: props.item.id,
    itemNumber: props.item.itemNumber,
    itemImage: props.item.resizedImages?.[1]?.url || "",
    maxBid: userPostBid,
    userBid: latestBid,
    timestamp: new Date(),
    comment: comment,
    autoBidId: generateUniqueBidId(), // Your logic to generate a unique ID
  };

  if (anonymous) {
    bidData.user = {
      uid: userDocId,
      name: "Anonymous",
      hiddenName:
        props.user.name || getFormattedName(user.firstName, user.lastName),
      mobile: props.user.mobile,
      table: props.user.assignedTableName,
      tableId: props.user.assignedTable,
    };
  } else {
    bidData.user = {
      uid: userDocId,
      name: props.user.name || getFormattedName(user.firstName, user.lastName),
      mobile: props.user.mobile,
      table: props.user.assignedTableName,
      tableId: props.user.assignedTable,
    };
  }

  await addDoc(bidsCollection, bidData);
}

async function placeMaxBid(
  bidsCollection,
  userPostBid,
  user,
  latestBid,
  userDocId,
  bidtype,
  anonymous
) {
  if (!userDocId) {
    throw new Error("User UID is missing.");
  }
  let bidComment = "Update Max bid";
  if (bidtype === "DirectBid") {
    bidComment = "New Direct Bid";
  }
  if (bidtype === "BuyNow") {
    bidComment = "Buy Now";
  }

  const bidData = {
    itemName: props.item.name,
    itemID: props.item.id,
    itemNumber: props.item.itemNumber,
    itemImage: props.item.resizedImages?.[1]?.url || "",
    maxBid: userPostBid,
    userBid: latestBid,
    timestamp: new Date(),
    comment: bidComment,
    autoBidId: generateUniqueBidId(),
  };

  if (anonymous) {
    bidData.user = {
      uid: userDocId,
      name: "Anonymous",
      mobile: props.user.mobile,
      table: props.user.assignedTableName,
      tableId: props.user.assignedTable,
    };
  } else {
    bidData.user = {
      uid: userDocId,
      name:
        props.user.name ||
        getFormattedName(props.user.firstName, props.user.lastName),
      mobile: props.user.mobile,
      table: props.user.assignedTableName,
      tableId: props.user.assignedTable,
    };
  }

  await addDoc(bidsCollection, bidData);
}
let highestMaxBidUserData = null;
const placeBid = async (bidtype) => {
  try {
    if (!isBidding.value) {
      isBidding.value = true;
      errorMessage.value = "";

      let userPostBid = parseFloat(parseBidAmount(bidAmount.value));
      if (bidtype === "BuyNow") {
        userPostBid = props.item.buyNowPrice;
      }

      if (isNaN(userPostBid) || userPostBid <= 0) {
        throw new Error("Please enter a valid number.");
      }
      //const firebaseUser = store.getters["user"].data;
      const customUser = store.getters["customUser"];

      let user = customUser;
      if (user) {
        //check if session from current user is different from the reactie user
        //If it is, change to the reactive user (admin posting bid on behalf of user)
        if (customUser.id != reactiveUser.id) {
          user = reactiveUser;
        }
      }
      if (!userDocId) {
        throw new Error("User not logged in or user data unavailable.");
      }

      const itemDocRef = itemDoc.value; // Get the actual DocumentReference
      const itemData = await fetchLatestBid(itemDocRef);
      const bidData = await fetchHighestBid(itemDocRef);

      // Get the current date and time
      const endDate = itemData.enddate.toMillis(); // Convert Firestore Timestamp to milliseconds

      // Get the current timestamp
      const currentTimestamp = Date.now();

      // Validate EndDate
      if (currentTimestamp > endDate) {
        throw new Error("The auction has ended. No more bids are allowed.");
      }

      let latestBid = Number(itemData.latestBid) || 0;
      const startBid = Number(itemData.startbid) || 0;
      let bidCounter = itemData.bidCount || 0;
      let highestMaxBid = latestBid;

      let currUserIsMaxBidder = false;

      if (bidData) {
        highestMaxBid = Number(bidData.maxBid);
        highestMaxBidUserData = bidData;

        if (highestMaxBidUserData.user.uid === userDocId) {
          currUserIsMaxBidder = true;
        }
      } else {
        highestMaxBidUserData = {
          user: {
            uid: userDocId,
            name: user.name || getFormattedName(user.firstName, user.lastName),
            phone: user.mobile,
            table: user.assignedTableName,
            tableId: user.assignedTable,
          },
        };
      }

      validateBid(
        userPostBid,
        latestBid,
        startBid,
        highestMaxBid,
        currUserIsMaxBidder,
        bidData,
        bidtype
      );
      const bidsCollection = collection(itemDocRef, "bids");
      let newUserPostBid = userPostBid;
      if (currUserIsMaxBidder) {
        let itemPriceNew = latestBid ? latestBid : userPostBid;
        if (bidtype === "DirectBid") {
          itemPriceNew = userPostBid;
        }
        if (bidtype === "BuyNow") {
          itemPriceNew = itemData.buyNowPrice;
          userPostBid = itemData.buyNowPrice;
          //Mark item as sold
          await updateDoc(itemDocRef, {
            hasBuyNowBid: true,
          });
        }
        await placeMaxBid(
          bidsCollection,
          userPostBid,
          user,
          itemPriceNew,
          userDocId,
          bidtype,
          postAnonymously.value
        );
        bidCounter += 1;
        newUserPostBid = itemPriceNew;
        // If the current user is now the highest bidder, show the popup
        showHighestBidderPopup.value = true;
      } else {
        //Bid if current user is not highest bidder or no bids have been placed

        if (userPostBid > highestMaxBid) {
          let comment = "Place new bid";
          if (!bidData && bidtype != "DirectBid") {
            highestMaxBid = startBid;
            comment = "Initial Bid";
            showHighestBidderPopup.value = true;
          } else if (!bidData && bidtype === "DirectBid") {
            highestMaxBid = userPostBid;
            comment = "Initial Direct Bid";
          } else if (bidtype === "DirectBid") {
            highestMaxBid = userPostBid;
          } else {
            //check if posted bid with increment is higher than user post bid

            if (userPostBid > highestMaxBid) {
              // Calculate the combined bid after increment
              let combinedBid = highestMaxBid + bidIncrement.value;
              showHighestBidderPopup.value = true;
              // Check if the combined bid exceeds userPostBid
              if (combinedBid > userPostBid) {
                // Set highestMaxBid to userPostBid to avoid exceeding user's desired bid
                highestMaxBid = userPostBid;
                comment = "Auto raised to match existing bid";
              } else {
                // Otherwise, increment highestMaxBid by the bidIncrement
                highestMaxBid += bidIncrement.value;
                comment = "Auto raised to match existing bid";
              }
            }
          }
          if (bidtype === "BuyNow") {
            highestMaxBid = itemData.buyNowPrice;
            userPostBid = itemData.buyNowPrice;
            comment = "Buy Now";
            //Mark item as sold
            await updateDoc(itemDocRef, {
              hasBuyNowBid: true,
            });
          }
          await placeNewBid(
            bidsCollection,
            userPostBid,
            user,
            comment,
            highestMaxBid,
            userDocId,
            postAnonymously.value,
            bidtype
          );
          bidCounter += 1;
          newUserPostBid = highestMaxBid;
        } else {
          newUserPostBid = userPostBid;

          await placeNewBid(
            bidsCollection,
            newUserPostBid,
            user,
            "Place new bid",
            undefined,
            userDocId,
            postAnonymously.value
          );
          bidCounter += 1;
          if (highestMaxBid !== undefined) {
            await delay(300);
          }

          if (highestMaxBid >= newUserPostBid + bidIncrement.value) {
            newUserPostBid += bidIncrement.value;
            const userMobile = highestMaxBidUserData.user.mobile || null;

            await addDoc(bidsCollection, {
              itemName: props.item.name,
              itemID: props.item.id,
              itemNumber: props.item.itemNumber,
              itemImage: props.item.resizedImages?.[1]?.url || "",
              maxBid: highestMaxBid,
              userBid: newUserPostBid,
              timestamp: new Date(),
              autoBidId: generateUniqueBidId(),
              user: {
                uid: highestMaxBidUserData.user.uid,
                name: highestMaxBidUserData.user.name,
                mobile: userMobile,
              },
              comment: "Auto raise to match bid",
            });
            bidCounter += 1;
            autoBidPosted.value = true;
          } else if (highestMaxBid !== undefined) {
            await addDoc(bidsCollection, {
              itemName: props.item.name,
              itemID: props.item.id,
              itemNumber: props.item.itemNumber,
              itemImage: props.item.resizedImages?.[1]?.url || "",
              maxBid: highestMaxBid,
              userBid: highestMaxBid,
              timestamp: new Date(),
              autoBidId: generateUniqueBidId(),
              user: {
                uid: highestMaxBidUserData.user.uid,
                name: highestMaxBidUserData.user.name,
                mobile: highestMaxBidUserData.user.mobile || null,
              },
              comment: "Auto raise to match bid",
            });

            newUserPostBid = highestMaxBid;
            bidCounter += 1;
            autoBidPosted.value = true;
          }
        }
      }

      const isHighestBid =
        highestMaxBid === null ||
        highestMaxBid === undefined ||
        userPostBid > highestMaxBid ||
        userPostBid > highestMaxBidUserData.maxBid;

      const latestBidderName = postAnonymously.value
        ? "Anonymous"
        : isHighestBid
          ? props.user.name ||
            getFormattedName(props.user.firstName, props.user.lastName)
          : highestMaxBidUserData.user.name;

      const bidderData = autoBidPosted.value
        ? {
            latestBidderID: highestMaxBidUserData.user.uid,
            latestBidder: highestMaxBidUserData.user.name,
          }
        : {
            latestBidderID: props.user.id,
            latestBidder: latestBidderName,
          };
      await updateDoc(itemDocRef, {
        latestBid: newUserPostBid,
        ...bidderData,
        bidCount: bidCounter, // Increment bid count
      });

      reactiveItem.latestBid = newUserPostBid;
      reactiveItem.latestBidder = bidderData.latestBidder;
      reactiveItem.latestBidderUID = bidderData.latestBidderID;

      //emit("bidPosted");

      if (!currUserIsMaxBidder && highestMaxBidUserData) {
        if (
          bidData !== null && // Add this check
          newUserPostBid <= bidData.maxBid &&
          highestMaxBidUserData.user.uid !== userDocId
        ) {
          const message = `Hi, you've been outbid on: ${props.item.name}.`;
          const highestBidderRef = doc(db, "users", userDocId);
          const notificationDoc = await addDoc(
            collection(highestBidderRef, "notifications"),
            {
              message: message,
              itemID: props.item.id,
              itemName: props.item.name,
              itemNumber: props.item.itemNumber,
              read: false,
              timestamp: new Date(),
            }
          );

          await notifyUser(
            user.mobile,
            message,
            props.item.id,
            notificationDoc.id
          );
        }

        if (
          bidData !== null && // Add this check
          newUserPostBid > bidData.maxBid &&
          highestMaxBidUserData.user.uid !== userDocId
        ) {
          const message = `Hi, you've been outbid on: ${props.item.name}.`;
          const highestBidderRef = doc(
            db,
            "users",
            highestMaxBidUserData.user.uid
          );
          const notificationDoc = await addDoc(
            collection(highestBidderRef, "notifications"),
            {
              message: message,
              itemID: props.item.id,
              itemName: props.item.name,
              itemNumber: props.item.itemNumber,
              read: false,
              timestamp: new Date(),
            }
          );
          await notifyUser(
            highestMaxBidUserData.user.mobile,
            message,
            props.item.id,
            notificationDoc.id
          );
        }
      }

      bidAmount.value = "";
      isOpen.value = false;
      // If the bid is placed successfully
      bidAmount.value = ""; // Clear the bid amount input
    }
  } catch (error) {
    errorMessage.value = error.message;
  } finally {
    isBidding.value = false;
  }
};

const hasAuctionEnded = computed(() => {
  const now = new Date();
  const hasAuctionEnded = now > new Date(reactiveItem.enddate.seconds * 1000);
  const hasBuyNowBid = reactiveItem.hasBuyNowBid ?? false; // Default to false if undefined

  return hasAuctionEnded || hasBuyNowBid;
});

const itemDoc = computed(() => {
  if (!props.item || !props.item.id) {
    console.error("Item prop is null or undefined", props.item);
    return null;
  }
  return doc(db, "auctionItems", props.item.id);
});

onMounted(() => {
  if (props.item && props.item.id) {
    subscribeToItem(props.item.id);
  }
});

onUnmounted(() => {
  if (unsubscribe) {
    unsubscribe();
  }
});
</script>

<template>
  <!-- Mobile Bid -->
  <Card style="overflow: hidden">
    <template #content>
      <div class="flex-wrap gap-2">
        <div class="flex flex-column">
          <div class="price-display">
            <span v-if="item.latestBid"
              >Current Bid:
              <strong
                ><PriceDisplay
                  :price="item.latestBid"
                  :currency="item.currency" /></strong
            ></span>
            <span v-else
              >Starting Bid:
              <PriceDisplay :price="item.startbid" :currency="item.currency"
            /></span>
          </div>

          <div v-if="reactiveItem.latestBidderID" class="flex flex-column mt-2">
            <p class="text-left bidding-info">
              <span
                class="p-chip-icon pi pi-star-fill"
                data-pc-section="icon"
                style="color: #f0ad4e"
              ></span>
              Highest bidder: {{ reactiveItem.latestBidder }}
            </p>
          </div>

          <!-- Bidding Form -->

          <SwipeModal
            v-model="isOpen"
            snapPoint="auto"
            style="background-color: #ffffff"
          >
            <div
              class="absolute top-0 right-0 font-bold flex align-items-center justify-content-center w-3rem h-3rem border-round"
            >
              <Button
                icon="pi pi-times"
                severity="contrast"
                text
                aria-label="Cancel"
                @click="isOpen = false"
              />
            </div>
            <div class="grid m-2">
              <div class="col-7 text-left">
                <p style="color: #333; font-size: 0.8rem">
                  Next minimum bid:
                  <strong>
                    <PriceDisplay
                      :price="
                        reactiveItem.latestBid + bidIncrement ||
                        reactiveItem.startbid
                      "
                      :currency="item.currency"
                    />
                  </strong>
                </p>
              </div>
              <div class="col-5 text-right">
                <CountdownBid
                  :endDateTimestamp="reactiveItem.enddate"
                  class="countdowntext"
                />
              </div>
            </div>

            <div class="flex m-2">
              <small class="mr-2" style="color: var(--gray-800)"
                >Place bid anonymously?</small
              >
              <InputSwitch v-model="postAnonymously" />
            </div>

            <div class="flex m-2">
              <div class="flex flex-grow-1">
                <InputText
                  v-model="bidAmount"
                  placeholder="Your bid"
                  class=""
                  style="width: 100%"
                  type="text"
                />
              </div>
              <div class="flex">
                <Button
                  label="Auto Bid"
                  class="item-btn small btn-darkblue"
                  rounded
                  @click="placeBid('AutoBid')"
                />
              </div>
              <div class="flex">
                <Button
                  label="Direct Bid"
                  class="item-btn small btn-lightblue"
                  rounded
                  @click="placeBid('DirectBid')"
                />
              </div>
            </div>
            <div
              v-if="canShowBuyNow"
              class="buynowitem pr-3 pl-3"
              style="color: #000000; padding-top: 0.2rem"
            >
              <Divider />
              <div class="flex m-2 flex justify-content-center flex-wrap">
                <div class="flex justify-content-center">
                  <span class="pt-1 pr-1">
                    Or Buy Now for
                    <PriceDisplay
                      :price="reactiveItem.buyNowPrice"
                      :currency="reactiveItem.currency"
                    />
                  </span>
                  <Button
                    label="Buy Now"
                    class="item-btn small btn-gold"
                    rounded
                    @click="placeBid('BuyNow')"
                  />
                </div>
              </div>
            </div>
            <div class="grid grid-nogutter">
              <div class="col-12">
                <p v-if="errorMessage" class="text-red-500 text-sm pb-0 mb-0">
                  {{ errorMessage }}
                </p>
              </div>
            </div>
            <div class="flex justify-content-start align-content-center p-2">
              <div class="flex align-items-center justify-content-start">
                <i
                  class="pi pi-info-circle"
                  style="font-size: 1rem; color: var(--gray-800)"
                ></i>
              </div>
              <div
                class="flex align-items-center justify-content-start flex-grow-1"
              >
                <small
                  class="m-3"
                  style="
                    color: var(--gray-800);
                    font-size: 1rem;
                    text-align: left;
                  "
                >
                  <strong>Auto bid</strong> automatically place new bids for
                  you, up to your maximum amount.<br />
                  <strong>Direct bid</strong> are posted immediately without
                  waiting for other bids to be raised.</small
                >
              </div>
            </div>
          </SwipeModal>
          <!-- Bidding Form End-->

          <div class="inline-flex">
            <div class="hidden md:block">
              <!-- Show on Desktop-->
              <div v-if="!isBidding && !hasAuctionEnded">
                <div class="grid nested-grid">
                  <div class="col-6 text-left">
                    <p style="color: #333; font-size: 0.8rem">
                      Next minimum bid:
                      <strong>
                        <PriceDisplay
                          :price="
                            reactiveItem.latestBid + bidIncrement ||
                            reactiveItem.startbid
                          "
                          :currency="item.currency"
                        />
                      </strong>
                    </p>
                  </div>
                  <div class="col-6 text-right">
                    <CountdownBid
                      :endDateTimestamp="reactiveItem.enddate"
                      class="countdowntext"
                    />
                  </div>
                  <div class="flex px-2">
                    <small class="mr-2" style="color: var(--gray-800)"
                      >Place bid anonymously?</small
                    >
                    <InputSwitch v-model="postAnonymously" />
                  </div>
                  <div class="flex m-2">
                    <div class="flex flex-auto">
                      <InputText
                        v-model="bidAmount"
                        placeholder="Your bid"
                        class=""
                        style="width: 100%"
                        type="text"
                      />
                    </div>
                    <div class="flex flex-auto">
                      <Button
                        label="Auto Bid"
                        class="item-btn small btn-darkblue"
                        rounded
                        @click="placeBid('AutoBid')"
                      />
                    </div>
                    <div class="flex flex-auto">
                      <Button
                        label="Direct Bid"
                        class="item-btn small btn-lightblue"
                        rounded
                        @click="placeBid('DirectBid')"
                      />
                    </div>
                  </div>
                </div>
                <div v-if="canShowBuyNow" class="buynowitem">
                  <Divider />
                  <div class="flex m-2">
                    <div class="flex">
                      <span class="pt-1 pr-1">
                        Or Buy Now for
                        <PriceDisplay
                          :price="reactiveItem.buyNowPrice"
                          :currency="reactiveItem.currency"
                        />
                      </span>
                      <Button
                        label="Buy Now"
                        class="item-btn small btn-gold"
                        rounded
                        @click="placeBid('BuyNow')"
                      />
                    </div>
                  </div>
                </div>
                <div class="grid grid-nogutter">
                  <div class="col-12">
                    <p
                      v-if="errorMessage"
                      class="text-red-500 text-sm pb-0 mb-0"
                    >
                      {{ errorMessage }}
                    </p>
                  </div>
                </div>
                <div class="flex justify-content-start align-content-center">
                  <div
                    class="flex align-items-center justify-content-start p-1"
                  >
                    <i
                      class="pi pi-info-circle"
                      style="font-size: 1rem; color: var(--gray-800)"
                    ></i>
                  </div>
                  <div
                    class="flex align-items-center justify-content-start flex-grow-1"
                  >
                    <small
                      class="m-1"
                      style="
                        color: var(--gray-800);
                        font-size: 0.75rem;
                        text-align: left;
                      "
                    >
                      <strong>Auto bid</strong> automatically place new bids for
                      you, up to your maximum amount.<br />
                      <strong>Direct bid</strong> are posted immediately without
                      waiting for other bids to be raised.
                    </small>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="block md:hidden"
              style="width: 100%"
              v-if="!isBidding && !hasAuctionEnded"
            >
              <button
                class="btn btn-primary bid-action"
                style="width: 100%"
                type="button"
                @click="isOpen = true"
              >
                Place Bid
              </button>
            </div>
            <div v-else-if="hasAuctionEnded" class="flex">
              <p>Bidding has ended.</p>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Card>
  <!-- Popup for Highest Bidder -->
  <div v-if="showHighestBidderPopup" class="modal-overlay">
    <div class="modal-content">
      <h3
        style="font-family: Playfair Display, serif;
    font-weight: 400;
    font-size: 1.9rem;
    text-transform: uppercase;
}"
      >
        Congratulations!
      </h3>
      <p>
        <span
          class="p-chip-icon pi pi-star-fill"
          data-pc-section="icon"
          style="color: #f0ad4e"
        ></span>
        You are the highest bidder!
        <span
          class="p-chip-icon pi pi-star-fill"
          data-pc-section="icon"
          style="color: #f0ad4e"
        ></span>
      </p>
      <Button
        label="Close"
        @click="showHighestBidderPopup = false"
        style="background: #1b2750"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.item-btn {
  border-radius: 6px;
  margin-left: 5px;
}
.item-btn.small {
  font-size: 0.75rem;
}
.scalein {
  animation: scalein 1s forwards;
}

.animation-duration-1000 {
  animation-duration: 1s;
}

@keyframes scalein {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.text-red-500 {
  color: red;
}
.countdowntext {
  color: #000000;
}

:deep(.modal-style) {
  box-sizing: border-box;
  width: 100%;
  color: #333;
  background-color: #ffffff;
  border-radius: 1rem 1rem 0 0;

  @media (prefers-color-scheme: light) {
    color: black;
    background-color: #f7f2fa;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 37%);
  }
}
.price-display {
  border-radius: 5px;
  padding: 3px;
  padding-left: 5px;
  background-color: #f1f5f9;
  color: #000000;
  text-align: left;
  margin-bottom: 2px;
}

.btn-gold {
  background-color: #c39935;
  border-color: #c39935;
}
.btn-green {
  background-color: #009036;
  border-color: #009036;
}
.btn-darkblue {
  background-color: #1b2750;
  border-color: #1b2750;
}
.btn-lightblue {
  background-color: #00a1e4;
  border-color: #00a1e4;
}
.bid-action {
  background: #1b2750;
}
.bid-action:hover {
  background: #1b2750;
  border-color: #1b2750;
}
.bid-action:focus {
  background: #1b2750;
  border-color: #1b2750;
}
.highest-bid-popup {
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -20%);
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.modal-content {
  background: #fff;
  padding: 20px;
  margin: 20px;
  border-radius: 8px;
  max-width: 500px;
  text-align: center;
}
</style>
