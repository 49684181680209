<template>
  <div class="wrapper">
    <div class="container pt-5">
      <h1 class="text-center mb-4">Auction Summary</h1>

      <div
        v-if="loading"
        class="d-flex justify-content-center align-items-center"
        style="height: 200px"
      >
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>

      <div v-else>
        <div class="mb-4">
          <p class="h4">Percentage of guests who placed a bid:</p>
          <p class="display-4">{{ bidPercentage }}%</p>
        </div>
        <div class="row">
          <div class="col-md-6 mb-4">
            <p class="h4">Most active tables:</p>
            <div class="table-responsive">
              <table class="table table-hover">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col">Table</th>
                    <th scope="col">Bids</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(table, index) in sortedTopTables.slice(0, 5)"
                    :key="index"
                    :class="{ 'fade-in': !loading }"
                  >
                    <td>{{ table.name }}</td>
                    <td>{{ table.bidCount }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="col-md-6 mb-4">
            <p class="h4">Tables leading in most items:</p>
            <div class="table-responsive">
              <table class="table table-hover">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col">Table</th>
                    <th scope="col">Items Leading</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(table, index) in sortedLeadingTables.slice(0, 5)"
                    :key="index"
                    :class="{ 'fade-in': !loading }"
                  >
                    <td>{{ table.name }}</td>
                    <td>{{ table.leadingBids }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, computed } from "vue";
import {
  listenToBidPercentage,
  listenToBidsPerTable,
  listenToTablesWithLeadingBids,
} from "@/services/dataService";

const bidPercentage = ref(0);
const topTables = ref([]);
const leadingTables = ref([]);
const loading = ref(true);
let unsubscribeBidPercentage = null;
let unsubscribeTopTables = null;
let unsubscribeLeadingTables = null;

onMounted(() => {
  loading.value = true;

  unsubscribeBidPercentage = listenToBidPercentage((percentage) => {
    bidPercentage.value = percentage;
  });

  unsubscribeTopTables = listenToBidsPerTable((tables) => {
    topTables.value = tables;
    loading.value = false;
  });

  unsubscribeLeadingTables = listenToTablesWithLeadingBids((tables) => {
    leadingTables.value = tables;
  });
});

onUnmounted(() => {
  if (unsubscribeBidPercentage) {
    unsubscribeBidPercentage();
  }
  if (unsubscribeTopTables) {
    unsubscribeTopTables();
  }
  if (unsubscribeLeadingTables) {
    unsubscribeLeadingTables();
  }
});

// Computed properties to handle sorting
const sortedTopTables = computed(() => {
  return topTables.value.slice().sort((a, b) => {
    if (b.bidCount === a.bidCount) {
      return a.name.localeCompare(b.name); // Sort by name if bidCount is the same
    }
    return b.bidCount - a.bidCount; // Sort by bidCount
  });
});

const sortedLeadingTables = computed(() => {
  return leadingTables.value.slice().sort((a, b) => {
    if (b.leadingBids === a.leadingBids) {
      return a.name.localeCompare(b.name); // Sort by name if leadingBids is the same
    }
    return b.leadingBids - a.leadingBids; // Sort by leadingBids
  });
});
</script>

<style scoped>
html,
body,
#app {
  height: 100%;
  margin: 0;
  padding: 0;
}
.wrapper {
  background: #1b2750;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
.h4,
.display-4,
.table {
  color: #ffffff;
}
.table {
  font-size: 1.5rem;
}
.table > :not(caption) > * > * {
  border-bottom-width: 1px;
}
.fade-in {
  opacity: 0;
  animation: fadeInAnimation ease 1s;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
