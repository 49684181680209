// dataService.js
import { db } from '@/firebase';
import { collection, onSnapshot, query, orderBy, limit, getDocs, doc, getDoc } from "firebase/firestore";

async function getLatestBid(auctionItemId) {
  const bidsCollection = collection(db, `auctionItems/${auctionItemId}/bids`);
  const bidsQuery = query(bidsCollection, orderBy('timestamp', 'desc'), limit(1));
  const snapshot = await getDocs(bidsQuery);
  
  if (!snapshot.empty) {
    const latestBidDoc = snapshot.docs[0];
    console.log(`Latest bid for item ${auctionItemId}:`, latestBidDoc.data());
    return latestBidDoc.data();
  }
  
  console.log(`No bids found for item ${auctionItemId}`);
  return null;
}

export function listenToTablesWithLeadingBids(callback, retries = 3) {
  const tablesCollection = collection(db, "tables");

  const unsubscribe = onSnapshot(tablesCollection, async (snapshot) => {
    const tables = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    // Initialize an object to count leading bids by table name
    const tableBidCounts = {};
    tables.forEach(table => {
      tableBidCounts[table.name] = 0;  // Initialize with zero
    });

    const auctionItemsCollection = collection(db, "auctionItems");
    const auctionItemsSnapshot = await getDocs(auctionItemsCollection);

    for (const auctionItemDoc of auctionItemsSnapshot.docs) {
      const auctionItemId = auctionItemDoc.id;
      const latestBid = await getLatestBid(auctionItemId);

      if (latestBid) {
        const { user } = latestBid;
        const { table: tableName } = user;  // Assuming user object contains table name

        if (tableName && Object.prototype.hasOwnProperty.call(tableBidCounts, tableName)) {
          tableBidCounts[tableName] += 1;
        } else {
          console.log(`Table ${tableName} not found in tables list`);
        }
      }
    }

    console.log("Final tableBidCounts:", tableBidCounts);

    // Map back to the table objects and sort them by leadingBids
    const updatedTables = tables
      .map(table => ({
        ...table,
        leadingBids: tableBidCounts[table.name] || 0,
      }))
      .sort((a, b) => b.leadingBids - a.leadingBids);  // Sort by leadingBids in descending order

    callback(updatedTables);
  },
  (error) => {
    console.error("Error fetching tables: ", error);
    if (retries > 0) {
      console.log(`Retrying... attempts left: ${retries}`);
      listenToTablesWithLeadingBids(callback, retries - 1);
    }
  });

  return unsubscribe;
}

// Lyssnare för användare
export function listenToUsers(callback, retries = 3) {
  const usersCollection = collection(db, "users");
  const unsubscribe = onSnapshot(usersCollection,
    (snapshot) => {
      const users = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      callback(users);
    },
    (error) => {
      console.error("Error fetching users: ", error);
      if (retries > 0) {
        console.log(`Retrying... attempts left: ${retries}`);
        listenToUsers(callback, retries - 1); // Retry with remaining attempts
      }
    }
  );
  return unsubscribe; // Return unsubscribe function to allow stopping the listener if needed
}

// Lyssnare för auktionsobjekt
export function listenToAuctionItems(callback, retries = 3) {
  const auctionItemsCollection = collection(db, "auctionItems");
  const unsubscribe = onSnapshot(auctionItemsCollection,
    (snapshot) => {
      const auctionItems = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      callback(auctionItems);
    },
    (error) => {
      console.error("Error fetching auction items: ", error);
      if (retries > 0) {
        console.log(`Retrying... attempts left: ${retries}`);
        listenToAuctionItems(callback, retries - 1); // Retry with remaining attempts
      }
    }
  );
  return unsubscribe;
}

// Lyssnare för bord
export function listenToTables(callback, retries = 3) {
  const tablesCollection = collection(db, "tables");
  const unsubscribe = onSnapshot(tablesCollection,
    (snapshot) => {
      const tables = snapshot.docs
        .map(doc => ({ id: doc.id, ...doc.data() }))
        .sort((a, b) => {
          const nameA = a.name.match(/\d+$/) ? parseInt(a.name.match(/\d+$/)[0], 10) : a.name;
          const nameB = b.name.match(/\d+$/) ? parseInt(b.name.match(/\d+$/)[0], 10) : b.name;

          // If both are numbers, sort by number
          if (typeof nameA === 'number' && typeof nameB === 'number') {
            return nameA - nameB;
          }

          // If only one is a number, prioritize it
          if (typeof nameA === 'number') return -1;
          if (typeof nameB === 'number') return 1;

          // Otherwise, sort lexicographically
          return a.name.localeCompare(b.name);
        });

      callback(tables);
    },
    (error) => {
      console.error("Error fetching tables: ", error);
      if (retries > 0) {
        console.log(`Retrying... attempts left: ${retries}`);
        listenToTables(callback, retries - 1);
      }
    }
  );
  return unsubscribe;
}

// Funktion för att lyssna på procentandelen av gäster som har lagt bud
export function listenToBidPercentage(callback) {
  listenToUsers(async (users) => {
    try {
      listenToAuctionItems(async (auctionItems) => {
        let usersWithBids = new Set();
        let totalBidsProcessed = 0;
        let totalAuctionItems = auctionItems.length;

        // Use Promise.all to ensure all auction items are processed before calculating percentage
        await Promise.all(
          auctionItems.map(async (item) => {
            const bidsCollection = collection(db, `auctionItems/${item.id}/bids`);
            return new Promise((resolve, reject) => {
              onSnapshot(bidsCollection,
                (bidsSnapshot) => {
                  bidsSnapshot.docs.forEach(bid => {
                    if (bid.data().user?.uid) { // Ensure the user data exists
                      usersWithBids.add(bid.data().user.uid);
                    }
                  });
                  totalBidsProcessed++;
                  resolve();
                },
                (error) => {
                  console.error(`Error fetching bids for auction item ${item.id}: `, error);
                  reject(error);
                }
              );
            });
          })
        );

        if (totalBidsProcessed === totalAuctionItems) {
          const percentage = (usersWithBids.size / users.length) * 100;
          callback(percentage.toFixed(2));
        }
      });
    } catch (error) {
      console.error("Error calculating bid percentage: ", error);
    }
  });
}

// Funktion för att lyssna på antalet bud per bord
export function listenToBidsPerTable(callback) {
  listenToTables(async (tables) => {
    listenToAuctionItems(async (auctionItems) => {
      let tableBids = {};
      let tableNames = {};

      // Initialize bid counts and names for each table
      tables.forEach(table => {
        tableBids[table.id] = 0;
        tableNames[table.id] = table.name;
      });

      // Use Promise.all to wait for all auction items to be processed
      await Promise.all(
        auctionItems.map(async (item) => {
          const bidsCollection = collection(db, `auctionItems/${item.id}/bids`);
          return new Promise((resolve, reject) => {
            onSnapshot(bidsCollection,
              (bidsSnapshot) => {
                bidsSnapshot.docs.forEach(bid => {
                  const tableId = bid.data().user?.tableId;
                  if (Object.prototype.hasOwnProperty.call(tableBids, tableId)) {
                    tableBids[tableId]++;
                  }
                });
                resolve();
              },
              (error) => {
                console.error(`Error fetching bids for auction item ${item.id}: `, error);
                reject(error);
              }
            );
          });
        })
      );

      // Sort and send the top 10 tables with the most bids
      const sortedTableBids = Object.entries(tableBids)
        .sort((a, b) => b[1] - a[1])
        .slice(0, 5)
        .map(([tableId, bidCount]) => ({
          name: tableNames[tableId],
          bidCount
        }));

      callback(sortedTableBids);
    });
  });
}

// Funktion för att lyssna på antalet objekt varje bord leder i
export function listenToLeadsPerTable(callback) {
  listenToTables(async (tables) => {
    listenToAuctionItems(async (auctionItems) => {
      let tableLeads = {};
      let tableNames = {};

      // Initialize lead counts and names for each table
      tables.forEach(table => {
        tableLeads[table.id] = 0;
        tableNames[table.id] = table.name;
      });

      for (const item of auctionItems) {
        const latestBidderId = item.latestBidderID;

        if (latestBidderId) {
          // Fetch the user document to ensure the user still exists
          const userDocRef = doc(db, "users", latestBidderId);
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
           

            // Iterate over each table and check if the user belongs to the table
            tables.forEach(table => {
              const userFound = table.users?.some(user => user.id === latestBidderId);
              if (userFound) {
                tableLeads[table.id]++;
              }
            });
          } else {
            console.warn(`User with ID ${latestBidderId} does not exist.`);
          }
        }
      }

      // Sort and send the top 10 tables leading the most items
      const sortedTableLeads = Object.entries(tableLeads)
        .sort((a, b) => b[1] - a[1])
        .slice(0, 5)
        .map(([tableId, leadCount]) => ({
          name: tableNames[tableId],
          leadCount
        }));

      callback(sortedTableLeads);
    });
  });
}