<template>
  <div v-if="!timeEnded">
    <p class="countdowntext">
      <i class="pi pi-clock"></i>
      {{ timeLeft.days }} Days {{ timeLeft.hours }}h {{ timeLeft.minutes }}m
      {{ timeLeft.seconds }}s
    </p>
  </div>
  <div v-else>
    <p>Time's up!</p>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  props: {
    endDateTimestamp: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      timeLeft: {},
      timeEnded: false,
    };
  },
  methods: {
    calculateTimeLeft() {
      const currentDate = dayjs();
      const endDate = dayjs.unix(this.endDateTimestamp.seconds);
      const timeDiff = endDate.diff(currentDate);

      if (timeDiff < 0) {
        this.timeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };
        this.timeEnded = true;
        return;
      }

      const daysLeft = endDate.diff(currentDate, "day");
      const hoursLeft = endDate.diff(currentDate.add(daysLeft, "day"), "hour");
      const minutesLeft = endDate.diff(
        currentDate.add(daysLeft, "day").add(hoursLeft, "hour"),
        "minute"
      );
      const secondsLeft = endDate.diff(
        currentDate
          .add(daysLeft, "day")
          .add(hoursLeft, "hour")
          .add(minutesLeft, "minute"),
        "second"
      );

      this.timeLeft = {
        days: daysLeft,
        hours: hoursLeft,
        minutes: minutesLeft,
        seconds: secondsLeft,
      };
    },
  },
  watch: {
    endDateTimestamp: {
      immediate: true,
      handler() {
        this.calculateTimeLeft();
        if (this.interval) {
          clearInterval(this.interval);
        }
        this.interval = setInterval(this.calculateTimeLeft, 1000); // Update every second
      },
    },
  },
  beforeUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  },
};
</script>

<style>
.countdowntext {
  font-size: 0.8rem;
  color: #333;
}
.countdowntext i {
  font-size: 0.8rem;
}
</style>
