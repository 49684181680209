<template>
  <div class="pl-2 pr-2">
    <h1>Admin Export</h1>

    <!-- Update End Time for All Items -->
    <div class="grid">
      <Card class="col-12 col-lg-6">
        <template #header
          ><h5>Update End Time for <strong>All Items</strong></h5></template
        >
        <template #content>
          <label for="enddate">Select New End Date:</label>
          <VueDatePicker
            v-model="selectedEndDate"
            time-picker-inline
            dateFormat="yy-mm-dd"
          />
        </template>
        <template #footer>
          <argon-button
            type="button"
            variant="gradient"
            class="ms-auto btn-success"
            @click="updateEndTimeForAllItems"
            >Update End Time for All Items</argon-button
          >
          <p class="mt-3">
            Note: This will not update auction items that have ended or items
            with Buy Now bids.
          </p>
        </template>
      </Card>
    </div>
    <!-- Manual Donation Component -->
    <ManualDonation />

    <!-- Reset All Items Component -->
    <ResetAllItems />
    <!-- Export All Items as CSV -->

    <div class="grid">
      <Card class="col-12 col-lg-6 mt-5">
        <template #header><h5>Export All Items as CSV</h5></template>
        <template #content>
          <argon-button
            type="button"
            variant="gradient"
            class="ms-auto btn-success"
            @click="exportAuctionItems"
            >Export Auction Items</argon-button
          >
        </template>
      </Card>
    </div>

    <!-- Export Sold Items and Donations as CSV -->
    <div class="grid">
      <Card class="col-12 col-lg-6 mt-5">
        <template #header
          ><h5>Export Sold Items and Donations as CSV</h5></template
        >
        <template #content>
          <argon-button
            type="button"
            variant="gradient"
            class="ms-auto btn-success"
            @click="exportPurchasedItems"
            >Export Sold Items and Donations</argon-button
          >
        </template>
      </Card>
    </div>

    <!-- Enable/Disable All Users -->
    <div class="grid">
      <Card class="col-12 col-lg-6 mt-5">
        <template #header
          ><h5>Enable/Disable All Users (Except Admins)</h5></template
        >
        <template #content>
          <div class="grid">
            <div class="col-6">
              <argon-button
                type="button"
                class="ms-auto btn-danger"
                @click="updateUserStatus(false)"
                >Disable All Users
              </argon-button>
            </div>
            <div class="col-6">
              <argon-button
                type="button"
                variant="gradient"
                class="ms-auto btn-success"
                @click="updateUserStatus(true)"
                >Enable All Users</argon-button
              >
            </div>
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import {
  collection,
  getDocs,
  updateDoc,
  doc,
  query,
  where,
} from "firebase/firestore";
import { db } from "@/firebase"; // Adjust the import path based on your project structure
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import Card from "primevue/card";
import ManualDonation from "@/views/admin/components/ManualDonation.vue";
import ResetAllItems from "@/views/admin/components/ResetAllItems.vue";
import ArgonButton from "@/components/ArgonButton.vue";

export default {
  name: "AdminExport",
  components: {
    VueDatePicker,
    Card,
    ManualDonation,
    ArgonButton,
    ResetAllItems,
  },
  data() {
    return {
      selectedEndDate: null, // Holds the selected end date
    };
  },
  methods: {
    // Fetch users from Firestore
    async fetchUsers() {
      try {
        const usersCollection = collection(db, "users");
        const usersSnapshot = await getDocs(usersCollection);
        return usersSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
      } catch (error) {
        console.error("Error fetching users:", error);
        return [];
      }
    },

    // Update user status (enable/disable)
    async updateUserStatus(isEnabled) {
      try {
        const users = await this.fetchUsers();
        const usersToUpdate = users.filter((user) => !user.userAdmin);

        const batchPromises = usersToUpdate.map(async (user) => {
          const userRef = doc(db, "users", user.id);
          await updateDoc(userRef, {
            userEnabled: isEnabled, // Set `userEnabled` to `true` or `false`
          });
        });

        await Promise.all(batchPromises);
        alert(
          `All users have been ${isEnabled ? "enabled" : "disabled"} successfully.`
        );
      } catch (error) {
        console.error("Error updating user status:", error);
        alert("An error occurred while updating user status.");
      }
    },

    // Fetch all auction items from Firestore
    async fetchAuctionItems() {
      try {
        const auctionItemsCollection = collection(db, "auctionItems");
        const auctionItemsSnapshot = await getDocs(auctionItemsCollection);
        return auctionItemsSnapshot.docs.map((doc) => ({
          autoID: doc.id, // Auto-generated document ID
          ...doc.data(), // All other fields from the document
        }));
      } catch (error) {
        console.error("Error fetching auction items:", error);
        return [];
      }
    },

    // Fetch donation items from Firestore
    async fetchDonations() {
      try {
        const auctionItemsCollection = collection(db, "auctionItems");
        const donationItemsQuery = query(
          auctionItemsCollection,
          where("isDonationItem", "==", true)
        );
        const donationItemsSnapshot = await getDocs(donationItemsQuery);
        const donationsList = [];

        for (const itemDoc of donationItemsSnapshot.docs) {
          const purchasesCollection = collection(
            db,
            `auctionItems/${itemDoc.id}/purchase`
          );
          const purchasesSnapshot = await getDocs(purchasesCollection);

          purchasesSnapshot.forEach((purchaseDoc) => {
            donationsList.push({
              ...purchaseDoc.data(),
              itemID: itemDoc.id,
            });
          });
        }
        return donationsList;
      } catch (error) {
        console.error("Error fetching donation items:", error);
        return [];
      }
    },

    // Export sold items and donations as a CSV file
    async exportPurchasedItems() {
      try {
        const users = await this.fetchUsers();
        const items = await this.fetchAuctionItems();
        const donations = await this.fetchDonations();

        const soldItems = items.filter(
          (item) =>
            item.enddate.seconds * 1000 < Date.now() && !item.isDonationItem
        );

        const csvRows = [
          [
            "Item Type",
            "Item Number/Name",
            "Bid Amount",
            "Bidder Name",
            "Bidder ID",
            "Assigned Table",
            "Email",
            "Mobile",
          ].join(","),
        ];

        for (const item of soldItems) {
          const user =
            users.find((user) => user.id === item.latestBidderID) || {};
          csvRows.push(
            [
              "Auction Item",
              item.itemNumber,
              item.latestBid,
              item.latestBidder,
              item.latestBidderID,
              user.assignedTableName || "",
              user.email || "",
              user.mobile || "",
            ].join(",")
          );
        }

        for (const donation of donations) {
          const user =
            users.find((user) => user.id === donation.user.uid) || {};
          csvRows.push(
            [
              "Donation Item",
              donation.itemName,
              donation.userBid,
              donation.user.hiddenName || "Anonymous",
              donation.user.uid,
              user.assignedTableName || donation.user.table || "",
              user.email || "",
              donation.user.mobile || user.mobile || "",
            ].join(",")
          );
        }

        const csvContent = csvRows.join("\n");
        this.downloadCSV(csvContent, "purchased_items.csv");
      } catch (error) {
        console.error("Error exporting purchased items:", error);
      }
    },

    // Convert items to CSV format
    convertToCSV(items) {
      const headers = [
        "itemNumber",
        "name",
        "creator",
        "startbid",
        "estimatedValue",
        "autoID",
        "itemURL",
      ];
      const csvRows = [headers.join(",")]; // CSV headers

      items.forEach((item) => {
        const itemURL = `https://theperfectauction.com/auction/item/${item.autoID}`;

        const escapeCsvValue = (value) => {
          if (typeof value === "string" && value.includes(",")) {
            return `"${value.replace(/"/g, '""')}"`; // Escape double quotes within the string
          }
          return value;
        };

        const row = [
          escapeCsvValue(item.itemNumber || ""),
          escapeCsvValue(item.name || ""),
          escapeCsvValue(item.creator || ""),
          escapeCsvValue(item.startbid || ""),
          escapeCsvValue(item.estimatedValue || ""),
          escapeCsvValue(item.autoID || ""),
          escapeCsvValue(itemURL || ""),
        ];
        csvRows.push(row.join(","));
      });

      return csvRows.join("\n");
    },

    // Trigger download of the CSV file
    downloadCSV(csvContent, fileName) {
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    // Export all auction items as a CSV file
    async exportAuctionItems() {
      try {
        const items = await this.fetchAuctionItems();
        if (items.length === 0) {
          console.warn("No items found to export.");
          return;
        }
        const csvContent = this.convertToCSV(items);
        this.downloadCSV(csvContent, "auction_items.csv");
      } catch (error) {
        console.error("Error exporting auction items:", error);
      }
    },

    // Update the end time for all applicable auction items
    async updateEndTimeForAllItems() {
      if (!this.selectedEndDate) {
        alert("Please select a new end date.");
        return;
      }

      try {
        const auctionItems = await this.fetchAuctionItems();
        const itemsToUpdate = auctionItems.filter((item) => {
          // Filter out items that are part of a live auction, have a Buy Now bid, or have already ended
          return (
            !(item.liveAuction && item.liveAuction === true) &&
            !(item.hasBuyNowBid && item.hasBuyNowBid === true) &&
            item.enddate.seconds * 1000 > Date.now()
          );
        });

        const batchPromises = itemsToUpdate.map(async (item) => {
          const itemRef = doc(db, "auctionItems", item.autoID);
          await updateDoc(itemRef, {
            enddate: this.selectedEndDate, // Set the new end date
          });
        });

        await Promise.all(batchPromises);
        alert("End time updated for all applicable items.");
      } catch (error) {
        console.error("Error updating end times:", error);
      }
    },
  },
};
</script>
