<template>
  <div class="p-7">
    <h1>Table Management</h1>
    <div>
      <input v-model="numTables" type="number" placeholder="Number of tables" />
      <button @click="generateTables">Generate Tables</button>
    </div>
    <div v-if="tables.length > 0">
      <h2>Assign Users to Tables</h2>
      <div v-for="table in sortedTables" :key="table.id" class="grid">
        <Card
          class="col mb-5"
          style="margin-bottom: 1rem; background-color: #ffffff"
        >
          <template #title>
            <div class="grid">
              <div class="col">
                <h3>{{ table.name }}</h3>
              </div>
              <div class="col-1">
                <PrimeButton
                  icon="pi pi-trash"
                  class="p-button-rounded p-button-danger p-button-text"
                  @click="confirmDeleteTable(table.id)"
                />
              </div>
            </div>
          </template>
          <template #content>
            <ul>
              <li v-for="user in table.users" :key="user.id">
                {{ user.firstName }} {{ user.lastName }}
                <PrimeButton
                  icon="pi pi-times"
                  class="p-button-rounded p-button-danger p-button-text"
                  @click="removeUserFromTable(table.id, user.id)"
                />
              </li>
            </ul>
          </template>
          <template #footer>
            <Divider />
            <h6>Assign user to table</h6>
            <PDropdown
              v-model="table.selectedUser"
              :options="availableUsers"
              optionLabel="name"
              placeholder="Select a User"
              :style="{ width: '100%' }"
              class="m-2"
            >
              <template #option="slotProps">
                <div>
                  {{ slotProps.option.firstName }}
                  {{ slotProps.option.lastName }}
                </div>
              </template>
              <template #selectedItem="slotProps">
                <div>
                  {{ slotProps.value.firstName }} {{ slotProps.value.lastName }}
                </div>
              </template>
            </PDropdown>
            <p>
              <PrimeButton
                rounded
                label="Assign User"
                @click="assignUserToTable(table.id, table.selectedUser)"
              />
            </p>
          </template>
        </Card>
      </div>
    </div>
    <PrimeDialog header="Confirm" v-model:visible="dialogVisible" modal>
      <p>Are you sure you want to delete this table?</p>
      <div class="p-d-flex p-jc-end">
        <PrimeButton
          label="No"
          icon="pi pi-times"
          @click="dialogVisible = false"
          class="p-button-text"
        />
        <PrimeButton
          label="Yes"
          icon="pi pi-check"
          @click="deleteTable(confirmTableId)"
          autoFocus
        />
      </div>
    </PrimeDialog>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import {
  getFirestore,
  collection,
  addDoc,
  getDocs,
  getDoc,
  deleteDoc,
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import PDropdown from "primevue/dropdown";

export default {
  setup() {
    const db = getFirestore();
    const numTables = ref(0);
    const tables = ref([]);
    const users = ref([]);
    const assignedUsers = ref([]);
    const dialogVisible = ref(false);
    const confirmTableId = ref(null);

    const fetchUsers = async () => {
      try {
        const usersSnapshot = await getDocs(collection(db, "users"));
        users.value = usersSnapshot.docs.map((doc) => {
          const userData = doc.data();
          return {
            id: doc.id,
            ...userData,
            name: `${userData.firstName} ${userData.lastName}`, // Combine firstName and lastName
          };
        });
      } catch (error) {
        console.error("Error fetching users: ", error);
      }
    };

    const fetchTables = async () => {
      const tablesSnapshot = await getDocs(collection(db, "tables"));
      tables.value = tablesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        selectedUser: null,
      }));
      assignedUsers.value = tables.value.flatMap((table) =>
        table.users.map((user) => user.id)
      );
    };

    const generateTables = async () => {
      const maxTableNumber = tables.value.reduce((max, table) => {
        const tableNumber = parseInt(table.name.replace("Table ", ""), 10);
        return Math.max(max, tableNumber);
      }, 0);

      for (let i = 1; i <= numTables.value; i++) {
        const tableName = `Table ${maxTableNumber + i}`;
        await addDoc(collection(db, "tables"), { name: tableName, users: [] });
      }
      fetchTables();
    };

    const confirmDeleteTable = (tableId) => {
      confirmTableId.value = tableId;
      dialogVisible.value = true;
    };

    const deleteTable = async (tableId) => {
      const tableRef = doc(db, "tables", tableId);
      await deleteDoc(tableRef);
      dialogVisible.value = false;
      fetchTables();
    };

    const assignUserToTable = async (tableId, selectedUser) => {
      if (!selectedUser) return;

      const tableRef = doc(db, "tables", tableId);
      const userRef = doc(db, "users", selectedUser.id);

      const tableSnapshot = await getDoc(tableRef);

      if (!tableSnapshot.exists()) {
        alert("Table does not exist.");
        return;
      }

      const tableData = tableSnapshot.data();

      if (tableData?.users?.some((user) => user.id === selectedUser.id)) {
        alert("User already assigned to this table.");
        return;
      }

      await updateDoc(tableRef, {
        users: arrayUnion({
          id: selectedUser.id,
          firstName: selectedUser.firstName,
          lastName: selectedUser.lastName,
        }),
      });

      await updateDoc(userRef, {
        assignedTable: tableId,
        assignedTableName: tableData.name,
      });

      fetchTables();
    };

    const removeUserFromTable = async (tableId, userId) => {
      const tableRef = doc(db, "tables", tableId);
      const userRef = doc(db, "users", userId);

      const user = users.value.find((user) => user.id === userId);

      if (!user) {
        alert("User not found.");
        return;
      }

      await updateDoc(tableRef, {
        users: arrayRemove({
          id: userId,
          firstName: user.firstName,
          lastName: user.lastName,
        }),
      });

      await updateDoc(userRef, {
        assignedTable: null,
      });

      fetchTables();
    };

    const availableUsers = computed(() => {
      return users.value.filter(
        (user) => !assignedUsers.value.includes(user.id)
      );
    });

    const sortedTables = computed(() => {
      return tables.value.slice().sort((a, b) => {
        const tableNumberA = parseInt(a.name.replace("Table ", ""), 10);
        const tableNumberB = parseInt(b.name.replace("Table ", ""), 10);
        return tableNumberA - tableNumberB;
      });
    });

    onMounted(() => {
      fetchUsers();
      fetchTables();
    });

    return {
      numTables,
      tables,
      users,
      availableUsers,
      sortedTables,
      generateTables,
      assignUserToTable,
      removeUserFromTable,
      confirmDeleteTable,
      deleteTable,
      dialogVisible,
      confirmTableId,
      PDropdown,
    };
  },
};
</script>

<style scoped>
ul {
  list-style-type: none;
  padding: 0;
}
ul li {
  display: flex;
  align-items: center;
}
.p-button-label {
  font-size: 14px;
}
</style>
