import { ref, watchEffect } from 'vue';
import { db } from '../firebase';
import { collection, query, onSnapshot } from 'firebase/firestore';

export function useCollection(collectionName, queryConstraints = []) {
  const collectionData = ref([]);
  const loading = ref(true);

  const fetchCollectionData = async () => {
    loading.value = true;
    try {
      const q = query(collection(db, collectionName), ...queryConstraints);

      
      onSnapshot(q, (querySnapshot) => {
        collectionData.value = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        loading.value = false;
      }, (error) => {
        console.error("Error fetching collection data:", error);
        loading.value = false;
      });
      
    } catch (error) {
      console.error("Error fetching collection data:", error);
      loading.value = false;
    }
  };
  

  watchEffect(() => {
    fetchCollectionData();
  });

  return {
    collectionData,
    loading,
  };
}
