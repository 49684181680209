<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useLayout } from "@/layout/composables/layout";

// Vuex store
const store = useStore();

const userProfile = computed(
  () => store.getters["user"].data || store.getters["customUser"] || {}
);
const isUserAuthenticated = computed(() => store.getters.isAuthenticated);
const userIsAdmin = computed(() => store.getters.userIsAdmin); // Add this line
const outsideClickListener = ref(null);
const topbarMenuActive = ref(false);
const router = useRouter();
const { onMenuToggle } = useLayout();

const signOut = async () => {
  await store.dispatch("logOut");
  router.push("/");
};

onMounted(() => {
  bindOutsideClickListener();
});

onBeforeUnmount(() => {
  unbindOutsideClickListener();
});

const onTopBarMenuButton = () => {
  topbarMenuActive.value = !topbarMenuActive.value;
};
const onSettingsClick = () => {
  topbarMenuActive.value = false;
  router.push({ name: "Edit User Info" });
};
const topbarMenuClasses = computed(() => {
  return {
    "layout-topbar-menu-mobile-active": topbarMenuActive.value,
  };
});

const bindOutsideClickListener = () => {
  if (!outsideClickListener.value) {
    outsideClickListener.value = (event) => {
      if (isOutsideClicked(event)) {
        topbarMenuActive.value = false;
      }
    };
    document.addEventListener("click", outsideClickListener.value);
  }
};
const unbindOutsideClickListener = () => {
  if (outsideClickListener.value) {
    document.removeEventListener("click", outsideClickListener.value);
    outsideClickListener.value = null;
  }
};
const isOutsideClicked = (event) => {
  if (!topbarMenuActive.value) return;

  const sidebarEl = document.querySelector(".layout-topbar-menu");
  const topbarEl = document.querySelector(".layout-topbar-menu-button");

  return !(
    sidebarEl.isSameNode(event.target) ||
    sidebarEl.contains(event.target) ||
    topbarEl.isSameNode(event.target) ||
    topbarEl.contains(event.target)
  );
};
</script>

<template>
  <div class="layout-topbar">
    <button
      class="p-link layout-menu-button layout-topbar-button"
      @click="onMenuToggle()"
    >
      <i class="pi pi-bars"></i>
    </button>

    <button
      class="p-link layout-topbar-menu-button layout-topbar-button"
      @click="onTopBarMenuButton()"
    >
      <i class="pi pi-ellipsis-v"></i>
    </button>
    <div class="header-logo">
      <router-link
        :to="{ name: 'Auction Dashboard' }"
        class="layout-topbar-logo"
      >
        <img src="@/assets/tpwf-transparent-no-padding.png" alt="logo" />
      </router-link>
    </div>

    <div class="layout-topbar-menu" :class="topbarMenuClasses">
      <div v-if="userIsAdmin" class="mr-3">
        <router-link :to="{ name: 'Auction Items List' }">
          <button class="p-link layout-topbar-button">
            <i class="pi pi-lock"></i>
            <p class="pb-0 pt-3 pl-2">Admin</p>
          </button>
        </router-link>
      </div>
      <div v-if="isUserAuthenticated">
        <router-link :to="{ name: 'Edit User Info' }">
          <button
            @click="onSettingsClick()"
            class="p-link layout-topbar-button profile-user-button"
          >
            <i class="pi pi-user"></i>
            <p class="pb-0 pt-3 pl-2">Hi, {{ userProfile.firstName }}</p>
          </button>
        </router-link>

        <!-- Add more fields as necessary -->
      </div>
      <div v-else>
        <p>Please log in to see your profile information.</p>
      </div>

      <button
        @click="signOut"
        v-if="isUserAuthenticated"
        class="p-link layout-topbar-button"
      >
        <i class="pi pi-sign-out"></i>
        <span>Logout</span>
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.layout-topbar-button.profile-user-button {
  width: 150px;
}

.header-logo {
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
}
</style>
