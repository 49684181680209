<script setup>
import { onMounted, ref } from "vue";
import { useToast } from "vue-toastification";
import { fetchUsers } from "@/services/userService";

import ComplexStatisticsCard from "@/examples/Cards/ComplexStatisticsCard.vue";
import ReportsTable from "./components/ReportsTable.vue";
import img1 from "@/assets/img/reports1.jpg";

const userCount = ref(0);
const toast = useToast();
const message = localStorage.getItem("saveMessage");

// If your toast library is not globally available, import it
// For example, if you're using Vue Toastification:
// import { useToast } from 'vue-toastification';
onMounted(async () => {
  const users = await fetchUsers();
  userCount.value = users.length;
  if (message) {
    // If using Vue Toastification and toast needs to be invoked
    toast.success(message);
    localStorage.removeItem("saveMessage");
  }
});
</script>
<template>
  <div class="container-fluid py-8">
    <div class="row">
      <div class="col-lg-6 col-12">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-12">
            <complex-statistics-card
              :img="img1"
              icon="text-dark ni ni-circle-08"
              :count="{ number: userCount, label: 'Active Users' }"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12">
        <div class="pb-3">
          <div class="d-lg-flex">
            <div>
              <router-link
                :to="{ name: 'Create New User' }"
                class="mb-0 btn bg-gradient-success btn-sm mr-2"
                >+&nbsp; Add new user</router-link
              >
            </div>
            <div>
              <router-link
                :to="{ name: 'Import Users' }"
                class="mb-0 btn bg-gradient-success btn-sm"
                >+&nbsp; Import new users</router-link
              >
            </div>
          </div>
        </div>
        <reports-table />
      </div>
    </div>
  </div>
</template>
