<!-- App.vue -->
<template>
    <div id="app">
      <SummaryComponent />
    </div>
  </template>
  
  <script>
  import SummaryComponent from './components/SummaryComponent';
  
  export default {
    components: {
      SummaryComponent
    }
  };
  </script>
  