<template>
    <span>{{ formattedPrice }} <small>{{ currency }}</small></span>
  </template>
  
  <script setup>
  import { computed } from "vue";
  
  const props = defineProps({
    price: {
      type: Number,
      required: true,
    },
    currency: {
      type: String,
      default: 'SEK',
    },
  });
  
  const formattedPrice = computed(() => {
    const numberFormatter = new Intl.NumberFormat('en-US', {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
    return numberFormatter.format(props.price);
  });
  </script>
  
  <style scoped>
  small {
    font-size: 0.75rem;
  }
  </style>
  