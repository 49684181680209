<template>
  <main class="main-content mt-0">
    <div
      class="page-header align-items-start min-vh-50 pt-7 pb-9"
      :style="{
        backgroundImage:
          'url(' + require('@/assets/img/tpwauction2024-min.jpg') + ')',
      }"
    >
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-5 text-center mx-auto">
            <h1 class="text-white mb-2 mt-5">
              Welcome to <br />
              The Perfect Auction
            </h1>
            <p class="text-lead text-white">
              Please login to start bidding on your favorite items.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
        <div class="col-xl-4 col-lg-5 col-md-7 mx-auto">
          <div class="card mt-5">
            <div class="card-header pb-0 text-start">
              <h3 class="font-weight-bolder">Sign in</h3>
              <p class="mb-0">
                Enter your name and select your user from the list.
              </p>
            </div>
            <div class="card-body">
              <form
                role="form"
                class="text-start userlogin-form"
                @submit.prevent="login"
              >
                <label>Type Your Name:</label>
                <Autocomplete
                  v-model="name"
                  :suggestions="suggestions"
                  @input="handleInput"
                  @select="selectUser"
                />
                <div v-if="selectedUser">
                  <label v-if="!hasPassword">Set Your Password:</label>
                  <label v-else>Enter Your Password:</label>
                  <argon-input
                    type="password"
                    v-model="password"
                    class="password-input"
                    :placeholder="
                      !hasPassword ? 'Set your password' : 'Enter your password'
                    "
                    aria-label="Password"
                  />
                </div>
                <Message
                  :class="errormsg"
                  v-if="errMsg"
                  severity="error"
                  :closable="false"
                  >{{ errMsg }}</Message
                >
                <div class="text-center">
                  <argon-button color="tpwf" full-width class="mt-4 mb-0"
                    >Sign in</argon-button
                  >
                </div>
              </form>
            </div>
            <div class="card-footer text-center pt-0 px-lg-2 px-1"></div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <app-footer />
</template>

<script setup>
import { onBeforeMount, onBeforeUnmount, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { db } from "@/firebase";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  where,
  query,
} from "firebase/firestore";
import AppFooter from "@/views/PageLayout/Footer.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import Autocomplete from "@/views/auction/components/Autocomplete.vue";
import { debounce } from "lodash-es";

const name = ref("");
const password = ref("");
const errMsg = ref("");
const suggestions = ref([]);
const selectedUser = ref(null);
const isReturningUser = ref(false);
const hasPassword = ref(false);

const router = useRouter();
const route = useRoute();
const store = useStore();

// Extract the redirect URL from the route query parameter
const redirectUrl = route.query.redirect || "/auction/dashboard";

const capitalize = (str) =>
  str ? str.charAt(0).toUpperCase() + str.slice(1).toLowerCase() : "";

watch(
  name,
  debounce((newVal) => {
    if (newVal.length >= 3) {
      searchUsers(newVal);
    } else {
      suggestions.value = [];
    }
  }, 300)
); // 300ms delay
const handleInput = (val) => {
  if (val.length >= 3) {
    searchUsers(val); // Trigger the search after 3 characters
  } else {
    suggestions.value = [];
  }
};
const searchUsers = async (searchTerm) => {
  const searchName = searchTerm.toLowerCase(); // Convert search term to lowercase
  try {
    // Query Firestore using the 'firstNameLower' field
    const usersQuery = query(
      collection(db, "users"),
      where("fullNameLower", ">=", searchName),
      where("fullNameLower", "<=", searchName + "\uf8ff")
    );

    const querySnapshot = await getDocs(usersQuery);

    suggestions.value = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      firstName: doc.data().firstName,
      lastName: doc.data().lastName,
      fullName: `${capitalize(doc.data().firstName)} ${capitalize(
        doc.data().lastName
      )}`,
    }));
  } catch (error) {
    console.error("Error fetching users:", error);
  }
};

const selectUser = async (user) => {
  if (!user || !user.id) {
    console.error("Invalid user selected:", user);
    return;
  }

  selectedUser.value = user;
  name.value = user.fullName;

  try {
    const userDoc = doc(db, "users", user.id);
    const userSnap = await getDoc(userDoc);
    if (userSnap.exists()) {
      const userData = userSnap.data();
      hasPassword.value = userData.password;
      isReturningUser.value = userData.loggedInBefore;
      if (!userData.userEnabled) {
        errMsg.value = "Your account is inactive. Please contact support.";
        selectedUser.value = null;
        return;
      }
    }
  } catch (error) {
    console.error("Error fetching user details:", error);
  }
};

const login = async () => {
  if (!selectedUser.value || !password.value) {
    errMsg.value = "Please select a user and enter your password.";
    return;
  }
  try {
    const userDoc = doc(db, "users", selectedUser.value.id);
    const userSnap = await getDoc(userDoc);
    if (userSnap.exists() && !userSnap.data().userEnabled) {
      errMsg.value = "Your account is inactive. Please contact support.";
      return;
    }
    if (!hasPassword.value) {
      await updateDoc(userDoc, {
        password: password.value,
        loggedInBefore: true,
      });
    }
    await store.dispatch("customLogIn", {
      firstName: selectedUser.value.firstName,
      password: password.value,
    });
    // Set the admin status after logging in
    if (userSnap.data().userAdmin) {
      store.commit("SET_USER_ISADMIN", true);
    } else {
      store.commit("SET_USER_ISADMIN", false);
    }

    // Redirect the user to the previous URL or default dashboard
    router.push(redirectUrl);
  } catch (error) {
    errMsg.value = "Wrong password";
    console.error("Error logging in user:", error);
  }
};

const toggleDefaultLayout = () => store.commit("toggleDefaultLayout");

onBeforeMount(() => {
  store.state.hideConfigButton = true;
  toggleDefaultLayout();
  document.body.classList.remove("bg-gray-100");
});

onBeforeUnmount(() => {
  store.state.hideConfigButton = false;
  toggleDefaultLayout();
  document.body.classList.add("bg-gray-100");
});
</script>

<style>
.p-message .p-message-text {
  font-size: 0.875rem;
}
.userlogin-form input {
  font-size: 1rem;
}
</style>
