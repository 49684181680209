<script setup>
import { ref, onMounted } from "vue";
import {
  collection,
  getDocs,
  doc,
  updateDoc,
  getDoc,
  setDoc,
} from "firebase/firestore";
import { db } from "@/firebase";
import { useToast } from "vue-toastification";

// PrimeVue Components
import PickList from "primevue/picklist";

// Toast notifications
const toast = useToast();
const items = ref([[], []]); // First array is for sourceItems, second array is for targetItems
const liveAuctionStatus = ref("inactive"); // Default status for the live auction

// Fetch the live auction status from Firestore
const fetchLiveAuctionStatus = async () => {
  try {
    const statusDoc = await getDoc(
      doc(db, "auctionSettings", "liveAuctionStatus")
    );
    if (statusDoc.exists()) {
      liveAuctionStatus.value = statusDoc.data().status || "inactive";
    } else {
      // If document does not exist, create it with default value
      await setDoc(doc(db, "auctionSettings", "liveAuctionStatus"), {
        status: "inactive",
      });
    }
  } catch (error) {
    console.error("Error fetching live auction status:", error);
    toast.error("Failed to fetch live auction status.");
  }
};

// Toggle the live auction status
const toggleLiveAuctionStatus = async () => {
  const newStatus =
    liveAuctionStatus.value === "active" ? "inactive" : "active";
  try {
    await updateDoc(doc(db, "auctionSettings", "liveAuctionStatus"), {
      status: newStatus,
    });
    liveAuctionStatus.value = newStatus;
    toast.success(`Live auction bidding is now ${newStatus}`);
  } catch (error) {
    console.error("Error updating live auction status:", error);
    toast.error("Failed to update live auction status.");
  }
};

// Fetch all auction items and classify them into source (available) and target (live auction) lists
const fetchAuctionItems = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, "auctionItems"));
    const fetchedItems = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    // Sort the items by itemNumber
    fetchedItems.sort((a, b) => a.itemNumber - b.itemNumber);

    // Split items into source (not live auction) and target (live auction)
    items.value[0] = fetchedItems.filter((item) => !item.liveAuction); // Source items
    items.value[1] = fetchedItems.filter((item) => item.liveAuction); // Target items
  } catch (error) {
    console.error("Error fetching auction items: ", error);
    toast.error("Failed to fetch auction items.");
  }
};

// Update liveAuction status in Firestore
const updateLiveAuctionStatus = async (itemId, isLiveAuction) => {
  try {
    const itemRef = doc(db, "auctionItems", itemId);
    await updateDoc(itemRef, { liveAuction: isLiveAuction });
  } catch (error) {
    console.error("Error updating live auction status:", error);
    toast.error("Failed to update live auction status.");
  }
};

// When an item is moved to the target list, set liveAuction to true
const onMoveToTarget = (event) => {
  event.items.forEach((item) => {
    updateLiveAuctionStatus(item.id, true);
  });
  toast.success("Selected items added to live auction");
};

// When an item is moved to the source list, set liveAuction to false
const onMoveToSource = (event) => {
  event.items.forEach((item) => {
    updateLiveAuctionStatus(item.id, false);
  });
  toast.success("Selected items removed from live auction");
};

// Fetch auction items and the live auction status when the component is mounted
onMounted(() => {
  fetchAuctionItems();
  fetchLiveAuctionStatus();
});
</script>

<template>
  <div class="container">
    <h2>Live Auction Management</h2>

    <!-- Live Auction Toggle -->
    <div class="mb-4 d-flex align-items-center pt-2">
      <p class="me-3">
        Live Auction Bidding is currently
        <strong>{{ liveAuctionStatus }}</strong>
      </p>
      <button
        class="btn"
        :class="liveAuctionStatus === 'active' ? 'btn-danger' : 'btn-success'"
        @click="toggleLiveAuctionStatus"
      >
        {{ liveAuctionStatus === "active" ? "Stop" : "Start" }} Live Auction
      </button>
    </div>

    <PickList
      v-model="items"
      sourceHeader="Available Items"
      targetHeader="Live Auction Items"
      optionLabel="name"
      :showSourceControls="false"
      :showTargetControls="false"
      @moveToTarget="onMoveToTarget"
      @moveToSource="onMoveToSource"
      listStyle="height:342px"
      breakpoint="1400px"
    >
      <template #sourceheader> Available </template>
      <template #targetheader> Selected for Live Auction </template>
      <!-- Custom template for each item -->
      <template #item="{ item }">
        <div class="flex flex-wrap p-2 align-items-center gap-3">
          <img
            class="w-4rem flex-shrink-0 border-round"
            :src="item.resizedImages[0].url"
            :alt="item.name"
          />
          <div class="flex-1 flex flex-column gap-2">
            <span class="font-bold"
              >{{ item.itemNumber }} - {{ item.name }}</span
            >
          </div>
        </div>
      </template>
    </PickList>

    <!-- Display details of selected live auction items -->
    <div v-if="items[1].length > 0" class="mt-4">
      <h4>Selected Live Auction Items</h4>
      <ul class="list-group">
        <li
          v-for="item in items[1]"
          :key="item.id"
          class="list-group-item d-flex justify-content-between align-items-center"
        >
          <div>
            <strong>{{ item.itemNumber }}</strong> - {{ item.name }}
          </div>
          <div>
            <span>Latest Bid: {{ item.latestBid || "No bids yet" }}</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped>
.container {
  margin: 20px;
}
</style>
