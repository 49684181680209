<script setup>
import { ref, onMounted, watch } from "vue";
import { useRoute } from "vue-router";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "@/firebase";
import PriceDisplay from "@/views/auction/components/DisplayPrice.vue";

const route = useRoute();
const auctionItem = ref({});
const latestBidder = ref({});

const fetchAuctionItem = async () => {
  try {
    const docRef = doc(db, "auctionItems", route.params.id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      auctionItem.value = docSnap.data();

      if (auctionItem.value.isPaid === undefined) {
        await updateDoc(docRef, { isPaid: false });
        auctionItem.value.isPaid = false;
      }

      if (auctionItem.value.latestBidderID) {
        fetchLatestBidder(auctionItem.value.latestBidderID);
      }
    } else {
      console.log("No such document!");
    }
  } catch (error) {
    console.error("Error fetching document:", error);
  }
};

const fetchLatestBidder = async (bidderID) => {
  try {
    const bidderRef = doc(db, "users", bidderID);
    const bidderSnap = await getDoc(bidderRef);
    if (bidderSnap.exists()) {
      latestBidder.value = bidderSnap.data();
    } else {
      console.log("No such user!");
    }
  } catch (error) {
    console.error("Error fetching bidder:", error);
  }
};

// Function to toggle the payment status
const togglePaymentStatus = async () => {
  try {
    const docRef = doc(db, "auctionItems", route.params.id);

    const newIsPaidStatus = !auctionItem.value.isPaid;

    // Log the current and new state for debugging
    //console.log("Current isPaid status:", auctionItem.value.isPaid);
    //console.log("Updating to new isPaid status:", newIsPaidStatus);

    // Update Firestore first
    await updateDoc(docRef, {
      isPaid: newIsPaidStatus,
    });

    // Only update local state after successful Firebase update
    auctionItem.value.isPaid = newIsPaidStatus;

    console.log("Successfully updated isPaid in Firebase");
  } catch (error) {
    console.error("Error updating payment status:", error);
  }
};

// Function to format date from Firestore timestamp
const formatDate = (timestamp) => {
  if (!timestamp || !timestamp.seconds) {
    return "N/A";
  }

  const date = new Date(timestamp.seconds * 1000);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");

  return `${year}-${month}-${day}`;
};

// Fetch auction item when component is mounted or when the route ID changes
onMounted(fetchAuctionItem);
watch(() => route.params.id, fetchAuctionItem);
</script>

<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-6">
        <h4 class="text-white">
          {{ auctionItem.name ? auctionItem.name : "Loading..." }}
        </h4>
      </div>
      <div
        class="text-right col-lg-6 d-flex flex-column justify-content-center"
      >
        <router-link
          :to="{
            name: 'List Sold Items',
          }"
        >
          <button
            type="button"
            class="mt-2 mb-0 btn btn-outline-black ms-lg-auto me-lg-0 me-auto mt-lg-0"
          >
            Back to List Items
          </button>
        </router-link>
      </div>
    </div>
    <div class="mt-4 row">
      <div class="col-lg-4">
        <div class="card">
          <div class="card-body">
            <h5 class="font-weight-bolder">Item Information</h5>
            <p>Auction Item: {{ auctionItem.name }}</p>
            <p>
              Sold Price:
              <PriceDisplay
                :price="auctionItem.latestBid"
                :currency="auctionItem.currency"
              />
            </p>
            <p>
              Item End Date:
              {{ formatDate(auctionItem.enddate) }}
            </p>
          </div>
        </div>
      </div>
      <div class="mt-4 col-lg-8 mt-lg-0">
        <div class="card">
          <div class="card-body">
            <h5 class="font-weight-bolder">Buyer Information</h5>
            <div class="row">
              <div class="col-12 col-sm-6">
                <p>
                  Sold Price:
                  <strong>
                    <PriceDisplay
                      :price="auctionItem.latestBid"
                      :currency="auctionItem.currency"
                  /></strong>
                </p>
                <p>
                  Buyer:
                  <strong v-if="latestBidder.firstName && latestBidder.lastName"
                    >{{ latestBidder.firstName }}
                    {{ latestBidder.lastName }}</strong
                  >
                  <span v-else>Loading...</span>
                </p>
                <p>
                  Buyer Phone:
                  <strong v-if="latestBidder.mobile">{{
                    latestBidder.mobile
                  }}</strong>
                  <span v-else>Loading...</span>
                </p>
                <p>
                  Paid:
                  <input
                    type="checkbox"
                    :checked="auctionItem.isPaid"
                    @change="togglePaymentStatus"
                  />
                  {{ auctionItem.isPaid ? "Yes" : "No" }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
