<script setup>
import {
  ref,
  watch,
  computed,
  onMounted,
  onBeforeMount,
  onBeforeUnmount,
} from "vue";
import { useStore } from "vuex";
import { SpeedInsights } from "@vercel/speed-insights/vue";
import { collection, getDocs, getDoc, doc } from "firebase/firestore";
import { auth } from "@/firebase";
import { db } from "@/firebase";
import AppTopbar from "@/layout/AppTopbar.vue";
import AppSidebar from "@/layout/AppSidebar.vue";
import { useLayout } from "@/layout/composables/layout";
//import "animate.css";

// Import NotificationComponent
import NotificationComponent from "@/views/auction/components/Notification.vue";
import TermsAndConditions from "@/views/auction/components/TermsAndConditions.vue";

const categories = ref([]);

const showTermsPopup = ref(false);
const { layoutConfig, layoutState, isSidebarActive } = useLayout();
const outsideClickListener = ref(null);
const body = document.getElementsByTagName("body")[0];
// Vuex store
const store = useStore();
const userDocId = store.getters.userDocId; // Directly access the getter

// Check user's agreement status
const checkUserAgreement = async () => {
  if (userDocId) {
    const userDoc = await getDoc(doc(db, "users", userDocId));

    if (userDoc.exists()) {
      const userData = userDoc.data();
      if (userData.agreedToTerms) {
        showTermsPopup.value = false;
      } else {
        showTermsPopup.value = true;
      }
    } else {
      showTermsPopup.value = true;
    }
  }
};
const fetchCategories = async () => {
  const querySnapshot = await getDocs(collection(db, "category"));
  categories.value = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
};

watch(isSidebarActive, (newVal) => {
  if (newVal) {
    bindOutsideClickListener();
  } else {
    unbindOutsideClickListener();
  }
});

const containerClass = computed(() => ({
  "layout-theme-light": layoutConfig.darkTheme.value === "light",
  "layout-theme-dark": layoutConfig.darkTheme.value === "dark",
  "layout-overlay": layoutConfig.menuMode.value === "overlay",
  "layout-static": layoutConfig.menuMode.value === "static",
  "layout-static-inactive":
    layoutState.staticMenuDesktopInactive.value &&
    layoutConfig.menuMode.value === "static",
  "layout-overlay-active": layoutState.overlayMenuActive.value,
  "layout-mobile-active": layoutState.staticMenuMobileActive.value,
  "p-ripple-disabled": layoutConfig.ripple.value === false,
}));

const bindOutsideClickListener = () => {
  if (!outsideClickListener.value) {
    outsideClickListener.value = (event) => {
      if (isOutsideClicked(event)) {
        layoutState.overlayMenuActive.value = false;
        layoutState.staticMenuMobileActive.value = false;
        layoutState.menuHoverActive.value = false;
      }
    };
    document.addEventListener("click", outsideClickListener.value);
  }
};

const unbindOutsideClickListener = () => {
  if (outsideClickListener.value) {
    document.removeEventListener("click", outsideClickListener.value);
    outsideClickListener.value = null;
  }
};

const isOutsideClicked = (event) => {
  const sidebarEl = document.querySelector(".layout-sidebar");
  const topbarEl = document.querySelector(".layout-menu-button");
  return !(
    sidebarEl.isSameNode(event.target) ||
    sidebarEl.contains(event.target) ||
    topbarEl.isSameNode(event.target) ||
    topbarEl.contains(event.target)
  );
};

onMounted(async () => {
  await fetchCategories();
});

onBeforeMount(() => {
  store.commit("setShowSidenav", true);
  store.state.showNavbar = true;
  store.state.hideConfigButton = true;
  store.commit("toggleDefaultLayout");
  body.classList.remove("bg-gray-100");
});

onBeforeUnmount(() => {
  store.state.hideConfigButton = false;
  store.commit("setShowSidenav", false);
  store.commit("toggleDefaultLayout");
  body.classList.add("bg-gray-100");
});

auth.onAuthStateChanged((user) => {
  store.dispatch("fetchUser", user);
  checkUserAgreement();
});
</script>

<template>
  <div class="layout-wrapper layout-static" :class="containerClass">
    <app-topbar></app-topbar>
    <div class="layout-sidebar">
      <app-sidebar></app-sidebar>
    </div>
    <div class="layout-main-container">
      <div class="layout-main">
        <router-view />
        <!-- Add NotificationComponent here -->
        <NotificationComponent />
        <SpeedInsights />
        <!-- Display the Terms and Conditions popup if required -->
        <terms-and-conditions v-if="showTermsPopup" :user-id="userDocId" />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
</style>
