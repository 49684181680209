<script setup>
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
//import { DataTable } from "simple-datatables";
import setTooltip from "@/assets/js/tooltip.js";
import { db } from "../../firebase";
import {
  collection,
  getDocs,
  deleteDoc,
  doc,
  query,
  orderBy,
} from "firebase/firestore";
import { useToast } from "vue-toastification";

const store = useStore();
const items = ref([]);
const toast = useToast();
const categories = ref([]);

//Table
import DataTable from "primevue/datatable";
import Column from "primevue/column";

const fetchItems = async () => {
  // Create a query with sorting by itemNumber
  const q = query(collection(db, "auctionItems"), orderBy("itemNumber"));

  const querySnapshot = await getDocs(q);
  items.value = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
};

//Get Categories
const fetchCategories = async () => {
  const querySnapshot = await getDocs(collection(db, "category"));
  categories.value = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
};

//Get the Category Name by ID
const getCategoryName = (categoryId) => {
  const category = categories.value.find((cat) => cat.id === categoryId);
  return category ? category.name : "Unknown Category";
};

const formatDate = (timestamp) => {
  const date = new Date(timestamp.seconds * 1000);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");

  return `${year}-${month}-${day}`;
};

const daysLeft = (endDateTimestamp) => {
  const currentDate = new Date();
  const endDate = new Date(endDateTimestamp.seconds * 1000);
  const timeDiff = endDate - currentDate;
  if (timeDiff < 0) {
    return 0;
  }
  const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
  return daysLeft;
};
const deleteItem = async (id) => {
  try {
    await deleteDoc(doc(db, "auctionItems", id));
    toast.success("Auction item deleted successfully");

    // Refresh the items list to reflect the deletion
    fetchItems();
  } catch (error) {
    console.error("Error deleting item: ", error);
    toast.error("Error deleting item");
  }
};

//Format Timestamp

onMounted(async () => {
  await fetchCategories();
  await fetchItems();

  const message = localStorage.getItem("auctionMessage");

  if (message) {
    toast.success(message);
    localStorage.removeItem("auctionMessage");
  }

  if (document.getElementById("products-list")) {
    const dataTableSearch = new DataTable("#products-list", {
      searchable: true,
      fixedHeight: false,
      perPage: 7,
    });

    document.querySelectorAll(".export").forEach(function (el) {
      el.addEventListener("click", function () {
        var type = el.dataset.type;

        var data = {
          type: type,
          filename: "soft-ui-" + type,
        };

        if (type === "csv") {
          data.columnDelimiter = "|";
        }

        dataTableSearch.export(data);
      });
    });
  }
  setTooltip(store.state.bootstrap);
});
</script>
<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Auction Items</h5>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <router-link
                    :to="{ name: 'Create New Auction Item' }"
                    class="mb-0 btn bg-gradient-success btn-sm"
                    >+&nbsp; New Auction Item</router-link
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="px-0 pb-0 card-body">
            <div class="table-responsive table table-flush">
              <DataTable
                :value="items"
                paginator
                :rows="10"
                :rowsPerPageOptions="[10, 20, 50]"
                tableStyle="min-width: 50rem"
              >
                <Column field="itemNumber" header="Item Number"
                  ><template #body="{ data }">
                    <router-link
                      :to="{
                        name: 'Edit Auction Item',
                        params: { id: data.id },
                      }"
                    >
                      <h6 class="my-auto ms-3">{{ data.itemNumber }}</h6>
                    </router-link></template
                  ></Column
                >
                <Column field="name" header="Name"
                  ><template #body="{ data }">
                    <router-link
                      :to="{
                        name: 'Edit Auction Item',
                        params: { id: data.id },
                      }"
                    >
                      <h6 class="my-auto ms-3">{{ data.name }}</h6>
                    </router-link></template
                  ></Column
                >
                <Column field="category" header="Category">
                  <template #body="{ data }">
                    {{ getCategoryName(data.category) }}
                  </template>
                </Column>
                <Column field="startbid" header="Start bid"> </Column>
                <Column field="latestBid" header="Latest bid"> </Column>
                <Column field="enddate" header="End date">
                  <template #body="{ data }">
                    {{ formatDate(data.enddate) }} ({{ daysLeft(data.enddate) }}
                    days left)
                  </template>
                </Column>
                <Column field="status" header="Status"
                  ><template #body="{ data }"
                    ><span class="badge badge-success badge-sm"
                      >Enabled {{ data.status }}</span
                    ></template
                  ></Column
                >
                <Column field="id" header="Action" width="10%">
                  <template #body="{ data }">
                    <router-link
                      :to="{
                        name: 'Edit Auction Item',
                        params: { id: data.id },
                      }"
                      class="mx-3"
                    >
                      <i class="fas fa-pencil-alt text-secondary"></i>
                    </router-link>
                    <a href="javascript:;" @click="deleteItem(data.id)">
                      <i class="fas fa-trash text-secondary"></i>
                    </a>
                  </template>
                </Column>
                <template #footer>
                  In total there are
                  {{ items ? items.length : 0 }} items.
                </template>
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
