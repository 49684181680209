<template>
  <div class="grid mt-5">
    <Card class="col-12 col-lg-6">
      <template #header><h5>Reset All Items</h5></template>
      <template #content>
        <div class="reset-all-items mt-4">
          <p>
            This action will reset all items in the auction. All bids or
            purchases will be deleted, and item statuses will be reset.
          </p>
          <argon-button
            type="button"
            class="ms-auto btn-danger"
            @click="resetAllItems"
          >
            Reset All Items
          </argon-button>
        </div></template
      >
    </Card>
  </div>
</template>

<script>
import {
  collection,
  getDocs,
  doc,
  writeBatch,
  deleteField,
} from "firebase/firestore";
import { db } from "@/firebase";
import { useToast } from "vue-toastification";
import ArgonButton from "@/components/ArgonButton.vue"; // Assuming ArgonButton is a reusable button component

export default {
  name: "ResetAllItems",
  components: {
    ArgonButton,
  },
  setup() {
    const toast = useToast();

    const resetAllItems = async () => {
      if (
        !confirm(
          "Are you sure you want to reset all items? This action cannot be undone."
        )
      ) {
        return;
      }

      try {
        const auctionItemsSnapshot = await getDocs(
          collection(db, "auctionItems")
        );

        const batch = writeBatch(db);

        for (const itemDoc of auctionItemsSnapshot.docs) {
          const itemId = itemDoc.id;
          const itemData = itemDoc.data();
          const subCollectionName = itemData.isDonationItem
            ? "purchase"
            : "bids";

          // Delete subcollection documents (bids or purchase)
          const subCollectionSnapshot = await getDocs(
            collection(db, "auctionItems", itemId, subCollectionName)
          );
          for (const subDoc of subCollectionSnapshot.docs) {
            batch.delete(subDoc.ref);
          }

          // Update the main document fields
          const itemRef = doc(db, "auctionItems", itemId);
          batch.update(itemRef, {
            latestBidder: deleteField(),
            latestBidderID: deleteField(),
            latestBid: deleteField(),
            winnerSMSSent: false,
            bidCount: 0,
          });
        }

        // Commit the batch
        await batch.commit();

        toast.success("All items have been reset successfully.");
      } catch (error) {
        console.error("Error resetting all items:", error);
        toast.error("Failed to reset all items.");
      }
    };

    return {
      resetAllItems,
    };
  },
};
</script>

<style scoped>
.reset-all-items {
  padding: 20px;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 5px;
}
</style>
