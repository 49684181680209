<template>
  <div>
    <input type="file" @change="handleFileUpload" />
    <button @click="importUsers">Import Users</button>
  </div>
</template>

<script>
import Papa from "papaparse";
import {
  collection,
  addDoc,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
} from "firebase/firestore"; // Import updateDoc and doc
import { db } from "@/firebase"; // Assuming you stored Firebase config in this file

export default {
  data() {
    return {
      csvData: null,
    };
  },
  methods: {
    handleFileUpload(event) {
      const file = event.target.files[0];
      Papa.parse(file, {
        header: true,
        delimiter: ";", // Specify the delimiter if your CSV uses something else, like ";"

        complete: (results) => {
          this.csvData = results.data;
        },
      });
    },
    async importUsers() {
      if (!this.csvData) {
        alert("Please upload a CSV file first.");
        return;
      }

      try {
        for (const user of this.csvData) {
          // Check if the row is empty by verifying if key fields are missing or empty
          if (!user.firstName && !user.lastName && !user.email) {
            console.warn("Skipping empty row:", user);
            continue; // Skip this row if it's empty
          }

          console.log("Processing user:", user); // Log the user data being processed

          // Ensure mobile number starts with '+'
          if (user.mobile && !user.mobile.startsWith("+")) {
            user.mobile = `+${user.mobile}`;
          } else if (!user.mobile) {
            console.warn("Mobile number is missing for user:", user);
          }

          // Lookup the table by assignedTableName
          const tablesRef = collection(db, "tables");
          const tableQuery = query(
            tablesRef,
            where("name", "==", user.assignedTableName)
          );
          const tableSnapshot = await getDocs(tableQuery);

          let assignedTableId = null;
          if (!tableSnapshot.empty) {
            assignedTableId = tableSnapshot.docs[0].id; // Get the first matching table's ID
          } else {
            console.warn(
              `Table ${user.assignedTableName} not found for user:`,
              user
            );
            continue; // Skip this user if the table is not found
          }

          // Convert userEnabled to true if it is "true" or "sant" (case-insensitive)
          const userEnabled = ["true", "sant"].includes(
            user.userEnabled?.toLowerCase()
          );
          console.log(`User enabled status for ${user.email}:`, userEnabled);

          // Prepare the data to be saved
          const userData = {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            fullNameLower: `${user.firstName.toLowerCase()} ${user.lastName.toLowerCase()}`,
            mobile: user.mobile,
            userEnabled: userEnabled, // Set the value based on the check
            assignedTable: assignedTableId, // Assign the table ID
            assignedTableName: user.assignedTableName, // Save the table name as a string
          };

          // Check if the user already exists by first and last name
          const usersRef = collection(db, "users");
          const userQuery = query(
            usersRef,
            where("firstName", "==", user.firstName),
            where("lastName", "==", user.lastName)
          );
          const userSnapshot = await getDocs(userQuery);

          if (!userSnapshot.empty) {
            // If user exists, update the existing document
            const userDocId = userSnapshot.docs[0].id;
            const userDocRef = doc(db, "users", userDocId);
            console.log(
              `Updating existing user: ${user.firstName} ${user.lastName}`
            );
            await updateDoc(userDocRef, userData);
          } else {
            // If user does not exist, create a new document
            console.log(
              `Creating new user: ${user.firstName} ${user.lastName}`
            );
            await addDoc(usersRef, userData);
          }
        }

        alert("Users imported successfully!");
      } catch (error) {
        console.error("Error occurred while importing users:", error);
        alert("An error occurred while importing users.");
      }
    },
  },
};
</script>
