<script>
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import {
  getFirestore,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import Message from "primevue/message";

export default {
  name: "UserEdit",
  setup() {
    const userProfile = ref({
      bio: "",
      company: "",
      email: "",
      firstName: "",
      lastName: "",
      mobile: "",
      password: "",
      repeatPassword: "",
      uid: "",
    });

    const store = useStore(); // Access Vuex store

    const loading = ref(true);
    const showMessage = ref(false); // Add state for success message
    const showError = ref(false); // Add state for error message
    const errorMessage = ref(""); // Add state for error message text
    const db = getFirestore();
    const userDocId = computed(() => store.getters["userDocId"]); // Access the stored document ID

    const loadUserProfile = async () => {
      try {
        if (userDocId.value) {
          const userDoc = await getDoc(doc(db, "users", userDocId.value));
          if (userDoc.exists()) {
            userProfile.value = userDoc.data();
          } else {
            console.log("No such document!");
          }
        } else {
          console.log("No userDocId available.");
        }
      } catch (error) {
        console.error("Error getting document:", error);
      } finally {
        loading.value = false;
      }
    };

    const validatePhoneNumber = (phoneNumber) => {
      const phoneRegex = /^\+\d+/;
      return phoneRegex.test(phoneNumber);
    };

    const validatePasswords = (password, repeatPassword) => {
      return password === repeatPassword;
    };

    const updateProfile = async () => {
      if (!validatePhoneNumber(userProfile.value.mobile)) {
        showError.value = true;
        errorMessage.value = "Phone number must include a valid country code.";
        setTimeout(() => {
          showError.value = false;
          errorMessage.value = "";
        }, 5000);
        return;
      }

      if (!validatePasswords(userProfile.value.password, userProfile.value.repeatPassword)) {
        showError.value = true;
        errorMessage.value = "Passwords do not match.";
        setTimeout(() => {
          showError.value = false;
          errorMessage.value = "";
        }, 5000);
        return;
      }

      try {
        if (userDocId.value) {
          await updateDoc(doc(db, "users", userDocId.value), userProfile.value);
          showMessage.value = true; // Show success message
          setTimeout(() => {
            showMessage.value = false; // Hide message after 3 seconds
          }, 5000);
        } else {
          console.log("No userDocId available for update.");
        }
      } catch (error) {
        console.error("Error updating document:", error);
      }
    };

    onMounted(() => {
      loadUserProfile();
    });

    return {
      userProfile,
      loading,
      showMessage, // Return showMessage state
      showError, // Return showError state
      errorMessage, // Return errorMessage state
      Message,
      updateProfile,
    };
  },
};
</script>

<template>
  <div class="user-edit">
    <h2>Edit Profile</h2>
    <form @submit.prevent="updateProfile">
      <div v-if="loading">Loading...</div>
      <div v-else>
        <div>
          <label for="firstName">First Name:</label>
          <input type="text" id="firstName" v-model="userProfile.firstName" />
        </div>
        <div>
          <label for="lastName">Last Name:</label>
          <input type="text" id="lastName" v-model="userProfile.lastName" />
        </div>
        <div>
          <label for="company">Company:</label>
          <input type="text" id="company" v-model="userProfile.company" />
        </div>
        <div>
          <label for="mobile">Mobile:</label>
          <input type="text" id="mobile" v-model="userProfile.mobile" />
        </div>
        <div>
          <label for="email">Email:</label>
          <input type="email" id="email" v-model="userProfile.email" disabled />
        </div>
        <div>
          <label for="table">Assigned to table:</label>
          <input
            type="text"
            id="table"
            v-model="userProfile.assignedTableName"
            disabled
          />
        </div>
        <div>
          <label for="bio">Bio:</label>
          <textarea id="bio" v-model="userProfile.bio"></textarea>
        </div>
        <div>
          <label for="password">Password:</label>
          <input type="password" id="password" v-model="userProfile.password" />
        </div>
        <div>
          <label for="repeatPassword">Repeat Password:</label>
          <input
            type="password"
            id="repeatPassword"
            v-model="userProfile.repeatPassword"
          />
        </div>

        <button type="submit">Save</button>
        <div v-if="showMessage" class="success-message mt-2">
          <Message severity="success" :closable="false"
            >Profile Updated</Message
          >
        </div>
        <div v-if="showError" class="error-message mt-2">
          <Message severity="error" :closable="false">{{ errorMessage }}</Message>
        </div>
      </div>
    </form>
  </div>
</template>

<style scoped>
.user-edit {
  max-width: 600px;
  margin: auto;
}
form div {
  margin-bottom: 15px;
}
label {
  display: block;
  margin-bottom: 5px;
}
input,
textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}
button {
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  cursor: pointer;
}
button:hover {
  background-color: #45a049;
}
.success-message {
  color: green;
  margin-bottom: 15px;
  text-align: center;
}
.error-message {
  color: red;
  margin-bottom: 15px;
  text-align: center;
}
</style>
