<template>
    <div class="sponsor-container">
      <div class="grid">
        <div class="grid-item" v-for="sponsor in sponsors" :key="sponsor.id">
          <img :src="sponsor.imageUrl" :alt="sponsor.name" class="sponsor-image" />
          <div class="sponsor-name">{{ sponsor.name }}</div>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted } from 'vue';
  import { collection, getDocs } from 'firebase/firestore';
  import { db } from '@/firebase';
  
  const sponsors = ref([]);
  
  const fetchSponsors = async () => {
    const querySnapshot = await getDocs(collection(db, 'sponsors'));
    sponsors.value = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  };
  
  onMounted(fetchSponsors);
  </script>
  
  <style scoped>
  html, body, #app {
  height: 100%;
  margin: 0;
  padding: 0;
}
  .sponsor-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: #1b2750;
    padding: 20px;
  }
  
  .grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    max-width: 1200px;
  }
  
  .grid-item {
    flex: 0 1 calc(16.66% - 20px); /* 16.66% for 6 items per row, minus the gap */
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .sponsor-image {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  .sponsor-name {
    margin-top: 10px;
    font-size: 1.2em;
    font-weight: bold;
    text-align: center;
  }
  </style>
  