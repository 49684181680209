<script setup>
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
//import { DataTable } from "simple-datatables";
import setTooltip from "@/assets/js/tooltip.js";
import { db } from "../../firebase";
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import { useToast } from "vue-toastification";

//Table
import DataTable from "primevue/datatable";
import Column from "primevue/column";
//import ColumnGroup from "primevue/columngroup"; // optional
//import Row from "primevue/row"; // optional

const store = useStore();
const items = ref([]);

const toast = useToast();

const fetchItems = async () => {
  const querySnapshot = await getDocs(collection(db, "category"));
  items.value = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
};

const deleteItem = async (id) => {
  try {
    await deleteDoc(doc(db, "category", id));
    toast.success("Category deleted successfully");

    // Refresh the items list to reflect the deletion
    fetchItems();
  } catch (error) {
    console.error("Error deleting item: ", error);
    toast.error("Error deleting item");
  }
};

onMounted(async () => {
  const message = localStorage.getItem("categoryMessage");

  if (message) {
    toast.success(message);
    localStorage.removeItem("categoryMessage");
  }
  await fetchItems();
  setTooltip(store.state.bootstrap);
});
</script>
<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Auction Categories</h5>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <router-link
                    :to="{ name: 'Create New Category' }"
                    class="mb-0 btn bg-gradient-success btn-sm"
                    >+&nbsp; Add new category</router-link
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="px-0 pb-0 card-body">
            <div class="table-responsive table table-flush">
              <DataTable
                :value="items"
                paginator
                :rows="10"
                :rowsPerPageOptions="[10, 20, 50]"
                tableStyle="min-width: 50rem"
              >
                <Column field="name" header="Name"
                  ><template #body="{ data }">
                    <router-link
                      :to="{
                        name: 'Edit Category',
                        params: { id: data.id },
                      }"
                    >
                      <h6 class="my-auto ms-3">{{ data.name }}</h6>
                    </router-link></template
                  ></Column
                >
                <Column field="status" header="Status"
                  ><template #body="{ data }"
                    ><span class="badge badge-success badge-sm"
                      >Enabled {{ data.status }}</span
                    ></template
                  ></Column
                >

                <Column field="id" header="Action" width="10%">
                  <template #body="{ data }">
                    <router-link
                      :to="{
                        name: 'Edit Category',
                        params: { id: data.id },
                      }"
                      class="mx-3"
                      data-bs-toggle="tooltip"
                      data-bs-original-title="Edit Category"
                    >
                      <i class="fas fa-pencil-alt text-secondary"></i>
                    </router-link>
                    <a
                      href="javascript:;"
                      @click="deleteItem(data.id)"
                      data-bs-toggle="tooltip"
                      data-bs-original-title="Delete Category"
                    >
                      <i class="fas fa-trash text-secondary"></i>
                    </a>
                  </template>
                </Column>
                <template #footer>
                  In total there are
                  {{ items ? items.length : 0 }} categories.
                </template>
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
