<script setup>
import { ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import UserInfo from "./components/UserInfo.vue";
import ArgonButton from "@/components/ArgonButton.vue";

import { doc, setDoc, getDoc } from "firebase/firestore";
import { db } from "@/firebase";

const router = useRouter();
const route = useRoute();
const userId = ref(route.params.userId || ""); // Use route params or another method to determine if editing
const isEditMode = ref(!!userId.value);
const userData = ref({});
const activeStep = ref(0);
const formSteps = 2;
const activeClass = "js-active position-relative";

const loading = ref(true);

const nextStep = () => {
  if (activeStep.value < formSteps) {
    activeStep.value += 1;
  }
};

function handleUpdateUserInfo(data) {
  userData.value = { ...userData.value, ...data };
}

function generateUserId() {
  const timestamp = Date.now();
  const randomNum = Math.floor(Math.random() * 1000);

  return `user_${timestamp}_${randomNum}`;
}

// Determine mode based on userId and fetch data if editing
onMounted(async () => {
  if (isEditMode.value) {
    const docRef = doc(db, "users", userId.value);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      userData.value = docSnap.data();
      loading.value = false;
    } else {
      console.log("No such document!");
      loading.value = false;
    }
  } else {
    loading.value = false;
    userId.value = generateUserId(); // Only generate new ID if creating
  }
});

// Update the handleFinalSubmit function to handle both create and edit
async function handleFinalSubmit(data) {
  let finalData = { ...userData.value, ...data };
  console.log("User Info submit:");
  console.log(finalData);
  if (isEditMode.value) {
    // Update existing user
    try {
      await setDoc(
        doc(db, "users", userId.value),
        { ...userData.value, ...data },
        { merge: true }
      );
      localStorage.setItem("saveMessage", "User Updated");
      console.log(
        "Data successfully updated in Firebase with ID:",
        userId.value
      );
    } catch (error) {
      console.error("Error updating document in Firebase: ", error);
    }
  } else {
    try {
      await setDoc(
        doc(db, "users", userId.value),
        { ...userData.value, ...data },
        { merge: true }
      );
      console.log("Data successfully added to Firebase with ID:", userId.value);
      localStorage.setItem("saveMessage", "User created successfully!");
    } catch (error) {
      console.error("Error adding document to Firebase: ", error);
    }
  }
  // Navigation and state reset after submission
  router.push({ name: "Users" });
}
</script>

<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="multisteps-form">
          <!--form panels-->
          <div class="row">
            <div class="col-12 col-lg-8 m-auto">
              <form class="multisteps-form__form">
                <!--single form panel-->
                <user-info
                  v-if="
                    !isEditMode ||
                    (userData && Object.keys(userData).length > 0)
                  "
                  :class="activeStep === 0 ? activeClass : ''"
                  @next-step="nextStep"
                  @updateUserInfo="handleUpdateUserInfo"
                  :user-data="userData"
                  :user-id="userId"
                />
                <!--single form panel-->
                <div class="button-row d-flex mt-2">
                  <argon-button
                    type="button"
                    color="dark"
                    variant="gradient"
                    class="ms-auto"
                    @click="handleFinalSubmit"
                    >Save</argon-button
                  >
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
