import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index.js';
import store from "./store";
import 'primeflex/primeflex.css'
//import 'primevue/resources/themes/aura-light-blue/theme.css';
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import VueTilt from "vue-tilt.js";
//import VueSweetalert2 from 'vue-sweetalert2';
//import 'sweetalert2/dist/sweetalert2.min.css';
import ArgonDashboard from "./argon-dashboard";
import Toast from "vue-toastification";
import PrimeVue from 'primevue/config';
import VueDatePicker from '@vuepic/vue-datepicker';
import Vue3TouchEvents from "vue3-touch-events";
import '@vuepic/vue-datepicker/dist/main.css'

import 'primeicons/primeicons.css'
import Message from 'primevue/message';
import PrimeButton from 'primevue/button';
import PrimeDialog from 'primevue/dialog';

import Card from 'primevue/card';
import Divider from 'primevue/divider';
import VueLazyload from 'vue-lazyload';
import "vue-toastification/dist/index.css";
const app = createApp(App)

// Ensure we fetch the user on app initialization
store.dispatch('fetchUser');

app.use(store);

app.use(router);
app.use(VueTilt);
//app.use(VueSweetalert2);
app.use(ArgonDashboard);

app.use(PrimeVue);

app.component('Message', Message);
app.component('PrimeButton', PrimeButton);
app.component('PrimeDialog', PrimeDialog);
app.component('Card', Card);
app.component('Divider', Divider);
app.use(VueLazyload, {
    preLoad: 1.3,
    loading: require('@/assets/img/spinner.gif'), // Loading spinner while image loads
    attempt: 1
  });
app.use(Vue3TouchEvents);
app.use(Toast);
app.component('VueDatePicker', VueDatePicker);
app.mount('#app');

