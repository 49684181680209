<script setup>
import { ref, onMounted, defineEmits, defineProps, watch } from "vue";
import {
  getDocs,
  collection,
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { db } from "@/firebase";

import ArgonInput from "@/components/ArgonInput.vue";
import ToggleButton from "primevue/togglebutton";

const firstName = ref("");
const lastName = ref("");
const fullNameLower = ref("");

const email = ref("");
const mobileNumber = ref("");
const password = ref("");
const repeatPassword = ref("");
const userEnabled = ref(false);
const userAdmin = ref(false);

const tables = ref([]);
const selectedTable = ref(null);
const originalTableId = ref(null);

const props = defineProps({
  userData: Object,
  userId: String, // Receive userId as a prop
});

const emit = defineEmits(["updateUserInfo"]);

function updateUserInfo() {
  emit("updateUserInfo", {
    firstName: firstName.value,
    lastName: lastName.value,
    fullNameLower: fullNameLower.value,
    email: email.value,
    mobile: mobileNumber.value,
    password: password.value,
    repeatPassword: repeatPassword.value,
    userEnabled: userEnabled.value,
    userAdmin: userAdmin.value,
    assignedTable: selectedTable.value,
    assignedTableName:
      tables.value.find((table) => table.id === selectedTable.value)?.name ||
      "",
  });
}

async function fetchTables() {
  const tablesCollection = collection(db, "tables");
  const tablesSnapshot = await getDocs(tablesCollection);

  tables.value = tablesSnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  // Custom sort to handle alphanumeric sorting correctly
  tables.value.sort((a, b) => naturalSort(a.name, b.name));
}

// Natural sorting function to handle numeric parts in strings
function naturalSort(a, b) {
  return a.localeCompare(b, undefined, { numeric: true, sensitivity: "base" });
}

async function handleTableChange() {
  if (selectedTable.value !== originalTableId.value) {
    if (originalTableId.value) {
      try {
        const oldTableRef = doc(db, "tables", originalTableId.value);
        await updateDoc(oldTableRef, {
          users: arrayRemove({
            id: props.userId, // Use userId prop
            firstName: firstName.value,
            lastName: lastName.value,
          }),
        });
        console.log("User removed from original table:", originalTableId.value);
      } catch (error) {
        console.error(
          "Error removing user from original table in Firebase: ",
          error
        );
      }
    }

    if (selectedTable.value) {
      try {
        const newTableRef = doc(db, "tables", selectedTable.value);
        await updateDoc(newTableRef, {
          users: arrayUnion({
            id: props.userId, // Use userId prop
            firstName: firstName.value,
            lastName: lastName.value,
          }),
        });
        console.log("User added to new table:", selectedTable.value);
      } catch (error) {
        console.error("Error adding user to new table in Firebase: ", error);
      }
    }
  }

  updateUserInfo();
}
// Watch for changes to firstName and lastName, and update fullNameLower
watch([firstName, lastName], ([newFirstName, newLastName]) => {
  fullNameLower.value = `${newFirstName.toLowerCase()} ${newLastName.toLowerCase()}`;
  updateUserInfo(); // Trigger user info update when fullNameLower changes
});
// Execute code when the component is mounted
onMounted(async () => {
  if (props.userData) {
    firstName.value = props.userData.firstName || "";
    lastName.value = props.userData.lastName || "";
    fullNameLower.value = props.userData.fullNameLower || "";
    email.value = props.userData.email || "";
    mobileNumber.value = props.userData.mobile || "";
    password.value = props.userData.password || "";
    repeatPassword.value = props.userData.repeatPassword || "";
    userEnabled.value =
      props.userData.userEnabled !== undefined
        ? props.userData.userEnabled
        : false;
    userAdmin.value =
      props.userData.userAdmin !== undefined ? props.userData.userAdmin : false;
    originalTableId.value = props.userData.assignedTable || null;
    selectedTable.value = originalTableId.value;
  }

  await fetchTables();
});
</script>

<template>
  <div
    class="card multisteps-form__panel p-3 border-radius-xl bg-white"
    data-animation="FadeIn"
  >
    <h5 class="font-weight-bolder mb-0">About me</h5>
    <p class="mb-0 text-sm">Mandatory informations</p>

    <div class="multisteps-form__content">
      <div class="row mt-3">
        <div class="col-12 col-sm-6">
          <label>First Name</label>
          <argon-input
            id="firstname"
            class="multisteps-form__input"
            type="text"
            placeholder="eg. John"
            v-model="firstName"
            @input="updateUserInfo"
          />
        </div>
        <div class="col-12 col-sm-6 mt-3 mt-sm-0">
          <label>Last Name</label>
          <argon-input
            id="lastname"
            class="multisteps-form__input"
            type="text"
            placeholder="eg. Doe"
            v-model="lastName"
            @input="updateUserInfo"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6 mt-3 mt-sm-0">
          <label>Email Address</label>
          <argon-input
            id="email"
            class="multisteps-form__input autocomplete-off"
            type="email"
            placeholder="eg. user@email.com"
            v-model="email"
            @input="updateUserInfo"
          />
        </div>
        <div class="col-12 col-sm-6">
          <label>Mobile </label>
          <argon-input
            id="mobilenumber"
            class="multisteps-form__input autocomplete-off"
            type="text"
            placeholder="Mobile Number"
            v-model="mobileNumber"
            @input="updateUserInfo"
          />
          <p style="font-size: 12px; color: #af0000">
            Mobile must be with country prefix, ie +467123123)
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-sm-6">
          <label>Password</label>
          <argon-input
            id="password"
            class="multisteps-form__input autocomplete-off"
            type="password"
            placeholder="******"
            v-model="password"
            @input="updateUserInfo"
          />
        </div>
        <div class="col-12 col-sm-6 mt-sm-0">
          <label>Repeat Password</label>
          <argon-input
            id="repeat-password"
            class="multisteps-form__input autocomplete-off"
            type="password"
            placeholder="******"
            v-model="repeatPassword"
            @input="updateUserInfo"
          />
        </div>
        <div class="col-12 col-sm-12 mt-sm-0" style="font-size: 12px">
          <strong>* Note. </strong> Leave password empty or remove existing
          password if you want the user to enter password on login.
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6 mt-3">
          <label for="tableSelection">Select Table</label>
          <select
            id="tableSelection"
            class="form-control"
            v-model="selectedTable"
            @change="handleTableChange"
          >
            <option v-for="table in tables" :key="table.id" :value="table.id">
              {{ table.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-sm-4">
          <span class="float-left">
            <ToggleButton
              id="userEnabled"
              v-model="userEnabled"
              onLabel="User Enabled"
              offLabel="User Disabled"
              onIcon="pi pi-check-circle"
              offIcon="pi pi-times-circle"
              @change="updateUserInfo"
            />
          </span>
        </div>
        <div class="col-sm-8">
          <span class="float-left">
            <ToggleButton
              id="userAdmin"
              v-model="userAdmin"
              onLabel="User is Admin"
              offLabel="User is not Admin"
              onIcon="pi pi-check-circle"
              offIcon="pi pi-times-circle"
              @change="updateUserInfo"
            />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.float-left {
  float: left;
}
.pi-times-circle {
  color: #f5365c;
}
.pi-check-circle {
  color: #4caf50;
}
</style>
