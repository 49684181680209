<script setup>
import { onMounted, ref } from "vue";
import { fetchUsers, deleteUserById } from "@/services/userService"; // Make sure to include deleteUserById
import ArgonAvatar from "@/components/ArgonAvatar.vue";
import ArgonBadge from "@/components/ArgonBadge.vue";
import { useToast } from "vue-toastification";
const items = ref([]);
const toast = useToast();

import img1 from "@/assets/img/reports1.jpg";

onMounted(async () => {
  const fetchedUsers = await fetchUsers();
  // Sort the users by firstName and lastName
  items.value = fetchedUsers.sort((a, b) => {
    const nameA = `${a.firstName} ${a.lastName}`.toUpperCase();
    const nameB = `${b.firstName} ${b.lastName}`.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
});

const deleteUser = async (id) => {
  try {
    const response = await deleteUserById(id);
    if (response.success) {
      items.value = items.value.filter((item) => item.id !== id);
      toast.success("User successfully deleted");
    } else {
      throw new Error("Deletion failed");
    }
  } catch (error) {
    toast.error("Error: " + error.message);
  }
};
</script>

<template>
  <div class="card">
    <div class="table-responsive">
      <table class="table align-items-center mb-0">
        <thead>
          <tr>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Name
            </th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              Status
            </th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              Mobile
            </th>
            <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Email
            </th>
            <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Role
            </th>
            <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items" :key="index">
            <td>
              <div class="d-flex px-2 py-1">
                <argon-avatar
                  :image="img1"
                  class="me-3"
                  size="sm"
                  alt="avatar image"
                  border-radius="lg"
                />
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm">
                    <router-link
                      :to="{
                        name: 'Edit User',
                        params: { userId: item.id },
                      }"
                      >{{ item.firstName }} {{ item.lastName }}</router-link
                    >
                  </h6>
                </div>
              </div>
            </td>
            <td>
              <p class="text-sm text-secondary mb-0">
                <argon-badge
                  size="sm"
                  :color="item.userEnabled ? 'success' : 'secondary'"
                  >{{ item.userEnabled ? "Enabled" : "Disabled" }}</argon-badge
                >
              </p>
            </td>
            <td>
              <p class="text-sm text-secondary mb-0">{{ item.mobile }}</p>
            </td>
            <td class="align-middle text-center text-sm">
              <p class="text-secondary mb-0 text-sm">{{ item.email }}</p>
            </td>
            <td class="align-middle text-center">
              <span class="text-secondary text-sm">{{ item.employed }}</span>
            </td>
            <td class="align-middle text-center">
              <span class="text-secondary text-sm">
                <router-link
                  :to="{
                    name: 'Edit User',
                    params: { userId: item.id },
                  }"
                  ><i class="fas fa-user-edit text-secondary"></i
                ></router-link>
                <span>&nbsp;&nbsp;</span>
                <a
                  href="javascript:;"
                  data-bs-toggle="tooltip"
                  data-bs-original-title="Delete product"
                  @click="deleteUser(item.id)"
                >
                  <i class="fas fa-trash text-secondary"></i> </a
              ></span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
