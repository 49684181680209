<template>
  <router-view />
</template>

<script setup>
import { inject } from "@vercel/analytics";
inject();
import { useStore } from "vuex";
import { auth } from "@/firebase"; // import firebase auth
const store = useStore();

auth.onAuthStateChanged((user) => {
  store.dispatch("fetchUser", user);
});
</script>

<style>
/* Common Styles for the App */
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500&display=swap");

body {
  background: rgb(0, 4, 66);
  width: 100%;
}

#app {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

h2,
.playfair {
  font-family: "Playfair Display", serif;
}

.border-gold {
  border: 1px solid #c39935;
}

.swal2-title {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 1.7rem;
}

.swal2-confirm {
  padding: 10px;
  margin: 10px;
}

.swal2-custom-sucess {
  cursor: pointer;
  outline: 0;
  color: #fff;
  background-color: #c39935;
  border-color: #c39935;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  border: 2px solid transparent;
  padding: 6px 12px;
  font-size: 16px;
  border-radius: 4px;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.swal2-custom-sucess:hover {
  color: #fff;
  background-color: #8b733a;
}

.swal2-custom-cancel {
  cursor: pointer;
  outline: 0;
  color: #000;
  background-color: #c7cfdf;
  border-color: #c39935;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  border: 2px solid transparent;
  padding: 6px 12px;
  font-size: 16px;
  border-radius: 4px;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.swal2-custom-cancel:hover {
  background-color: #c7cfdf;
}
</style>
