<template>
  <Card style="overflow: hidden">
    <template #content>
      <div class="flex-wrap gap-2">
        <div class="flex flex-column">
          <div class="price-display">
            <span>
              Minimum Donation:
              <strong>
                <PriceDisplay
                  :price="item.startbid"
                  :currency="item.currency"
                />
              </strong>
            </span>
          </div>

          <div class="flex">
            <div class="">
              <!-- Show on Desktop-->
              <div v-if="!isBidding && !hasAuctionEnded">
                <div class="flex-wrap">
                  <div class="col-12 text-right mt-2">
                    <CountdownBid
                      :endDateTimestamp="reactiveItem.enddate"
                      class="countdowntext"
                    />
                  </div>
                  <div class="flex px-2">
                    <small class="mr-2" style="color: var(--gray-800)">
                      Place donation anonymously?
                    </small>
                    <InputSwitch v-model="postAnonymously" />
                  </div>
                  <div class="flex m-2">
                    <div class="flex">
                      <Dropdown
                        v-model="selectedBidAmount"
                        :options="bidOptions"
                        optionLabel="label"
                        optionValue="value"
                        placeholder="Select donation amount"
                        @change="handleBidSelection"
                        style="min-width: 200px; line-height: 37px"
                      />
                    </div>

                    <div class="flex" v-if="selectedBidAmount != 'Custom'">
                      <Button
                        label="Place Donation"
                        class="item-btn small btn-darkblue"
                        rounded
                        @click="placeBid"
                      />
                    </div>
                  </div>
                  <div
                    class="input-box flex m-2"
                    v-if="selectedBidAmount === 'Custom'"
                  >
                    <div class="flex">
                      <InputText
                        v-model="formattedBidAmount"
                        placeholder="Donation amount"
                        class="flex"
                        type="text"
                        style="width: 100%"
                        min="0"
                        @input="updateBidAmount"
                      />
                    </div>
                    <div class="flex" v-if="selectedBidAmount === 'Custom'">
                      <Button
                        label="Place Donation"
                        class="item-btn small btn-darkblue"
                        rounded
                        @click="placeBid"
                      />
                    </div>
                  </div>
                </div>

                <div class="grid grid-nogutter">
                  <div class="col-12">
                    <p
                      v-if="errorMessage"
                      class="text-red-500 text-sm pb-0 mb-0"
                    >
                      {{ errorMessage }}
                    </p>
                  </div>
                </div>
                <div class="flex justify-content-start align-content-center">
                  <div
                    class="flex align-items-center justify-content-start p-1"
                  >
                    <i
                      class="pi pi-info-circle"
                      style="font-size: 1rem; color: var(--gray-800)"
                    ></i>
                  </div>
                  <div
                    class="flex align-items-center justify-content-start flex-grow-1"
                  >
                    <small
                      class="m-1"
                      style="
                        color: var(--gray-800);
                        font-size: 0.75rem;
                        text-align: left;
                      "
                    >
                      <strong>Donation Item,</strong> You can purchase this item
                      multiple times and donate any amount above the listed item
                      price.</small
                    >
                  </div>
                </div>
              </div>
              <div v-else-if="hasAuctionEnded" class="flex">
                <p>Bidding has ended.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Card>
  <!-- Popup for Highest Bidder -->
  <div v-if="showHighestBidderPopup" class="modal-overlay">
    <div class="modal-content">
      <h3
        style="font-family: Playfair Display, serif;
    font-weight: 400;
    font-size: 1.9rem;
    text-transform: uppercase;
}"
      >
        THANKS FOR YOUR DONATION!
      </h3>
      <p>
        Your donation will support <br />The Perfect World Foundation’s work.
      </p>
      <p>
        <span
          class="p-chip-icon pi pi-star-fill"
          data-pc-section="icon"
          style="color: #f0ad4e"
        ></span>
        Thank you!
        <span
          class="p-chip-icon pi pi-star-fill"
          data-pc-section="icon"
          style="color: #f0ad4e"
        ></span>
      </p>
      <Button
        label="Close"
        @click="showHighestBidderPopup = false"
        style="background: #1b2750"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, watch, reactive, onMounted, onUnmounted, computed } from "vue";
import {
  doc,
  addDoc,
  getDoc,
  updateDoc,
  collection,
  onSnapshot,
} from "firebase/firestore";
import { useStore } from "vuex";
import { db } from "@/firebase";
import Card from "primevue/card";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import CountdownBid from "./CountdownBid.vue";
import InputSwitch from "primevue/inputswitch";
import PriceDisplay from "@/views/auction/components/DisplayPrice.vue";

const props = defineProps({
  item: {
    type: Object,
    required: true,
    default: () => ({
      id: "",
      latestBid: 0,
      startbid: 0,
      enddate: { seconds: 0 },
    }), // Provide default values
  },
  user: {
    type: Object,
    required: true,
    default: () => ({ id: "", name: "", firstName: "", lastName: "" }), // Provide default values
  },
});
//const emit = defineEmits(["bidPosted"]);
const store = useStore();

const isBidding = ref(false);
const bidAmount = ref("");
const formattedBidAmount = ref("");
const errorMessage = ref("");
const selectedBidAmount = ref("");
const bidIncrement = 50;
const isOpen = ref(false);
const postAnonymously = ref(false); // New reactive property for the InputSwitch
const showHighestBidderPopup = ref(false); // Flag for showing the popup

const bidOptions = [
  { label: "5,000 SEK", value: 5000 },
  { label: "10,000 SEK", value: 10000 },
  { label: "25,000 SEK", value: 25000 },
  { label: "50,000 SEK", value: 50000 },
  { label: "100,000 SEK", value: 100000 },
  { label: "1,000,000 SEK", value: 1000000 },
  { label: "Custom amount", value: "Custom" },
];

const reactiveItem = reactive({ ...props.item });
const reactiveUser = reactive({ ...props.user });
const userDocId = props.user.id;

let unsubscribe;

const subscribeToItem = (itemId) => {
  const itemDocRef = doc(db, "auctionItems", itemId);
  unsubscribe = onSnapshot(itemDocRef, (doc) => {
    if (doc.exists()) {
      const itemData = doc.data();
      reactiveItem.latestBid = Number(itemData.latestBid);
      reactiveItem.latestBidder = itemData.latestBidder;
      reactiveItem.description = itemData.description;
      reactiveItem.enddate = itemData.enddate;
      reactiveItem.startbid = Number(itemData.startbid);
      reactiveItem.currency = itemData.currency;
    }
  });
};

watch(
  () => props.item,
  (newItem) => {
    Object.assign(reactiveItem, newItem);
    if (unsubscribe) unsubscribe();
    subscribeToItem(newItem.id);
  },
  { immediate: true }
);

watch(
  () => props.user,
  (newUser) => {
    Object.assign(reactiveUser, newUser);
  },
  { immediate: true }
);

watch(
  () => bidAmount.value,
  (newValue) => {
    if (newValue !== "") {
      formattedBidAmount.value = formatNumber(newValue);
    }
  }
);

function updateBidAmount(event) {
  const value = event.target.value.replace(/,/g, "");
  bidAmount.value = value;
}

function formatNumber(value) {
  const number = parseFloat(value);
  if (isNaN(number)) return "";
  return number.toLocaleString("en-US");
}

function capitalizeFirstLetter(string) {
  if (typeof string !== "string" || string.length === 0) {
    return "";
  }
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

function getFormattedName(firstName, lastName) {
  const formattedFirstName = capitalizeFirstLetter(firstName);
  const formattedLastName = capitalizeFirstLetter(lastName);
  return `${formattedFirstName} ${formattedLastName}`.trim();
}

function validateBid(
  userPostBid,
  latestBid,
  startBid,
  highestMaxBid,
  currUserIsMaxBidder,
  bidData = null
) {
  if (isNaN(userPostBid) || userPostBid <= 0) {
    throw new Error("Please enter a valid number.");
  }
  if (userPostBid < startBid && bidData === null) {
    throw new Error(
      "Your donation must be higher than the minimum amount, " +
        startBid +
        " SEK"
    );
  }
  if (userPostBid <= latestBid && bidData !== null) {
    throw new Error("Your bid must be higher than the current bid.");
  }
  if (userPostBid <= highestMaxBid && currUserIsMaxBidder) {
    throw new Error(
      `Your new bid must be higher than your current existing bid ${highestMaxBid}.`
    );
  }
  if (userPostBid < latestBid + bidIncrement && bidData !== null) {
    throw new Error("Your bid must be higher than the current highest bid.");
  }
}

async function fetchLatestBid(itemDocRef) {
  const itemSnapshot = await getDoc(itemDocRef);
  if (itemSnapshot.exists()) {
    return itemSnapshot.data();
  } else {
    throw new Error("Item not found.");
  }
}

//Place a new Bid
async function placeNewBid(
  purchasesCollection,
  userPostBid,
  user,
  comment,
  latestBid,
  userDocId,
  anonymous
) {
  if (latestBid === undefined) latestBid = userPostBid;

  if (!userDocId) {
    throw new Error("User UID is missing.");
  }

  const bidData = {
    itemName: props.item.name,
    itemID: props.item.id,
    itemNumber: props.item.itemNumber,
    itemImage: props.item.resizedImages[0].url,
    maxBid: userPostBid,
    userBid: latestBid,
    timestamp: new Date(),
    comment: comment,
  };

  if (anonymous) {
    bidData.user = {
      uid: userDocId,
      name: "Anonymous",
      mobile: props.user.mobile,
      hiddenName:
        props.user.name || getFormattedName(user.firstName, user.lastName),
      table: props.user.assignedTableName,
      tableId: props.user.assignedTable,
    };
  } else {
    bidData.user = {
      uid: userDocId,
      name: props.user.name || getFormattedName(user.firstName, user.lastName),
      mobile: props.user.mobile,
      table: props.user.assignedTableName,
      tableId: props.user.assignedTable,
    };
  }

  await addDoc(purchasesCollection, bidData);
}

function handleBidSelection() {
  if (selectedBidAmount.value !== "Custom") {
    bidAmount.value = selectedBidAmount.value.toString();
  } else {
    bidAmount.value = "";
  }
}

const placeBid = async () => {
  try {
    if (!isBidding.value) {
      isBidding.value = true;
      errorMessage.value = "";

      const userPostBid = parseFloat(bidAmount.value.replace(/,/g, ""));
      if (isNaN(userPostBid) || userPostBid <= 0) {
        throw new Error("Please enter a valid number.");
      }

      const firebaseUser = store.getters["user"].data;
      const customUser = store.getters["customUser"];

      const user = firebaseUser || customUser;

      if (!userDocId) {
        throw new Error("User not logged in or user data unavailable.");
      }

      const itemDocRef = itemDoc.value; // Get the actual DocumentReference
      const itemData = await fetchLatestBid(itemDocRef);

      const latestBid = Number(itemData.latestBid) || 0;
      const startBid = Number(itemData.startbid) || 0;
      let bidCounter = itemData.bidCount || 0;
      let highestMaxBid = latestBid;

      let currUserIsMaxBidder = false;

      validateBid(
        userPostBid,
        latestBid,
        startBid,
        highestMaxBid,
        currUserIsMaxBidder
      );

      const purchasesCollection = collection(itemDocRef, "purchase");
      let newUserPostBid = userPostBid;

      newUserPostBid = userPostBid;

      await placeNewBid(
        purchasesCollection,
        newUserPostBid,
        user,
        "New Donation",
        undefined,
        userDocId,
        postAnonymously.value
      );
      bidCounter += 1;

      const latestBidderName = postAnonymously.value
        ? "Anonymous"
        : props.user.name ||
          getFormattedName(props.user.firstName, props.user.lastName);

      const bidderData = {
        latestBidderID: props.user.id,
        latestBidder: latestBidderName,
      };

      await updateDoc(itemDocRef, {
        latestBid: newUserPostBid,
        ...bidderData,
        bidCount: bidCounter, // Increment bid count
      });

      reactiveItem.latestBid = newUserPostBid;
      reactiveItem.latestBidder = bidderData.latestBidder;
      reactiveItem.latestBidderUID = bidderData.latestBidderID;
      isOpen.value = false;
      showHighestBidderPopup.value = true;

      // Reset the bid options and bid amount
      selectedBidAmount.value = null; // Reset dropdown to default value
      bidAmount.value = ""; // Clear the input field
    }
  } catch (error) {
    errorMessage.value = error.message;
  } finally {
    isBidding.value = false;
  }
};

const hasAuctionEnded = computed(() => {
  const now = new Date();
  return now > new Date(reactiveItem.enddate.seconds * 1000);
});

const itemDoc = computed(() => {
  if (!props.item || !props.item.id) {
    console.error("Item prop is null or undefined", props.item);
    return null;
  }
  return doc(db, "auctionItems", props.item.id);
});

onMounted(() => {
  if (props.item && props.item.id) {
    subscribeToItem(props.item.id);
  }
});

onUnmounted(() => {
  if (unsubscribe) {
    unsubscribe();
  }
});
</script>

<style lang="scss" scoped>
.item-btn {
  border-radius: 6px;
  margin-left: 5px;
}
.item-btn.small {
  font-size: 0.75rem;
}
.scalein {
  animation: scalein 1s forwards;
}

.animation-duration-1000 {
  animation-duration: 1s;
}

@keyframes scalein {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.text-red-500 {
  color: red;
}
.countdowntext {
  color: #000000;
}

:deep(.modal-style) {
  box-sizing: border-box;
  width: 100%;
  color: #333;
  background-color: #ffffff;
  border-radius: 1rem 1rem 0 0;

  @media (prefers-color-scheme: light) {
    color: black;
    background-color: #f7f2fa;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 37%);
  }
}
.price-display {
  border-radius: 5px;
  padding: 3px;
  padding-left: 5px;
  background-color: #f1f5f9;
  color: #000000;
  text-align: left;
  margin-bottom: 2px;
}

.btn-gold {
  background-color: #c39935;
  border-color: #c39935;
}
.btn-green {
  background-color: #009036;
  border-color: #009036;
}
.btn-darkblue {
  background-color: #1b2750;
  border-color: #1b2750;
}
.btn-lightblue {
  background-color: #00a1e4;
  border-color: #00a1e4;
}
.bid-action {
  background: #1b2750;
}
.bid-action:hover {
  background: #1b2750;
  border-color: #1b2750;
}
.bid-action:focus {
  background: #1b2750;
  border-color: #1b2750;
}
.highest-bid-popup {
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -20%);
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.modal-content {
  background: #fff;
  padding: 20px;
  margin: 20px;
  border-radius: 8px;
  max-width: 500px;
  text-align: center;
}
.p-dropdown-items-wrapper ul {
  padding-left: 1rem;
}
</style>
