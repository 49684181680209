<script setup>
import { ref } from "vue";
import AppMenuItem from "./AppMenuItem.vue";

const model = ref([
  {
    label: "Auction Items",
    items: [
      {
        label: "Auction Items List",
        icon: "pi pi-fw pi-objects-column",
        to: "/admin/auctionitems",
      },
      {
        label: "Auction Category List",
        icon: "pi pi-fw pi-tags",
        to: "/admin/category",
      },
    ],
  },
  {
    label: "Users",
    items: [
      {
        label: "All Users",
        icon: "pi pi-fw pi-users",
        to: "/admin/users",
      },
      {
        label: "Create New User",
        icon: "pi pi-fw pi-user-plus",
        to: "/admin/users/create",
      },
    ],
  },

  {
    label: "Admin Features",
    items: [
      {
        label: "Manually Add Bid",
        icon: "pi pi-fw pi-money-bill",
        to: "/admin/manualbid",
      },
      {
        label: "Live Auction",
        icon: "pi pi-fw pi-megaphone",
        to: "/admin/liveauction",
      },
      {
        label: "Table Management",
        icon: "pi pi-fw pi-address-book",
        to: "/admin/tables",
      },
      {
        label: "Sold Items",
        icon: "pi pi-fw pi-money-bill",
        to: "/admin/solditems",
      },
      {
        label: "Sponsors",
        icon: "pi pi-fw pi-heart-fill",
        to: "/admin/sponsors",
      },
      {
        label: "Admin Tools",
        icon: "pi pi-fw pi-file-export",
        to: "/admin/exportitems",
      },
      {
        label: "Back to Auction Items",
        icon: "pi pi-fw pi-arrow-circle-left",
        to: "/auction/dashboard",
      },
    ],
  },
  {
    label: "TV Display",
    items: [
      {
        label: "Display 0 - Auto Play All",
        icon: "pi pi-fw pi-desktop",
        to: "/display/autoplay",
      },
      {
        label: "Display 1 - Show Items",
        icon: "pi pi-fw pi-desktop",
        to: "/display/auctionitems",
      },
      {
        label: "Display 2 - Latest Bids",
        icon: "pi pi-fw pi-desktop",
        to: "/display/latestbids",
      },
      {
        label: "Display 3 - Auction Totals",
        icon: "pi pi-fw pi-desktop",
        to: "/display/auctiontotals",
      },
      {
        label: "Display 4 - Sponsors",
        icon: "pi pi-fw pi-desktop",
        to: "/display/sponsors",
      },
      {
        label: "Display 5 - Summary",
        icon: "pi pi-fw pi-desktop",
        to: "/display/summary",
      },
    ],
  },
]);
</script>

<template>
  <div>
    <ul class="layout-menu">
      <template v-for="(item, i) in model" :key="item.label">
        <app-menu-item
          v-if="!item.separator"
          :item="item"
          :index="i"
        ></app-menu-item>
        <li v-if="item.separator" class="menu-separator"></li>
      </template>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.backto {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
</style>
