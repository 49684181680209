<script setup>
import { defineProps, useAttrs } from "vue";
import Button from "primevue/button";

const props = defineProps({
  toastProps: {
    type: Object,
    required: true,
  },
  closeToast: {
    type: Function,
    required: false,
  },
  data: {
    type: Object,
    required: false,
  }
});

const attrs = useAttrs();
const notification = props.toastProps.data || props.data;

const handleClick = () => {
  if (notification.onClick) {
    notification.onClick();
  }
};
</script>

<template>
  <span v-bind="attrs">
    You have been outbid on
    <strong
      ><a href="#" @click="handleClick">{{ notification.itemName }}</a></strong
    >
  </span>
  <Button
    label="Place new bid"
    severity="success"
    size="small"
    class="btn-small ml-2"
    outlined
    rounded
    @click="handleClick"
  />
</template>

<style scoped>
.btn-small {
  font-size: 0.8rem;
  padding: 6px;
  color: #000000;
  border-color: #000000;
  border-radius: 0.5rem;
}
</style>
