import { createStore } from "vuex";
import auctionItemsList from './modules/auctionItemsList'; // Import the module
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from 'firebase/auth';
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  query,
  where,
  getDocs,
  collection,
  updateDoc,
} from 'firebase/firestore';

const auth = getAuth();
const db = getFirestore();

import bootstrap from "bootstrap/dist/js/bootstrap.min.js";

export default createStore({
  modules: {
    auctionItemsList, // Register the module
  },
  state: {
    hideConfigButton: false,
    isPinned: true,
    showConfig: false,
    isRTL: false,
    color: "",
    sidebarType: "bg-white",
    darkMode: false,
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    layout: "default",
    bootstrap,
    user: {
      loggedIn: false,
      data: null,
      userAdmin: JSON.parse(localStorage.getItem('userAdmin')) || false, // Retrieve from localStorage
    },
    userAdmin: JSON.parse(localStorage.getItem('userAdmin')) || false, // Retrieve from localStorage
    isAuthenticated: false,
    customUser: JSON.parse(localStorage.getItem('customUser')) || null,
    categories: [],
    userDocId: localStorage.getItem('userDocId') || null,  // Retrieve from localStorage
  },
  mutations: {
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    setShowSidenav(state, value) {
      state.showSidenav = value;
    },
    navbarMinimize(state) {
      const sidenav_show = document.querySelector(".g-sidenav-show");
      if (sidenav_show.classList.contains("g-sidenav-hidden")) {
        sidenav_show.classList.remove("g-sidenav-hidden");
        sidenav_show.classList.add("g-sidenav-pinned");
        state.isPinned = true;
      } else if (sidenav_show.classList.contains("g-sidenav-pinned")) {
        sidenav_show.classList.add("g-sidenav-hidden");
        sidenav_show.classList.remove("g-sidenav-pinned");
        state.isPinned = false;
      } else if (window.innerWidth < 1200) {
        sidenav_show.classList.add("g-sidenav-pinned");
        state.isPinned = true;
      } else {
        sidenav_show.classList.add("g-sidenav-hidden");
      }
    },
    setSidebarType(state, payload) {
      state.sidebarType = payload;
    },
    navbarFixed(state) {
      state.isNavFixed = !state.isNavFixed;
    },
    toggleDefaultLayout(state) {
      state.showNavbar = !state.showNavbar;
      state.showSidenav = !state.showSidenav;
      state.showFooter = !state.showFooter;
    },
    // Authentication mutations
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
      state.isAuthenticated = value;
    },
    SET_USER(state, data) {
      state.user.data = data;
    },
    SET_USER_ISADMIN(state, isAdmin) {
      state.userAdmin = isAdmin;
    },
    setCategories(state, categories) {
      state.categories = categories;
    },
    SET_CUSTOM_USER(state, user) {
      state.customUser = user;
      state.isAuthenticated = !!user;
      if (user) {
        localStorage.setItem('customUser', JSON.stringify(user));
      } else {
        localStorage.removeItem('customUser');
      }
    },
    SET_USER_DOC_ID(state, id) { // Ensure the ID is stored as a string
      state.userDocId = id;
      if (id) {
        localStorage.setItem('userDocId', id);
      } else {
        localStorage.removeItem('userDocId');
      }
    },
  },
  actions: {
    toggleSidebarColor({ commit }, payload) {
      commit("setSidebarType", payload);
    },
    // Authentication actions
    async register(context, { email, password, firstName, lastName }) {
      const response = await createUserWithEmailAndPassword(auth, email, password);
      if (response) {
        const userProfile = {
          email: response.user.email,
          firstName,
          lastName,
          uid: response.user.uid,
        };
        await setDoc(doc(db, 'users', response.user.uid), userProfile);
        context.commit('SET_USER', userProfile);
        context.commit('SET_LOGGED_IN', true);
        context.commit('SET_USER_DOC_ID', response.user.uid); // Store the document ID
      } else {
        throw new Error('Unable to register user');
      }
    },
    async logIn(context, { email, password }) {
      const response = await signInWithEmailAndPassword(auth, email, password);
      if (response) {
        await context.dispatch('fetchUserProfile', response.user);
        context.commit('SET_LOGGED_IN', true);
      } else {
        throw new Error('login failed');
      }
    },
    async logOut(context) {
      await signOut(auth);
      context.commit('SET_USER', null);
      context.commit('SET_LOGGED_IN', false);
      context.commit('SET_USER_ISADMIN', false);
      context.commit('SET_CUSTOM_USER', null); // Reset custom user state
      context.commit('SET_USER_DOC_ID', null); // Reset user document ID
    },
    fetchUser(context) {
      return new Promise((resolve) => {
        onAuthStateChanged(auth, async (user) => {
          if (user) {
            await context.dispatch('fetchUserProfile', user);
            context.commit('SET_LOGGED_IN', true);
            
          } else {
            context.commit('SET_USER', null);
            context.commit('SET_LOGGED_IN', false);
          }
          resolve(user);
        });
      });
    },
    async fetchUserProfile(context, user) {
      let userDoc = await getDoc(doc(db, 'users', user.uid));

      if (!userDoc.exists()) {
        const emailQuery = query(collection(db, 'users'), where('email', '==', user.email));
        const querySnapshot = await getDocs(emailQuery);
        
        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            userDoc = doc;
          });
        }
      }

      if (userDoc.exists()) {
        context.commit("SET_USER", userDoc.data());
        context.commit("SET_USER_DOC_ID", userDoc.id); // Store the document ID
      }
    },
    async fetchCategories({ commit }) {
      const querySnapshot = await getDocs(collection(db, 'category'));
      const categories = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      commit('setCategories', categories);
    },
    // Custom user login action
    async customLogIn(context, { firstName, password }) {
      const q = query(
        collection(db, 'users'),
        where('firstName', '==', firstName),
        where('password', '==', password)
      );
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const userDoc = querySnapshot.docs[0];
        const user = userDoc.data();
        user.id = userDoc.id; // Add the userDoc.id to the user data
        
        context.commit('SET_CUSTOM_USER', user);
        context.commit('SET_LOGGED_IN', true); // Set loggedIn state to true
        context.commit('SET_USER_DOC_ID', userDoc.id); // Store the document ID
        
        if(userDoc.userAdmin) {
          context.commit('SET_USER_ISADMIN', true); 
        }
        
        if (!user.loggedInBefore) {
          await updateDoc(doc(db, 'users', userDoc.id), { loggedInBefore: true });
        }
      } else {
        throw new Error('login failed');
      }
    },
  },
  getters: {
    user(state) {
      return state.user;
    },
    isAuthenticated(state) {
      return state.isAuthenticated || !!state.customUser;
    },
    userIsAdmin(state) {
      return state.customUser?.userAdmin || false; // Add null check here
    },
    categories(state) {
      return state.categories;
    },
    getCategoryName: (state) => (categoryId) => {
      const category = state.categories.find(cat => cat.id === categoryId);
      return category ? category.name : 'Unknown Category';
    },
    customUser(state) {
      return state.customUser;
    },
    userDocId(state) { // Ensure this getter returns a string
      return state.userDocId;
    },
  },
});
