<template>
  <div class="layout-wrapper layout-static" :class="containerClass">
    <app-topbar></app-topbar>
    <div class="layout-sidebar admin-view">
      <app-sidebar></app-sidebar>
    </div>
    <div class="layout-main-container admin-view">
      <div class="layout-main">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, computed, onBeforeMount, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
import { auth } from "@/firebase"; // import firebase auth
//import Configurator from "@/examples/Configurator.vue";
//import Sidenav from "@/examples/Sidenav";
//import AppFooter from "@/examples/Footer.vue";
import AppTopbar from "@/layout/AppTopbarAdmin.vue";
import AppSidebar from "@/layout/AppSidebarAdmin.vue";
import { useLayout } from "@/layout/composables/layout";

const store = useStore();
auth.onAuthStateChanged((user) => {
  store.dispatch("fetchUser", user);
});

//NEW
const { layoutConfig, layoutState, isSidebarActive } = useLayout();
const outsideClickListener = ref(null);
const body = document.getElementsByTagName("body")[0];

watch(isSidebarActive, (newVal) => {
  if (newVal) {
    bindOutsideClickListener();
  } else {
    unbindOutsideClickListener();
  }
});

const containerClass = computed(() => ({
  "layout-theme-light": layoutConfig.darkTheme.value === "light",
  "layout-theme-dark": layoutConfig.darkTheme.value === "dark",
  "layout-overlay": layoutConfig.menuMode.value === "overlay",
  "layout-static": layoutConfig.menuMode.value === "static",
  "layout-static-inactive":
    layoutState.staticMenuDesktopInactive.value &&
    layoutConfig.menuMode.value === "static",
  "layout-overlay-active": layoutState.overlayMenuActive.value,
  "layout-mobile-active": layoutState.staticMenuMobileActive.value,
  "p-ripple-disabled": layoutConfig.ripple.value === false,
}));

const bindOutsideClickListener = () => {
  if (!outsideClickListener.value) {
    outsideClickListener.value = (event) => {
      if (isOutsideClicked(event)) {
        layoutState.overlayMenuActive.value = false;
        layoutState.staticMenuMobileActive.value = false;
        layoutState.menuHoverActive.value = false;
      }
    };
    document.addEventListener("click", outsideClickListener.value);
  }
};

const unbindOutsideClickListener = () => {
  if (outsideClickListener.value) {
    document.removeEventListener("click", outsideClickListener.value);
    outsideClickListener.value = null;
  }
};

const isOutsideClicked = (event) => {
  const sidebarEl = document.querySelector(".layout-sidebar");
  const topbarEl = document.querySelector(".layout-menu-button");
  return !(
    sidebarEl.isSameNode(event.target) ||
    sidebarEl.contains(event.target) ||
    topbarEl.isSameNode(event.target) ||
    topbarEl.contains(event.target)
  );
};

onBeforeMount(() => {
  store.commit("setShowSidenav", true);
  store.state.showNavbar = true;
  store.state.hideConfigButton = true;
  store.commit("toggleDefaultLayout");
  body.classList.remove("bg-gray-100");
});

onBeforeUnmount(() => {
  store.state.hideConfigButton = false;
  store.commit("setShowSidenav", false);
  store.commit("toggleDefaultLayout");
  body.classList.add("bg-gray-100");
});

auth.onAuthStateChanged((user) => {
  store.dispatch("fetchUser", user);
});
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500&display=swap");

body {
  background: rgb(0, 4, 66);
}

#app {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

h2,
.playfair {
  font-family: "Playfair Display", serif;
}

.border-gold {
  border: 1px solid #c39935;
}

.swal2-title {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 1.7rem;
}

.swal2-confirm {
  padding: 10px;
  margin: 10px;
}

.swal2-custom-sucess {
  cursor: pointer;
  outline: 0;
  color: #fff;
  background-color: #c39935;
  border-color: #c39935;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  border: 2px solid transparent;
  padding: 6px 12px;
  font-size: 16px;
  border-radius: 4px;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.swal2-custom-sucess:hover {
  color: #fff;
  background-color: #8b733a;
}

.swal2-custom-cancel {
  cursor: pointer;
  outline: 0;
  color: #000;
  background-color: #c7cfdf;
  border-color: #c39935;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  border: 2px solid transparent;
  padding: 6px 12px;
  font-size: 16px;
  border-radius: 4px;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.swal2-custom-cancel:hover {
  background-color: #c7cfdf;
}
</style>
