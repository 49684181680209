<template>
  <div
    class="diaply-container"
    :style="{
      backgroundImage:
        'url(' + require('@/assets/img/tpwauction-min-blur.jpg') + ')',
    }"
  >
    <h1>Latest Bids</h1>
    <div class="scroll-container" ref="scrollContainer">
      <transition-group name="bid" tag="ul" class="bids-list">
        <li v-for="bid in latestBids" :key="bid.id" class="bid-block flex">
          <div class="bid-number flex align-items-center pl-3 pr-3 text-3xl">
            {{ bid.itemNumber }}
          </div>
          <div class="bid-image flex align-items-center">
            <img :src="bid.itemImage" />
          </div>
          <div class="bid-content flex align-items-center text-left text-3xl">
            <span>
              {{ bid.user.name }} <br />
              {{ formatCurrency(bid.userBid) }}</span
            >
          </div>
        </li>
      </transition-group>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from "vue";
import { db } from "@/firebase";
import {
  collectionGroup,
  query,
  orderBy,
  limit,
  onSnapshot,
} from "firebase/firestore";
//import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  name: "LatestBidsView",
  setup() {
    const latestBids = ref([]);
    const latestBid = ref(null);
    const scrollContainer = ref(null);
    let scrollInterval = null;
    let initialDelayTimeout = null;
    let resetDelayTimeout = null;

    const formatCurrency = (value, currencyCode = "SEK") => {
      const formattedValue = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 0, // No decimals for bids
      }).format(value);

      return `${formattedValue} ${currencyCode}`;
    };

    /*const showToast = (bid) => {
      const formattedBid = formatCurrency(bid.userBid); // Format the bid.userBid value

      const toastContent = `
    <div style="display: flex; align-items: center; width: 100%;">
      <img src="${bid.itemImage}" alt="${bid.itemName}" style="width: 150px; margin-right: 10px;" />
      <div class="grid">
        <span class="text-2xl col-12 text-latestbid" style="color: #000000;">${bid.itemNumber}</span>
        <span class="col-12 text-xl" style="color: #000000;"><strong>${bid.user.name}</strong><br/><strong>${formattedBid}</strong></span>
      </div>
    </div>
  `;
      toast(toastContent, {
        position: "bottom-right",
        autoClose: 5000,
        limit: 1,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        dangerouslyHTMLString: true,
        style: { width: "600px" },
      });
    }; */

    const fetchLatestBids = () => {
      const q = query(
        collectionGroup(db, "bids"),
        orderBy("timestamp", "desc"),
        limit(50)
      );

      onSnapshot(q, (querySnapshot) => {
        const bids = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        latestBids.value = bids;

        if (bids.length > 0) {
          const newBid = bids[0];
          if (!latestBid.value || latestBid.value.id !== newBid.id) {
            //console.log('New latest bid:', newBid);
            latestBid.value = newBid;
            //showToast(newBid);
          }
        }
      });
    };

    const startAutoScroll = () => {
      const container = scrollContainer.value;
      let scrollStep = 1; // Scroll step in pixels

      const performScroll = () => {
        if (
          container.scrollTop + container.clientHeight >=
          container.scrollHeight
        ) {
          // When the last bid is reached, pause for 5 seconds
          clearInterval(scrollInterval);

          resetDelayTimeout = setTimeout(() => {
            container.scrollTop = 0; // Reset to the top
            scrollInterval = setInterval(performScroll, 60); // Restart scrolling
          }, 5000); // 5 seconds pause
        } else {
          container.scrollTop += scrollStep; // Continue scrolling
        }
      };

      initialDelayTimeout = setTimeout(() => {
        scrollInterval = setInterval(performScroll, 60);
      }, 5000); // Initial delay before starting the scroll
    };
    const stopAutoScroll = () => {
      //console.log("Stopping auto scroll");
      if (scrollInterval) {
        clearInterval(scrollInterval);
        scrollInterval = null;
      }
      if (initialDelayTimeout) {
        clearTimeout(initialDelayTimeout);
        initialDelayTimeout = null;
      }
      if (resetDelayTimeout) {
        clearTimeout(resetDelayTimeout);
        resetDelayTimeout = null;
      }
    };

    onMounted(() => {
      fetchLatestBids();
      startAutoScroll();
    });

    onBeforeUnmount(() => {
      stopAutoScroll();
    });

    return {
      latestBids,
      latestBid,
      scrollContainer,
      formatCurrency,
    };
  },
};
</script>

<style scoped>
.diaply-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: #2e2e2e; /* Adjust background color as needed */
  background-size: cover;
  color: #fff; /* Text color */
  position: relative;
}

.scroll-container {
  width: 70vh;
  height: 70vh; /* Adjust height as needed */
  overflow: hidden; /* Hide scrollbar */
  position: relative;
}

h1 {
  color: #ffffff !important;
}

.bids-list {
  list-style: none;
  padding: 0;
  z-index: 5;
  width: 90%;
  margin: 0 auto;
}
.bid-block {
  background: rgb(249 249 249 / 19%);
  border-radius: 8px;
  padding: 16px;
  margin: 8px 0;
  transition: transform 0.3s ease;
  display: flex;
}
.bid-content {
  display: flex;
  margin-left: 20px;
  justify-content: center;
  align-items: center;
  font-family: "Playfair Display", serif;
}
.bid-image {
  display: flex;
  justify-content: center;
  img {
    max-width: 250px;
    height: auto;
  }
}

.bid-enter-active,
.bid-leave-active {
  transition: all 0.5s;
}
.bid-enter {
  opacity: 0;
  transform: translateY(-20px);
}
.bid-enter-to {
  opacity: 1;
  transform: translateY(0);
}
.Toastify__toast-body {
  white-space: normal;
}
</style>
