<template>
  <transition name="bounce" @after-leave="onAfterLeave">
    <div v-if="showLocal" class="overlay">
      <div class="notification">
        <h1 class="text-gold">NEW BID!</h1>
        <div v-html="message"></div>
        <!-- Use v-html to render HTML content -->
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    message: String,
    show: Boolean,
  },
  data() {
    return {
      showLocal: false,
    };
  },
  watch: {
    show(newVal) {
      if (newVal) {
        this.showLocal = true;
        setTimeout(() => {
          this.showLocal = false;
        }, 5000); // Show notification for 5 seconds
      }
    },
  },
  methods: {
    onAfterLeave() {
      this.$emit("hidden");
    },
  },
};
</script>

<style scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px); /* Blur effect */
  background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure the overlay is below the notification */
}

.notification {
  padding: 60px;
  background-color: #ffffff;
  color: #000000;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  z-index: 1000; /* Ensure the notification is on top */
  animation: bounceIn 0.6s;
}

@keyframes bounceIn {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  60% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}

@keyframes bounceOut {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.1);
    opacity: 0;
  }
}

.bounce-enter-active {
  animation: bounceIn 0.6s;
}

.bounce-leave-active {
  animation: bounceOut 0.5s;
}

.text-gold {
  color: #c39935;
}
</style>
