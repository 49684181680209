import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";  // Import your Vuex store

import Home from "../views/Home.vue";
//import UserRegister from "../views/Register.vue";

//import SignupBasic from "../views/auth/signup/Basic.vue";
//import SignupCover from "../views/auth/signup/Cover.vue";
//import SignupIllustration from "../views/auth/signup/Illustration.vue";

import Error404 from "@/views/auth/error/Error404.vue";
import Error500 from "@/views/auth/error/Error500.vue";

/* Layouts */
import AdminLayout from "@/views/admin/AdminLayout.vue";
import AuctionLayout from "@/views/auction/AuctionLayout.vue";

/* Auction Router */
import EditUserDetails from "@/views/auction/EditUserDetails.vue";
import AuctionDashboard from "@/views/auction/AuctionDashboard.vue";
import MyBids from "@/views/auction/MyBids.vue";

/* Admin Router */
import AuctionItemList from "../views/admin/AuctionItemList.vue";
import AuctionCategoriesList from "../views/admin/AuctionCategoryList.vue";
import AuctionItemNew from "../views/admin/AuctionItemEdit.vue";
import AuctionItemEdit from "../views/admin/AuctionItemEdit.vue";
import AuctionCategoryNew from "../views/admin/AuctionCategoryNew.vue";
import AuctionUserListing from "../views/admin/users/UserListing.vue";
import AuctionUserCreate from "../views/admin/users/UserCreate.vue";
import TableManagement from "../views/admin/AdminTableManagement.vue";
import ManualBid from "../views/admin/AuctionManualBid.vue";
import sponsors from "../views/admin/sponsors.vue";
import listSponsors from "../views/admin/SponsorsListing.vue";
import listSoldItems from "../views/admin/SoldItemsList.vue";
import viewSoldItem from "../views/admin/soldItemView.vue";
import liveAuction from "../views/admin/LiveAuction.vue";
import importUsers from "../views/admin/AuctionImportUsers.vue";
import exportAuctionItems from "../views/admin/AuctionExportItems.vue";

import DisplayLatestBids from "../views/display/latestBids.vue";
import DisplayAuctionTotal from "../views/display/AuctionTotalView.vue";
import DisplayAuctionItemCarousel from "../views/display/AuctionItemCarousel.vue";
import DisplaySponsors from "../views/display/AuctionSponsors.vue";
import StartSequence from "../views/display/components/NavigationSequence.vue";
import DisplaySummary from "../views/display/AuctionSummary.vue";

const routes = [
  {
    path: "/",
    name: "/",
    component: Home
  },
  {
    path: "/",
    name: "Default",
    component: Home
  },
  /*{
    path: "/register",
    name: "Register",
    component: UserRegister
  },*/
 /*{
    path: "/authentication/signup/basic",
    name: "Signup Basic",
    component: SignupBasic
  },
  {
    path: "/authentication/signup/cover",
    name: "Signup Cover",
    component: SignupCover
  },
  {
    path: "/authentication/signup/illustration",
    name: "Signup Illustration",
    component: SignupIllustration
  },*/
  {
    path: "/authentication/error/error404",
    name: "Error Error404",
    component: Error404
  },
  {
    path: "/authentication/error/error500",
    name: "Error Error500",
    component: Error500
  },
  {
    path: "/admin",
    component: AdminLayout,
    name: "Admin Dashboard",
    children: [
      {
        path: "/admin/auctionitems",
        name: "Auction Items List",
        component: AuctionItemList,
        meta: {
          requiresAuth: true, requiresAdmin: true
        }
      },
      {
        path: "/admin/auctionitems/edit/:id",
        name: "Edit Auction Item",
        component: AuctionItemEdit,
        meta: {
          requiresAuth: true, requiresAdmin: true
        }
      },
      {
        path: "/admin/auctionitems/create",
        name: "Create New Auction Item",
        component: AuctionItemNew,
        meta: {
          requiresAuth: true, requiresAdmin: true
        }
      },
      {
        path: "/admin/category",
        name: "Auction Category List",
        component: AuctionCategoriesList,
        meta: {
          requiresAuth: true, requiresAdmin: true
        }
      },
      {
        path: "/admin/category/create",
        name: "Create New Category",
        component: AuctionCategoryNew,
        meta: {
          requiresAuth: true, requiresAdmin: true
        }
      },
      {
        path: "/admin/category/edit/:id",
        name: "Edit Category",
        component: () => import('../views/admin/AuctionCategoryEdit.vue'),
        meta: {
          requiresAuth: true, requiresAdmin: true
        }
      },
      {
        name: 'Users',
        path: "/admin/users",
        children: [
          { path: 'list', name: "User Listing", component: AuctionUserListing },
        ],
        component: AuctionUserListing,
        meta: {
          requiresAuth: true, requiresAdmin: true
        }
      },
      {
        path: "/admin/users/edit/:userId?",
        name: "Edit User",
        component: AuctionUserCreate,
        props: true, // This will pass the userId as a prop to the UserCreate component
        meta: {
          requiresAuth: true, requiresAdmin: true
        }
      },
      {
        path: "/admin/users/create/",
        name: "Create New User",
        component: AuctionUserCreate,
        meta: {
          requiresAuth: true, requiresAdmin: true
        }
      },
      {
        path: "/admin/tables/",
        name: "Table Management",
        component: TableManagement,
        meta: {
          requiresAuth: true, requiresAdmin: true
        }
      },{
        path: "/admin/manualbid/",
        name: "Add Bid",
        component: ManualBid,
        meta: {
          requiresAuth: true, requiresAdmin: true
        }
      },{
        path: "/admin/sponsors/",
        name: "List Sponsors",
        component: listSponsors,
        meta: {
          requiresAuth: true, requiresAdmin: true
        }
      },{
        path: "/admin/new-sponsor/",
        name: "Add New Sponsor",
        component: sponsors,
        meta: {
          requiresAuth: true, requiresAdmin: true
        }
      },
      {
        path: '/sponsors/edit/:id',
        name: 'Edit Sponsor',
        component: sponsors,
        props: true, // Pass route params as props to the component
        meta: {
          requiresAuth: true, requiresAdmin: true
        }
      },{
        path: "/admin/solditems/view/:id",
        name: "View Sold Item",
        component: viewSoldItem,
        meta: {
          requiresAuth: true, requiresAdmin: true
        }
      },{
        path: "/admin/solditems/",
        name: "List Sold Items",
        component: listSoldItems,
        meta: {
          requiresAuth: true, requiresAdmin: true
        }
      },{
        path: "/admin/liveauction/",
        name: "Live Auction",
        component: liveAuction,
        meta: {
          requiresAuth: true, requiresAdmin: true
        }
       },{
        path: "/admin/import/",
        name: "Import Users",
        component: importUsers,
        meta: {
          requiresAuth: true, requiresAdmin: true
        }
      },{
        path: "/admin/exportitems/",
        name: "Export Auction Items",
        component: exportAuctionItems,
        meta: {
          requiresAuth: true, requiresAdmin: true
        }
      },
    ],
  },
  {
    path: "/auction",
    component: AuctionLayout,
    children: [
      {
        path: "/auction/item/:id",
        name: "View Auction Item",
        component: () => import('../views/auction/ViewAuctionItem.vue'),
        meta: {
          requiresAuth: true
        }
      },{
        path: "/auction/donationitem/:id",
        name: "View Donation Item",
        component: () => import('../views/auction/ViewDonationItem.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/auction/dashboard",
        name: "Auction Dashboard",
        component: AuctionDashboard,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/auction/category/",
        name: "View Category List",
        component: () => import('../views/auction/ViewCategoryList.vue'),
        meta: {
          requiresAuth: true
        },
      },
      {
        path: '/auction/category/:categoryName/:category',
        name: 'CategoryView',
        component: () => import('../views/auction/ViewCategoryList.vue'),
        meta: {
          requiresAuth: true
        },
      },{
        path: '/auction/category/all',
        name: 'CategoryViewAll',
        component: () => import('../views/auction/ViewCategoryListAll.vue'),
        meta: {
          requiresAuth: true
        },
      },
      {
        path: '/auction/category/price-range/:range',
        name: 'PriceRangeView',
        component: () => import('../views/auction/ViewCategoryList.vue'),
        meta: {
          requiresAuth: true
        },
      },
      {
        path: "/auction/edituserinfo",
        name: "Edit User Info",
        component: EditUserDetails,
        meta: {
          requiresAuth: true
        },
      },
      {
        path: "/auction/mybids/",
        name: "My Bids",
        component: MyBids,
        meta: {
          requiresAuth: true
        },
      },
    ],
  },{
    path: "/display",
    //component: DisplayLatestBids,
    children: [
      {
        path: "/display/latestbids",
        name: "Display Latest Bids",
        component: DisplayLatestBids,
        meta: {
          requiresAuth: false
        }
      },{
        path: '/display/auctiontotals',
        name: 'Display Auction Totals',
        component: DisplayAuctionTotal,
        meta: {
          requiresAuth: false
        }
      },{
        path: '/display/auctionitems',
        name: 'Display All Auction Items',
        component: DisplayAuctionItemCarousel,
        meta: {
          requiresAuth: false
        }
      },{
        path: '/display/sponsors',
        name: 'Display All Sponsors',
        component: DisplaySponsors,
        meta: {
          requiresAuth: false
        }
      },{
        path: '/display/summary',
        name: 'Display Summary',
        component: DisplaySummary,
        meta: {
          requiresAuth: false
        }
      },{
        path: '/display/autoplay',
        name: 'Start TV Display',
        component: StartSequence,
        meta: {
          requiresAuth: false
        }
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes, // your routes array
  linkActiveClass: "active"
});

router.beforeEach(async (to, from, next) => {
  // Ensure user state is fetched before checking auth state
  await store.dispatch('fetchUser');
  const isAuthenticated = store.getters.isAuthenticated;
  const isAdmin = store.getters.userIsAdmin;

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      next({ path: "/", query: { redirect: to.fullPath } }); // Redirect to the login/register page
    } else if (to.matched.some((record) => record.meta.requiresAdmin)) {
      if (isAdmin) {
        next();
      } else {
        next({ path: "/", query: { redirect: to.fullPath } }); // Redirect to home if not admin
      }
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
