<script setup>
import { ref, computed } from "vue";

import Card from "primevue/card";
import Divider from "primevue/divider";
import Badge from "primevue/badge";
import PriceDisplay from "@/views/auction/components/DisplayPrice.vue";
const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  getCategoryName: {
    type: Function,
    required: true,
  },
});

const imageLoaded = ref(false); // Track image loading state
const onImageLoad = () => {
  imageLoaded.value = true; // Set imageLoaded to true once the image is fully loaded
};

// Computed property to determine the displayed bid amount
const displayedBid = computed(() => {
  return props.item.isDonationItem
    ? props.item.startbid
    : props.item.latestBid || props.item.startbid;
});

// Computed property to determine the route dynamically
const itemRoute = computed(() => {
  if (props.item.isDonationItem) {
    return {
      name: "View Donation Item",
      params: { id: props.item.id },
    };
  } else {
    return {
      name: "View Auction Item",
      params: { id: props.item.id },
    };
  }
});
</script>

<template>
  <Card style="overflow: hidden; margin: 5px">
    <template #header>
      <div
        class="itemImage"
        style="position: relative; max-height: 250px; overflow: hidden"
      >
        <router-link :to="itemRoute">
          <!-- SVG Spinner while loading -->
          <div v-if="!imageLoaded" class="spinner">
            <img :src="require('@/assets/img/spinner.gif')" alt="Loading..." />
          </div>
          <img
            v-if="item.imageUrl"
            :alt="item.name"
            :src="item.resizedImages[0].url ? item.resizedImages[0].url : ''"
            loading="lazy"
            style="width: 100%; display: block"
            @load="onImageLoad"
          />
          <img
            v-else
            src="@/assets/img/tpwf-placeholder-noimg.jpg"
            alt="No Image"
          />
        </router-link>
      </div>
    </template>

    <template #footer>
      <div class="grid">
        <div class="col-2">
          <div v-if="item.itemNumber" class="itemnumber">
            <router-link :to="itemRoute">{{ item.itemNumber }}</router-link>
          </div>
        </div>
        <div class="col-10">
          <div class="title text-left" v-if="item.creator">
            <router-link :to="itemRoute">{{ item.creator }}</router-link>
          </div>
          <div class="artist text-left">
            <router-link :to="itemRoute">{{ item.name }}</router-link>
          </div>
        </div>
      </div>

      <Divider />
      <div class="flex pt-1">
        <div
          class="flex-1 align-items-center justify-content-center text-xs"
          style="line-height: 1.5rem"
        >
          <PriceDisplay :price="displayedBid" :currency="item.currency" />
        </div>
        <div
          class="flex-1 hidden align-items-center justify-content-center text-xs"
        >
          <i class="pi pi-tag" style="font-size: 0.7rem"></i>
          {{ getCategoryName(item.category) }}
        </div>
        <div class="flex-1 align-items-center justify-content-center text-xs">
          <router-link :to="itemRoute">
            <span style="font-size: 0.75rem" class="mr-1">{{
              item.isDonationItem ? "Donations" : "Bids"
            }}</span>
            <Badge
              :value="item.bidCount ? item.bidCount : '0'"
              severity="info"
              style="font-size: 0.7rem"
            ></Badge>
          </router-link>
        </div>
      </div>
    </template>
  </Card>
</template>

<style scoped>
.item-btn {
  font-size: 0.8rem;
  border-radius: 6px;
}

.scalein {
  animation: scalein 1s forwards;
}

.animation-duration-1000 {
  animation-duration: 1s;
}

@keyframes scalein {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.title,
.artist {
  font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-size: 1rem;
  font-style: normal;
}
.artist {
  font-size: 0.8rem;
}

.p-divider.p-divider-horizontal {
  margin: 0.2rem;
  font-weight: 400;
}

.itemnumber {
  font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-size: 25px;
}
</style>
