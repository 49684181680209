<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-6">
        <h4 class="text-white">{{ productData.name }}</h4>
      </div>
      <div
        class="text-right col-lg-6 d-flex flex-column justify-content-center"
      >
        <button
          type="button"
          class="mt-2 mb-0 btn btn-outline-black ms-lg-auto me-lg-0 me-auto mt-lg-0"
          @click="updateItem"
        >
          Save
        </button>
      </div>
    </div>
    <div class="mt-4 row">
      <div class="col-lg-4">
        <div class="card h-100">
          <div class="card-body">
            <h5 class="font-weight-bolder">Product Image</h5>
            <div class="row">
              <div class="col-12">
                <img
                  class="mt-3 shadow-lg w-100 border-radius-lg"
                  :src="productData.imageUrl"
                  alt="product_image"
                  v-if="productData.imageUrl"
                />
              </div>
              <div class="mt-5 col-12">
                <div
                  class="image-forms row"
                  v-if="
                    !productData.imageUrl ||
                    productData.imageUrl === placeholderImageUrl
                  "
                >
                  <div class="col">
                    <file-pond
                      ref="pond"
                      name="filepond"
                      label-idle="Drag & Drop your image or <span class='filepond--label-action'>Browse</span>"
                      :allow-multiple="false"
                      :server="pondOptions"
                    ></file-pond>
                  </div>
                  <div class="col"></div>
                </div>

                <button
                  class="mb-0 btn btn-outline-dark btn-sm"
                  type="button"
                  @click="deleteImage"
                  v-if="
                    productData.imageUrl &&
                    productData.imageUrl !== placeholderImageUrl
                  "
                >
                  Remove
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4 col-lg-8 mt-lg-0">
        <div class="card">
          <div class="card-body">
            <h5 class="font-weight-bolder">Auction Item Information</h5>
            <div class="row">
              <div class="col-12 col-sm-6">
                <label>Name</label>
                <input
                  class="form-control"
                  type="text"
                  v-model="productData.name"
                />
              </div>
              <div class="mt-3 col-12 col-sm-6 mt-sm-0">
                <label>Artist</label>
                <input
                  class="form-control"
                  type="text"
                  v-model="productData.creator"
                />
              </div>
            </div>
            <div class="row">
              <div class="mt-3 col-12 col-sm-12 mt-sm-0">
                <label class="mt-4">Description</label>
                <div class="col-12">
                  <QuillEditor
                    v-model:content="productData.description"
                    contentType="html"
                    theme="snow"
                  />
                </div>
              </div>
              <div class="mt-3 col-12 col-sm-6 mt-sm-0">
                <label class="mt-4">Category</label>
                <Dropdown
                  v-model="selectedCategory"
                  :options="categories"
                  optionLabel="name"
                  placeholder="Select a Category"
                  class="col-12"
                />
              </div>
              <div class="mt-3 col-12 col-sm-6 mt-sm-0">
                <label class="mt-4">Item Number #</label>
                <input
                  class="form-control"
                  id="itemNumber"
                  type="number"
                  v-model="productData.itemNumber"
                />
              </div>
              <div class="mt-3 col-12 col-sm-6 mt-sm-0">
                <label class="mt-4">Estimated Item Value</label>
                <input
                  class="form-control"
                  type="number"
                  id="estimatedValue"
                  v-model="productData.estimatedValue"
                />
              </div>
              <div class="mt-3 col-12 col-sm-6 mt-sm-0">
                <label class="mt-4">Size</label>
                <input
                  class="form-control"
                  type="text"
                  id="size"
                  v-model="productData.size"
                />
              </div>
              <div class="mt-3 col-12 col-sm-6 mt-sm-0">
                <label class="mt-4">Other</label>
                <input
                  class="form-control"
                  type="text"
                  id="other"
                  v-model="productData.other"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4 row">
      <div class="mt-4 col-sm-12 mt-sm-0">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <h5 class="font-weight-bolder">Pricing & Date</h5>
              <div class="col-3">
                <label>Starting Bid</label>
                <input
                  class="form-control"
                  type="text"
                  v-model="productData.startbid"
                />
              </div>
              <div class="col-4">
                <label>Currency</label>
                <select
                  id="choices-currency-edit"
                  class="form-control"
                  name="choices-sizes"
                  v-model="productData.currency"
                >
                  <option value="SEK" selected>SEK</option>
                  <option value="EUR">EUR</option>
                  <option value="USD">USD</option>
                </select>
              </div>
              <div class="col-3">
                <label>End Date</label>
                {{ formatDate(productData.enddate) }}
                <VueDatePicker
                  v-model="productData.newEndDate"
                  time-picker-inline
                  dateFormat="yy-mm-dd"
                />
              </div>
            </div>
            <div class="row">
              <hr class="horizontal dark mt-3" />
              <div class="d-flex">
                <div class="s-auto me-3 my-auto pt-1">
                  <InputSwitch v-model="productData.isBuyNowEnabled" />
                </div>
                <div class="my-auto">
                  <div class="h-100">
                    <h5 class="mb-0">Buy Now</h5>
                  </div>
                </div>

                <p class="text-sm text-secondary ms-auto me-3 my-auto"></p>
                <div class="s-auto me-3 my-auto">
                  <argon-badge
                    size="md"
                    :color="productData.isBuyNowEnabled ? 'info' : 'primary'"
                    variant="gradient"
                    >{{
                      productData.isBuyNowEnabled ? "Enabled" : "Disabled"
                    }}</argon-badge
                  >
                </div>
              </div>
              <div class="row mt-3 buynow" v-if="productData.isBuyNowEnabled">
                <div class="col-6 col-sm-6">
                  <p class="float-left pt-1">
                    Enter the fixed amount that you want to sell the product
                    for:
                  </p>
                </div>
                <div class="col-3 col-sm-3">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Buy now price"
                    v-model="productData.buyNowPrice"
                    :disabled="!productData.isBuyNowEnabled"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <hr class="horizontal dark mt-3" />
              <div class="d-flex">
                <div class="s-auto me-3 my-auto pt-1">
                  <InputSwitch v-model="productData.isDonationItem" />
                </div>
                <div class="my-auto">
                  <div class="h-100">
                    <h5 class="mb-0">Donation Item</h5>
                  </div>
                </div>

                <p class="text-sm text-secondary ms-auto me-3 my-auto"></p>
                <div class="s-auto me-3 my-auto">
                  <argon-badge
                    size="md"
                    :color="productData.isDonationItem ? 'info' : 'primary'"
                    variant="gradient"
                    >{{
                      productData.isDonationItem ? "Enabled" : "Disabled"
                    }}</argon-badge
                  >
                </div>
              </div>
              <div
                class="row mt-3 donationitem"
                v-if="productData.isDonationItem"
              >
                <div class="col-6 col-sm-6">
                  <p class="float-left pt-1">
                    Donation items are products that can be purchased multiple
                    times.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-4 col-sm-12">
        <div class="card">
          <div class="card-body">
            <h5 class="font-weight-bolder">
              {{ productData.isDonationItem ? "Purchases" : "Bids" }}
            </h5>
            <ul class="list-group">
              <li
                class="list-group-item d-flex justify-content-between align-items-center"
                v-for="(bid, index) in bids"
                :key="index"
              >
                <div class="d-flex flex-grow-1 justify-content-between w-100">
                  <div class="p-2">
                    <p class="mb-0">
                      <strong>Amount:</strong> {{ bid.userBid }}
                      {{ productData.currency }}
                    </p>
                  </div>
                  <div class="p-2">
                    <p class="mb-0">
                      <strong>Max Bid:</strong> {{ bid.maxBid }}
                      {{ productData.currency }}
                    </p>
                  </div>
                  <div class="p-2">
                    <p class="mb-0">
                      <strong>User:</strong> {{ bid.user.name }}
                    </p>
                  </div>
                  <div class="p-2 d-none d-md-block">
                    <p class="mb-0">
                      <strong>Mobile:</strong> {{ bid.user.mobile }}
                    </p>
                  </div>

                  <div class="p-2 d-none d-md-block">
                    <p class="mb-0">
                      <strong>Table:</strong> {{ bid.user.table }}
                    </p>
                  </div>
                  <div class="p-2 d-none d-md-block">
                    <p class="mb-0">
                      <strong>Time:</strong> {{ formatBidTime(bid.timestamp) }}
                    </p>
                  </div>
                </div>
                <button
                  class="btn btn-outline-danger btn-sm ms-2"
                  @click="deleteBid(bid.id)"
                >
                  Delete
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="mt-4 col-sm-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <h5 class="font-weight-bolder">Image Gallery</h5>
              <file-pond
                ref="galleryPond"
                name="gallery"
                label-idle="Drag & Drop your images or <span class='filepond--label-action'>Browse</span>"
                :allow-multiple="true"
                :server="galleryPondOptions"
              ></file-pond>
              <div class="col-12 mt-4">
                <div v-if="Array.isArray(productData.imageGallery)">
                  <div class="row">
                    <div
                      v-for="(image, index) in productData.imageGallery"
                      :key="index"
                      class="col-4"
                    >
                      <img
                        :src="image.url"
                        class="w-100 shadow-lg border-radius-lg mb-3"
                      />
                      <button
                        class="btn btn-outline-danger btn-sm"
                        @click="removeImageFromGallery(index)"
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        type="button"
        class="mt-2 mb-0 btn btn-outline-danger ms-lg-auto me-lg-0 me-auto mt-lg-0"
        @click="resetItem"
      >
        Reset item (Remove all bids)
      </button>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { useRoute } from "vue-router";
import Dropdown from "primevue/dropdown";

import ArgonBadge from "@/components/ArgonBadge.vue";
import InputSwitch from "primevue/inputswitch";
import { useRouter } from "vue-router";
import {
  ref as storageRef,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { storage } from "../../firebase";
import { db } from "../../firebase";
import {
  doc,
  getDocs,
  getDoc,
  collection,
  addDoc,
  updateDoc,
  deleteDoc,
  deleteField,
  writeBatch,
  query,
  orderBy,
} from "firebase/firestore";
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

import FilePondPluginImagePreview from "filepond-plugin-image-preview";

import pica from "pica";

const FilePond = vueFilePond(FilePondPluginImagePreview);

const bids = ref([]);
const route = useRoute();
const router = useRouter();

const productData = ref({
  name: "",
  price: "",
  description: "",
  enddate: "",
  category: "",
  creator: "",
  startbid: "",
  homepage: "",
  instagram: "",
  currency: "",
  newEndDate: "",
  imageUrl: "",
  resizedImages: [], // This will hold URLs of resized images
  buyNowPrice: "0",
  isBuyNowEnabled: false,
  isDonationItem: false,
  imageGallery: [], // Add image gallery
  winnerSMSSent: false,
  itemNumber: "",
  estimatedValue: "",
});

const categories = ref([]);
const selectedCategory = ref(null);
const placeholderImageUrl = "";

async function initializeProductData() {
  if (route.params.id) {
    await fetchAuctionItem(route.params.id);
  } else {
    await createNewAuctionItem();
  }
}

async function fetchAuctionItem(id) {
  const docRef = doc(db, "auctionItems", id);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    const data = docSnap.data();
    productData.value = {
      id: docSnap.id,
      ...data,
      imageGallery: Array.isArray(data.imageGallery) ? data.imageGallery : [],
    };
    await fetchCategories();
    productData.value.newEndDate = new Date(data.enddate.seconds * 1000);
    setSelectedCategory();
  } else {
    console.log("No such document!");
  }
}

async function createNewAuctionItem() {
  const newAuctionItem = {
    name: "",
    price: 0,
    description: "",
    enddate: new Date(),
    category: "",
    creator: "",
    size: "",
    other: "",
    startbid: "",
    homepage: "",
    instagram: "",
    currency: "SEK",
    imageUrl: placeholderImageUrl,
    resizedImages: [],
    buyNowPrice: "0",
    isBuyNowEnabled: false,
    isDonationItem: false,
    imageGallery: [],
    winnerSMSSent: false,
    itemNumber: "",
    estimatedValue: "",
  };

  const docRef = await addDoc(collection(db, "auctionItems"), newAuctionItem);
  productData.value = {
    id: docRef.id,
    ...newAuctionItem,
  };
  await fetchCategories();
  productData.value.newEndDate = new Date();
}

const setSelectedCategory = () => {
  const category = categories.value.find(
    (cat) => cat.id === productData.value.category
  );
  if (category) {
    selectedCategory.value = category;
  }
};

const fetchCategories = async () => {
  const querySnapshot = await getDocs(collection(db, "category"));
  categories.value = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
};

async function fetchBidsOrPurchases() {
  let subCollectionName = "bids";

  if (productData.value.isDonationItem) {
    subCollectionName = "purchase"; // Ensure the correct sub-collection name
  }

  const auctionItemId = route.params.id;
  if (!auctionItemId) {
    console.error("Auction item ID is missing.");
    return;
  }

  try {
    const subCollectionRef = collection(
      db,
      "auctionItems",
      auctionItemId,
      subCollectionName
    );

    // Query the collection and order by timestamp descending
    const subCollectionQuery = query(
      subCollectionRef,
      orderBy("timestamp", "desc")
    );

    const querySnapshot = await getDocs(subCollectionQuery);

    bids.value = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  } catch (error) {
    console.error("Error fetching bids or purchases:", error);
  }
}

function formatBidTime(timestamp) {
  const date = new Date(timestamp.seconds * 1000);
  return date.toLocaleString();
}

async function deleteBid(bidId) {
  const subCollectionName = productData.value.isDonationItem
    ? "purchase"
    : "bids";

  if (confirm("Are you sure you want to delete this bid?")) {
    try {
      const auctionItemId = route.params.id;

      // Delete the bid from Firestore
      await deleteDoc(
        doc(db, "auctionItems", auctionItemId, subCollectionName, bidId)
      );

      // Remove the bid from the local array
      bids.value = bids.value.filter((bid) => bid.id !== bidId);

      // Refetch the bids to update the UI
      await fetchBidsOrPurchases();

      let newBidCount = bids.value.length;

      // Check if there are remaining bids or purchases
      if (newBidCount > 0) {
        const latestBid = bids.value[0]; // The latest bid after deletion

        // Update the auction item with the latest bid details and updated bidCount
        const auctionItemRef = doc(db, "auctionItems", auctionItemId);
        await updateDoc(auctionItemRef, {
          latestBid: latestBid.userBid,
          latestBidder: latestBid.user.name,
          latestBidderID: latestBid.user.uid,
          bidCount: newBidCount,
        });
      } else {
        // If no bids are left, clear the fields and set bidCount to 0
        const auctionItemRef = doc(db, "auctionItems", auctionItemId);
        await updateDoc(auctionItemRef, {
          latestBid: deleteField(),
          latestBidder: deleteField(),
          latestBidderID: deleteField(),
          bidCount: 0,
        });
      }

      console.log(`Deleted ${subCollectionName} with ID ${bidId}`);
    } catch (error) {
      console.error(`Error deleting ${subCollectionName}:`, error);
    }
  }
}

const resetItem = async () => {
  if (confirm("Are you sure you want to reset this auction item?")) {
    try {
      const auctionItemId = route.params.id;

      if (!auctionItemId) {
        console.error("Auction item ID is missing.");
        return;
      }

      // Determine sub-collection name
      const subCollectionName = productData.value.isDonationItem
        ? "purchase"
        : "bids";

      // Fetch documents in the bids or purchases sub-collection
      const subCollectionRef = collection(
        db,
        "auctionItems",
        auctionItemId,
        subCollectionName
      );
      const querySnapshot = await getDocs(subCollectionRef);

      // Initialize batch
      const batch = writeBatch(db);

      // Add each delete operation to the batch
      querySnapshot.forEach((doc) => {
        batch.delete(doc.ref);
      });

      // Commit the batch
      await batch.commit();

      // Delete the specific fields from the auction item
      const docRef = doc(db, "auctionItems", auctionItemId);
      await updateDoc(docRef, {
        latestBid: deleteField(),
        latestBidder: deleteField(),
        latestBidderID: deleteField(),
        bidCount: 0, // Set bidCount to 0
        winnerSMSSent: false, // Reset winnerSMSSent flag
      });

      // Clear bids from the UI
      bids.value = [];

      alert("Auction item has been reset successfully.");
    } catch (error) {
      console.error("Error resetting the auction item:", error);
      alert("Failed to reset the auction item.");
    }
  }
};

const formatDate = (timestamp) => {
  const date = new Date(timestamp.seconds * 1000);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");

  return `${year}-${month}-${day}`;
};

const pondOptions = {
  process: (fieldName, file, metadata, load, error, abort) => {
    const originalFile = file;
    Promise.all([
      resizeImage(originalFile, 300),
      resizeImage(originalFile, 600),
      resizeImage(originalFile, 1200),
    ])
      .then(async (resizedImages) => {
        const uploadPromises = resizedImages.map((resizedImage) =>
          uploadToFirebase(resizedImage)
        );

        const urls = await Promise.all(uploadPromises);
        const itemRef = doc(db, "auctionItems", productData.value.id);
        await updateDoc(itemRef, {
          imageUrl: urls[0],
          resizedImages: urls.map((url, index) => ({
            size: [300, 600, 1200][index],
            url,
          })),
        });

        productData.value.imageUrl = urls[0];
        productData.value.resizedImages = urls.map((url, index) => ({
          size: [300, 600, 1200][index],
          url,
        }));

        load(urls[0]);
      })
      .catch((err) => {
        error(err.message);
      });

    return {
      abort: () => {
        abort();
      },
    };
  },
};

const galleryPondOptions = {
  process: (fieldName, file, metadata, load, error, abort) => {
    const uploadPromise = uploadToFirebase(file);

    uploadPromise
      .then(async (downloadURL) => {
        const itemRef = doc(db, "auctionItems", productData.value.id);
        await updateDoc(itemRef, {
          imageGallery: [
            ...productData.value.imageGallery,
            { url: downloadURL },
          ],
        });

        productData.value.imageGallery.push({ url: downloadURL });

        load(downloadURL);
      })
      .catch((err) => {
        error(err.message);
      });

    return {
      abort: () => {
        abort();
      },
    };
  },
};

async function resizeImage(file, size) {
  const img = new Image();
  const picaInstance = pica();

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      img.src = event.target.result;
      img.onload = async () => {
        try {
          const canvas = document.createElement("canvas");
          const scaleFactor = size / img.width;
          canvas.width = size;
          canvas.height = img.height * scaleFactor;

          await picaInstance.resize(img, canvas);
          const blob = await picaInstance.toBlob(canvas, "image/jpeg", 0.9);
          const resizedImage = new File([blob], `${file.name}-${size}.jpg`, {
            type: "image/jpeg",
          });

          resolve(resizedImage);
        } catch (err) {
          reject(err);
        }
      };
      img.onerror = (err) => {
        reject(err);
      };
    };
    reader.onerror = (err) => {
      reject(err);
    };

    reader.readAsDataURL(file);
  });
}

async function uploadToFirebase(file) {
  const storageReference = storageRef(storage, `images/${file.name}`);
  // Define the metadata with cache control headers
  const metadata = {
    contentType: file.type,
    cacheControl: "public, max-age=3600", // Cache for 1 hour
  };
  const uploadTask = uploadBytesResumable(storageReference, file, metadata);

  return new Promise((resolve, reject) => {
    uploadTask.on(
      "state_changed",
      () => {
        // Removed snapshot parameter and progress calculation
      },
      (err) => {
        reject(err);
      },
      async () => {
        try {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(downloadURL);
        } catch (err) {
          reject(err);
        }
      }
    );
  });
}

async function removeImageFromGallery(index) {
  try {
    const imageToRemove = productData.value.imageGallery[index];

    if (imageToRemove && imageToRemove.url) {
      const imageRef = storageRef(
        storage,
        imageToRemove.url.replace(storage.bucket, "")
      );

      try {
        await deleteObject(imageRef);
      } catch (error) {
        if (error.code !== "storage/object-not-found") {
          console.error("Error removing gallery image:", error);
          return;
        }
      }

      const updatedGallery = [...productData.value.imageGallery];
      updatedGallery.splice(index, 1);

      const itemRef = doc(db, "auctionItems", productData.value.id);
      await updateDoc(itemRef, {
        imageGallery: updatedGallery,
      });

      productData.value.imageGallery = updatedGallery;
    }
  } catch (error) {
    console.error("Error removing image from gallery:", error);
  }
}

const deleteImage = async () => {
  if (
    !productData.value.imageUrl ||
    productData.value.imageUrl === placeholderImageUrl
  ) {
    alert("No image to delete");
    return;
  }

  const imageRef = storageRef(
    storage,
    productData.value.imageUrl.replace(storage.bucket, "")
  );

  try {
    await deleteObject(imageRef);
  } catch (error) {
    if (error.code !== "storage/object-not-found") {
      console.error("Error removing image:", error);
      return;
    }
  }

  for (const { size, url } of productData.value.resizedImages) {
    const resizedImageRef = storageRef(
      storage,
      url.replace(storage.bucket, "")
    );

    try {
      await deleteObject(resizedImageRef);
    } catch (error) {
      if (error.code !== "storage/object-not-found") {
        console.error(`Error removing resized image ${size}:`, error);
        return;
      }
    }
  }

  try {
    const itemRef = doc(db, "auctionItems", productData.value.id);
    await updateDoc(itemRef, {
      imageUrl: placeholderImageUrl,
      resizedImages: [],
    });

    productData.value.imageUrl = placeholderImageUrl;
    productData.value.resizedImages = [];
  } catch (error) {
    console.error("Error updating Firestore document:", error);
  }
};

const updateItem = async () => {
  try {
    if (!productData.value.name.trim()) {
      alert("Please enter the name of the item.");
      return;
    }

    if (!productData.value.creator.trim()) {
      alert("Please enter the artist name.");
      return;
    }

    if (!selectedCategory.value) {
      alert("Please select a category.");
      return;
    }

    if (!productData.value.itemNumber) {
      alert("Please enter the item number.");
      return;
    }

    const docRef = doc(db, "auctionItems", productData.value.id);

    const startbidNumber = Number(productData.value.startbid) || 0;
    const buyNowPriceNumber = Number(productData.value.buyNowPrice) || 0;

    const updateData = {
      name: productData.value.name || "",
      category: selectedCategory.value.id || "",
      price: productData.value.price || 0,
      description: productData.value.description || "",
      enddate: productData.value.newEndDate || new Date(),
      creator: productData.value.creator || "",
      size: productData.value.size || "",
      other: productData.value.other || "",
      startbid: startbidNumber,
      homepage: productData.value.homepage || "",
      instagram: productData.value.instagram || "",
      currency: productData.value.currency || "SEK",
      imageUrl: productData.value.imageUrl || placeholderImageUrl,
      resizedImages: productData.value.resizedImages || [],
      imageGallery: productData.value.imageGallery || [],
      buyNowPrice: buyNowPriceNumber,
      isBuyNowEnabled: productData.value.isBuyNowEnabled || false,
      isDonationItem: productData.value.isDonationItem || false,
      itemNumber: productData.value.itemNumber || "",
      estimatedValue: productData.value.estimatedValue || 0,
    };

    console.log("Data to be sent to Firestore:", updateData);

    await updateDoc(docRef, updateData);

    localStorage.setItem("auctionMessage", "Auction item updated");
    router.push({ name: "Auction Items List" });
  } catch (error) {
    console.error("Error updating document:", error);
  }
};

onMounted(async () => {
  await initializeProductData();
  await fetchBidsOrPurchases();
});
</script>

<style>
@import "filepond/dist/filepond.min.css";
@import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
</style>
