/* eslint-disable */

// initialization of Tooltips that hide on click
export default function setTooltip(bootstrap) {
  // Dispose of any existing tooltips to avoid duplicates
  var existingTooltips = document.querySelectorAll('[data-bs-toggle="tooltip"]');
  existingTooltips.forEach(el => {
    var tooltipInstance = bootstrap.Tooltip.getInstance(el);
    if (tooltipInstance) {
      tooltipInstance.dispose();
    }
  });

  // Initialize new tooltips without a timer and hide on click
  var tooltipTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="tooltip"]')
  );
  tooltipTriggerList.forEach(function (tooltipTriggerEl) {
    const tooltipInstance = new bootstrap.Tooltip(tooltipTriggerEl);

    // Add click event listener to hide the tooltip when clicked
    tooltipTriggerEl.addEventListener('click', () => {
      tooltipInstance.hide();
    });
  });
}
