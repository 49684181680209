<template>
  <div class="diaply-container">
    <h1>Total amount raised</h1>
    <div class="total-raised">
      <NumberAnimation
        :from="prevTotalAmount"
        :to="totalAmount"
        :duration="2"
        :format="formatCurrency"
        autoplay
      />
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { db } from "@/firebase";
import { collection, onSnapshot } from 'firebase/firestore';
import NumberAnimation from "vue-number-animation";

export default {
  name: 'AuctionTotalView',
  components: {
    NumberAnimation
  },
  setup() {
    const totalAmount = ref(0);
    const prevTotalAmount = ref(0);

    const fetchTotalAmount = () => {
      const auctionItemsRef = collection(db, 'auctionItems');
      onSnapshot(auctionItemsRef, async (querySnapshot) => {
        let total = 0;

        const auctionItems = querySnapshot.docs.map(doc => {
          const item = doc.data();
          const latestBid = parseFloat(item.latestBid) || 0;
          return { id: doc.id, latestBid, isDonationItem: item.isDonationItem };
        });

        // Add all latest bids to the total
        total += auctionItems.reduce((sum, item) => sum + item.latestBid, 0);

        // Fetch purchases from all donation items in real time
        const donationItems = auctionItems.filter(item => item.isDonationItem);
        const promises = donationItems.map(donationItem => {
          return new Promise((resolve) => {
            const purchaseCollectionRef = collection(db, `auctionItems/${donationItem.id}/purchase`);
            onSnapshot(purchaseCollectionRef, (purchaseSnapshot) => {
              const purchaseTotal = purchaseSnapshot.docs.reduce((sum, purchaseDoc) => {
                const purchase = purchaseDoc.data();
                return sum + (parseFloat(purchase.userBid) || 0);
              }, 0);
              resolve(purchaseTotal);
            });
          });
        });

        const purchaseTotals = await Promise.all(promises);
        total += purchaseTotals.reduce((sum, purchaseTotal) => sum + purchaseTotal, 0);

        prevTotalAmount.value = totalAmount.value;
        totalAmount.value = total;
      });
    };

    const formatCurrency = (value) => {
      return `${value.toLocaleString()} SEK`;
    };

    onMounted(fetchTotalAmount);

    return {
      totalAmount,
      prevTotalAmount,
      formatCurrency
    };
  },
};
</script>

<style scoped>
.diaply-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; 
  background: #1b2750;
  background-size: cover;
  color: #fff; /* Text color */
  position: relative;
}

h1 {
  font-size: 48px; /* Adjust font size as needed */
  margin-bottom: 20px;
  color: #ffffff !important;
  z-index: 5;
}

.total-raised {
  background: rgba(0, 0, 0, 0.5);
  padding: 20px 40px;
  border-radius: 10px;
  font-size: 7rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 80%;
  z-index: 5;
}
</style>
