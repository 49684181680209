<script setup>
import { ref, onMounted, computed } from "vue";
import { collection, getDocs, query, where, orderBy } from "firebase/firestore";
import { useStore } from "vuex";
import { db } from "@/firebase";
import Card from "./components/cards.vue";

// Firebase collections
const userBids = ref([]);
const store = useStore(); // Access Vuex store

// Computed property to get user ID
const userId = computed(() => {
  const firebaseUser = store.getters["user"].data;
  const customUser = store.getters["userDocId"];

  return firebaseUser?.uid || customUser;
});

// Fetch user bids from Firestore
const fetchUserBids = async () => {
  try {
    if (!userId.value) {
      throw new Error("No user is logged in");
    }
    //console.log(userId.value);

    // Query auctionItems collection with sorting by itemNumber
    const itemsQuery = query(
      collection(db, "auctionItems"),
      orderBy("itemNumber", "asc") // Sort by itemNumber in ascending order
    );
    const itemsSnapshot = await getDocs(itemsQuery);

    // Fetch bids and filter items where the user placed a bid
    const itemPromises = itemsSnapshot.docs.map(async (itemDoc) => {
      const bidsRef = collection(db, `auctionItems/${itemDoc.id}/bids`);
      const bidsQuery = query(bidsRef, where("user.uid", "==", userId.value));
      const bidsSnapshot = await getDocs(bidsQuery);

      if (!bidsSnapshot.empty) {
        return { id: itemDoc.id, ...itemDoc.data() };
      }
    });

    // Resolve all promises and filter out empty results
    const items = (await Promise.all(itemPromises)).filter((item) => item);

    // Update userBids with the sorted items
    userBids.value = items;
  } catch (error) {
    console.error("Error fetching user bids: ", error);
  }
};

// Lifecycle hooks
onMounted(async () => {
  await store.dispatch("fetchCategories"); // Ensure categories are fetched
  await fetchUserBids();
});
</script>

<template>
  <div class="layout-main show-all-items">
    <h1>Items you placed a bid on</h1>
    <!-- Items -->
    <div class="grid">
      <div
        v-for="item in userBids"
        :key="item.id"
        class="col-6 col-lg-3 col-lg-3"
      >
        <Card :item="item" :getCategoryName="store.getters.getCategoryName" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
