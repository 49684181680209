<template>
  <swiper
    ref="mySwiper"
    :modules="modules"
    :lazy="true"
    :slides-per-view="2"
    :slidesPerGroup="2"
    :space-between="10"
    :grid="{ rows: 3, fill: 'row' }"
    :navigation="false"
    :autoplay="{ delay: 10000, disableOnInteraction: false }"
    :scrollbar="{ draggable: true }"
  >
    <swiper-slide
      v-for="(item, index) in sortedItems"
      :key="index"
      class="auction-item"
    >
      <div
        v-if="!item.isPlaceholder"
        class="flex align-items-center"
        style="height: 100%"
      >
        <div class="col flex align-items-center justify-content-center">
          <img
            :src="item.resizedImages[1]?.url"
            :alt="item.name"
            loading="lazy"
            class="image shadow-6"
          />
          <div class="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
        </div>

        <div
          class="iteminfo flex align-items-center justify-content-center"
          style="width: 33.3%"
        >
          <div class="itemnumber pb-3">
            {{ item.itemNumber }}
            <div class="itemname">{{ item.name }}</div>
            <div v-if="item.creator" class="itemcreator">
              {{ item.creator }}
            </div>
          </div>
        </div>
        <div
          class="bids col flex align-items-center justify-content-center"
          style="width: 33.3%"
        >
          <div class="price-display-tv">
            <PriceDisplay
              :price="getDisplayedBid(item)"
              :currency="item.currency"
            />
            <div class="divider"></div>
            <h5
              v-if="item.isDonationItem && !item.latestBid"
              style="color: #ffffff"
            >
              Donation Item
            </h5>
            <h5
              v-else-if="item.isDonationItem && item.latestBid"
              style="color: #ffffff"
            >
              Latest Donation
            </h5>
            <h5 v-else-if="item.liveAuction" style="color: #ffffff">
              Live Auction
            </h5>
            <h5 v-else-if="item.latestBidder" style="color: #ffffff">
              Highest Bid
            </h5>
            <h5 v-else style="color: #ffffff">Starting Bid</h5>
            <div class="divider"></div>
            <h5 v-if="item.latestBidder" class="biddername">
              {{ item.latestBidder }}
            </h5>
            <h5 v-else>&nbsp;</h5>
          </div>
        </div>
      </div>
      <div v-else class="flex align-items-center" style="height: 100%">
        <div class="col flex align-items-center justify-content-center"></div>
      </div>
    </swiper-slide>
  </swiper>

  <div class="bidticker">
    <Vue3Marquee :duration="marqueeDuration">
      <span
        v-for="(donation, index) in donations"
        :key="index"
        class="donation"
        v-html="donation.message"
      ></span>
    </Vue3Marquee>
    <!-- Render the marquee slider only when donations are loaded -->
  </div>

  <Notification
    :message="currentNotification"
    :show="showNotification"
    @hidden="handleNotificationHidden"
  />
</template>

<script>
import { Vue3Marquee } from "vue3-marquee";
import { ref, onMounted, computed, watch, nextTick } from "vue";
import { db } from "@/firebase";
import {
  collection,
  collectionGroup,
  onSnapshot,
  query,
  orderBy,
  getDocs,
  where,
} from "firebase/firestore";
import Notification from "@/views/display/components/Notification.vue";
import PriceDisplay from "@/views/auction/components/DisplayPrice.vue";
import { formatPrice } from "@/services/formatPrice";

import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Grid,
  Autoplay,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";

export default {
  name: "AuctionSlider",
  components: {
    Notification,
    PriceDisplay,
    Swiper,
    SwiperSlide,
    Vue3Marquee,
  },
  setup() {
    const items = ref([]);
    const donations = ref([]);
    const notificationQueue = ref([]);
    const currentNotification = ref("");
    const showNotification = ref(false);
    const donationsLoaded = ref(false);
    const QUEUE_LIMIT = 100;
    const donationContainer = ref(null);
    const animationDuration = ref(40);

    // Capture the timestamp when the page is loaded
    const pageLoadTimestamp = new Date();

    // To track processed bids to avoid duplication
    const processedBids = new Set();

    const fetchItems = async () => {
      const q = query(collection(db, "auctionItems"), orderBy("itemNumber"));

      onSnapshot(q, (querySnapshot) => {
        const updatedItems = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        items.value = updatedItems;

        listenForBids();
        listenForDonations();
      });
    };

    // Efficiently listen for new bids using server-side filtering
    const listenForBids = () => {
      const bidsRef = collectionGroup(db, "bids");
      const bidsQuery = query(
        bidsRef,
        where("timestamp", ">", pageLoadTimestamp),
        orderBy("timestamp", "desc")
      );

      onSnapshot(bidsQuery, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            const newBid = change.doc.data();

            if (!processedBids.has(newBid.autoBidId)) {
              processedBids.add(newBid.autoBidId);

              const itemIndex = items.value.findIndex(
                (i) => i.id === newBid.itemID
              );

              if (itemIndex !== -1) {
                items.value[itemIndex] = {
                  ...items.value[itemIndex],
                  latestBid: newBid.userBid,
                  latestBidder: newBid.user.name,
                };

                const formattedPrice = formatPrice(
                  newBid.userBid,
                  newBid.currency || "SEK"
                );

                const notificationMessage = `
              <h2>#${newBid.itemNumber} - ${newBid.itemName}</h2>
              <h3>New bid of <span class="stronger">${formattedPrice}</span> placed by ${newBid.user.name}</h3>
            `;

                notificationQueue.value.push(notificationMessage);

                if (notificationQueue.value.length > QUEUE_LIMIT) {
                  notificationQueue.value.shift();
                }

                nextTick(() => {
                  currentNotification.value = notificationQueue.value[0];
                  showNotification.value = true;
                });
              }
            }
          }
        });
      });
    };

    const listenForDonations = async () => {
      try {
        const donationItemsQuery = query(
          collection(db, "auctionItems"),
          where("isDonationItem", "==", true)
        );

        const querySnapshot = await getDocs(donationItemsQuery);

        querySnapshot.forEach((doc) => {
          const itemId = doc.id;
          const purchasesRef = collection(
            db,
            `auctionItems/${itemId}/purchase`
          );
          const purchasesQuery = query(
            purchasesRef,
            orderBy("timestamp", "desc")
          );

          onSnapshot(purchasesQuery, (snapshot) => {
            snapshot.docChanges().forEach((change) => {
              if (change.type === "added") {
                const newDonation = change.doc.data();

                // Create a unique identifier for this donation
                const donationId = `${newDonation.userBid}-${newDonation.currency}-${newDonation.user.name}-${newDonation.timestamp}`;

                // Check if this donation already exists in the donations array
                const donationExists = donations.value.some(
                  (donation) => donation.id === donationId
                );

                if (!donationExists) {
                  donations.value.push({
                    id: donationId, // Store the unique identifier
                    message: `Donation of ${formatPrice(
                      newDonation.userBid,
                      newDonation.currency || "SEK"
                    )} by ${newDonation.user.name}`,
                  });

                  if (donations.value.length > QUEUE_LIMIT) {
                    donations.value.shift();
                  }

                  nextTick(() => {
                    calculateAnimationDuration();
                  });
                }
              }
            });

            donationsLoaded.value = true;
          });
        });
      } catch (error) {
        console.error("Error fetching donation items:", error);
      }
    };

    const calculateAnimationDuration = () => {
      const container = donationContainer.value;
      if (container) {
        const containerWidth = container.scrollWidth;
        const speed = 100;
        animationDuration.value = containerWidth / speed;
      }
    };

    const marqueeDuration = computed(() => {
      const baseDuration = 40;
      const additionalTime = 5 * Math.max(donations.value.length - 1, 0);
      return baseDuration + additionalTime;
    });

    onMounted(async () => {
      await fetchItems();
      calculateAnimationDuration();
    });

    const getDisplayedBid = (item) => {
      if (item.isDonationItem) {
        return item.latestBid || item.startbid;
      }
      return item.latestBid || item.startbid;
    };

    const sortedItems = computed(() => {
      const sorted = [...items.value].sort(
        (a, b) => a.itemNumber - b.itemNumber
      );
      const itemsPerSlide = 3;

      const emptyItemsCount =
        (itemsPerSlide - (sorted.length % itemsPerSlide)) % itemsPerSlide;

      for (let i = 0; i < emptyItemsCount; i++) {
        sorted.push({ isPlaceholder: true });
      }

      return sorted;
    });

    watch(notificationQueue, (newQueue) => {
      if (
        newQueue.length > 0 &&
        (!currentNotification.value ||
          currentNotification.value === newQueue[0])
      ) {
        currentNotification.value = newQueue[0];
        showNotification.value = true;
      }
    });

    const handleNotificationHidden = () => {
      showNotification.value = false;
      notificationQueue.value.shift();
      if (notificationQueue.value.length > 0) {
        setTimeout(() => {
          currentNotification.value = notificationQueue.value[0];
          showNotification.value = true;
        }, 500);
      } else {
        currentNotification.value = "";
        showNotification.value = false;
      }
    };

    return {
      sortedItems,
      getDisplayedBid,
      currentNotification,
      showNotification,
      notificationQueue,
      handleNotificationHidden,
      donations,
      donationContainer,
      animationDuration,
      donationsLoaded,
      marqueeDuration,
      modules: [Navigation, Pagination, Scrollbar, A11y, Grid, Autoplay],
    };
  },
};
</script>

<style>
html,
body,
#app {
  height: 100% !important;
  width: 100% !important;
  margin: 0;
  padding: 0;
}

.auction-slider {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  height: 100vh;
  background-color: #ffffff;
  padding: 20px;
}

.auction-item {
  background: #151f3f;
  padding: 20px;
  color: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.auction-item h3 {
  margin: 0 0 10px 10px;
}

.auction-item p {
  margin: 0;
  flex-grow: 1;
}

.auction-item p {
  max-height: 150px;
  overflow-y: auto;
}

.auction-item img {
  max-width: 100%;
  max-height: 250px;
  border: 2px solid #ffffff;
}

.itemnumber {
  font-size: 3rem;
  color: #ffffff;
}

.itemcreator {
  font-size: 1.2rem;
  color: #ffffff;
}

.itemname {
  font-size: 1.2rem;
  color: #ffffff;
}

.bounce-enter-active,
.bounce-leave-active {
  transition: opacity 0.5s;
}

.bounce-enter,
.bounce-leave-to {
  opacity: 0;
}

.stronger {
  font-weight: bold;
  color: #c39935;
}

.price-display-tv {
  width: 100%;
  background-color: #8a6b17;
  color: #fff;
  font-size: 2rem;
  border-radius: 5px;
  padding: 5px 15px 5px 15px;
  h5 {
    font-weight: 400;
    color: #fff;
  }
}

h5 {
  font-weight: 400;
  color: #fff;
}

.divider {
  display: block;
  border-color: rgba(255, 255, 255, 0.4);
  margin: 0.2em auto;
  border-style: solid;
  border-width: 1px;
}

.image {
  position: relative;
}

.swiper {
  width: 100%;
  height: 94%;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
}

.swiper-slide {
  padding: 0 20px;
  height: calc((100% - 20px) / 3) !important;
}

.bidticker {
  width: 100%;
  height: 6%;
  background-color: #8a6b17;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
}

.donation-container {
  display: inline-block;
  white-space: nowrap;
}

.donation {
  display: inline-block;
  margin-right: 60px;
  font-size: 1.8rem;
  color: #ffffff;
  white-space: nowrap;
}
</style>
