<script setup>
import { ref, onBeforeMount, onMounted, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import ArgonButton from "@/components/ArgonButton.vue";
//import ArgonInput from "@/components/ArgonInput.vue";
//import Media from "./components/Media.vue";
//import Socials from "./components/Socials.vue";
//import Pricing from "./components/Pricing.vue";
//import setNavPills from "@/assets/js/nav-pills.js";
import ArgonInput from "@/components/ArgonInput.vue";

import { collection, addDoc } from "firebase/firestore";
import { db } from "../../firebase"; // Ensure you have your Firebase config correctly imported

const router = useRouter();
const isCategoryNameError = ref(false);

const submitProduct = async () => {
  if (!categoryData.value.name.trim()) {
    isCategoryNameError.value = true; // Set error flag if name is empty
    return;
  } else {
    isCategoryNameError.value = false; // Reset error flag if validation passes
  }

  try {
    const docRef = await addDoc(collection(db, "category"), categoryData.value);
    console.log("Category created, with ID: ", docRef.id);
    localStorage.setItem("categoryMessage", "Category created");
    router.push({ name: "Auction Category List" });
  } catch (e) {
    console.error("Error adding document: ", e);
  }
};

const body = document.getElementsByTagName("body")[0];
const store = useStore();

const categoryData = ref({
  name: "",
  // Add other necessary fields if needed
});

onBeforeMount(() => {
  store.state.layout = "custom";
  store.state.showNavbar = false;
  store.state.showFooter = true;
  store.state.hideConfigButton = true;
});

onMounted(() => {
  store.state.isAbsolute = true;
  //setNavPills();
});

onBeforeUnmount(() => {
  store.state.isAbsolute = false;
  store.state.layout = "default";
  store.state.showNavbar = true;
  store.state.showFooter = true;
  store.state.hideConfigButton = false;
  body.classList.remove("new-product");
});
</script>
<template>
  <div class="card shadow-lg mx-4 card-profile-bottom">
    <div class="card-body p-3">
      <div class="row gx-4">
        <div class="col-auto my-auto">
          <div class="h-100">
            <h5 class="mb-1">Add new category</h5>
            <p class="mb-0 text-sm font-weight-bold">
              Enter category information
            </p>
          </div>
        </div>
        <div
          class="mx-auto mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0"
        >
          <div class="nav-wrapper position-relative end-0">
            <ul
              class="p-1 bg-transparent nav nav-pills nav-fill"
              role="tablist"
            ></ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="multisteps-form">
          <!--form panels-->
          <form
            class="mb-8 multisteps-form__form"
            @submit.prevent="submitProduct"
          >
            <div id="delete" class="card mt-4">
              <div class="card-header col-6 col-sm-6">
                <label>Category Name</label>
                <ArgonInput
                  class="multisteps-form__input"
                  type="text"
                  placeholder="Category Name"
                  v-model="categoryData.name"
                  :error="isCategoryNameError"
                />
                <!-- Show error message -->
                <div v-if="isCategoryNameError" class="text-danger">
                  Please enter a category name.
                </div>
              </div>
              <div class="card-body d-sm-flex pt-0">
                <argon-button
                  type="submit"
                  color="primary"
                  variant="gradient"
                  class="btn mb-0 btn-outline-secondary btn-md"
                  title="Submit"
                  >Save</argon-button
                >
              </div>
            </div>
          </form>
          <!-- form end-->
        </div>
      </div>
    </div>
  </div>
</template>
