<template>
  <div v-if="userDocId === 'Qs8XUJssueRKJiGzZEfN'">
    <button @click="deleteAllBids('xfcVctSLve25KUXx6FU5')">Delete All Bids</button>
    <button @click="notifyUser('+46703623623', 'You have been outbid!', 'item123', userDocId)">Notify User</button>
    <button @click="cancelNotification('item123')">Cancel Notification</button>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, watch, onUnmounted } from "vue";
import {
  collection,
  query,
  onSnapshot,
  doc,
  updateDoc,
  getDocs,
  writeBatch,
  deleteField,
  addDoc,
  setDoc,
  getDoc,
  where,
  deleteDoc,
} from "firebase/firestore";
import { useStore } from "vuex";
import { db } from "@/firebase";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { useRouter } from "vue-router";

const notifications = ref([]);
const store = useStore();
const router = useRouter();

const userDocId = computed(() => store.getters['userDocId']);
const shownNotifications = ref(new Set());

import NotificationMessage from "@/views/auction/components/NotificationMessage.vue";

let unsubscribe = null;

const deleteAllBids = async (auctionItemId) => {
  const auctionItemRef = doc(db, "auctionItems", auctionItemId);
  const bidsRef = collection(auctionItemRef, "bids");
  const querySnapshot = await getDocs(bidsRef);

  const batch = writeBatch(db);
  querySnapshot.forEach((bidDoc) => {
    batch.delete(bidDoc.ref);
  });

  await batch.commit();
  console.log(`All bids for auction item ${auctionItemId} have been deleted.`);

  await updateDoc(auctionItemRef, {
    latestBidder: deleteField(),
    latestBid: deleteField(),
    latestBidderID: deleteField(),
  });
  console.log(
    `Fields latestBidder, latestBid, and latestBidderID have been removed from auction item ${auctionItemId}.`
  );
};

const notifyUser = async (phoneNumber, message, itemId, userId) => {
  try {
    const notificationsRef = collection(db, "notifications");
    const notification = {
      phoneNumber,
      message,
      itemId,
      userId,
      timestamp: Date.now(),
      read: false,
    };
    const notificationDoc = await addDoc(notificationsRef, notification);

    const userNotificationsRef = doc(db, "users", userId, "notifications", notificationDoc.id);
    await setDoc(userNotificationsRef, notification);
    return notificationDoc.id;
  } catch (error) {
    console.error("Error adding notification:", error);
  }
};

const cancelNotification = async (itemId) => {
  try {
    const notificationsRef = collection(db, "notifications");
    const q = query(notificationsRef, where("itemId", "==", itemId));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      deleteDoc(doc.ref);
    });
    console.log("Notification canceled for item:", itemId);
  } catch (error) {
    console.error("Error canceling notification:", error);
  }
};

const deleteNotification = async (notificationId, userId) => {
  try {
    const notificationRef = doc(db, "notifications", notificationId);
    await deleteDoc(notificationRef);

    const userNotificationRef = doc(db, "users", userId, "notifications", notificationId);
    await deleteDoc(userNotificationRef);

  } catch (error) {
    console.error("Error deleting notification:", error);
  }
};

const setupNotificationListener = (userDocId) => {
  if (unsubscribe) {
    unsubscribe();
  }

  const notificationsRef = collection(db, "users", userDocId, "notifications");
  const q = query(notificationsRef);

  unsubscribe = onSnapshot(q, (querySnapshot) => {
    const newNotifications = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
      userDocId: userDocId,
    }));

    newNotifications.forEach((notification) => {
      if (!notification.read && !shownNotifications.value.has(notification.id)) {
        shownNotifications.value.add(notification.id);
        toast(NotificationMessage, {
          closeOnClick: true,
          autoClose: 5000,
          type: "info",
          theme: "light",
          limit: 1,
          position: toast.POSITION.TOP_RIGHT,
          data: {
            message: notification.message,
            timestamp: notification.timestamp,
            itemName: notification.itemName,
            itemID: notification.itemID,
          },
          onClick: async () => {
            const notificationDocRef = doc(
              db,
              `users/${userDocId}/notifications/${notification.id}`
            );
            const docSnapshot = await getDoc(notificationDocRef);
            if (docSnapshot.exists()) {
              await updateDoc(notificationDocRef, {
                read: true,
              });
              await deleteNotification(notification.id, userDocId);
              router.push({
                name: "View Auction Item",
                params: { id: notification.itemID },
              });
            }
          },
          onClose: async () => {
            const notificationDocRef = doc(
              db,
              `users/${userDocId}/notifications/${notification.id}`
            );
            const docSnapshot = await getDoc(notificationDocRef);
            if (docSnapshot.exists()) {
              await updateDoc(notificationDocRef, {
                read: true,
              });
              await deleteNotification(notification.id, userDocId);
            }
          },
        });
      }
    });

    notifications.value = newNotifications;
  });
};

const initializeListener = () => {
  if (userDocId.value && !unsubscribe) {
    shownNotifications.value.clear();
    setupNotificationListener(userDocId.value);
  }
};

onMounted(() => {
  initializeListener();
  router.afterEach(() => {
    shownNotifications.value.clear();
    initializeListener();
  });
});

watch(userDocId, (newUserDocId) => {
  if (newUserDocId && !unsubscribe) {
    shownNotifications.value.clear();
    setupNotificationListener(newUserDocId);
  }
}, { immediate: true });

watch(router.currentRoute, () => {
  shownNotifications.value.clear();
  initializeListener();
});

onUnmounted(() => {
  if (unsubscribe) {
    unsubscribe();
  }
});
</script>

<style scoped>
/* Add your styles here */
</style>
