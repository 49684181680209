<template>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-lg-6">
          <h4 class="text-white">{{ sponsorData.id ? "Edit Sponsor" : "Add Sponsor" }}</h4>
        </div>
        <div class="text-right col-lg-6 d-flex flex-column justify-content-center">
          <button type="button" class="mt-2 mb-0 btn btn-outline-black ms-lg-auto me-lg-0 me-auto mt-lg-0" @click="saveSponsor" :disabled="isUploading">
            Save
          </button>
        </div>
      </div>
      <div class="mt-4 row">
        <div class="col-lg-4">
          <div class="card h-100">
            <div class="card-body">
              <h5 class="font-weight-bolder">Sponsor Image</h5>
              <div class="row">
                <div class="col-12">
                  <img class="mt-3 shadow-lg w-100 border-radius-lg" :src="sponsorData.imageUrl" alt="sponsor_image" v-if="sponsorData.imageUrl" />
                </div>
                <div class="mt-5 col-12">
                  <div class="image-forms row" v-if="!sponsorData.imageUrl || sponsorData.imageUrl === placeholderImageUrl">
                    <div class="col">
                      <file-pond ref="pond" name="filepond" label-idle="Drag & Drop your image or <span class='filepond--label-action'>Browse</span>" :allow-multiple="false" :server="pondOptions"></file-pond>
                    </div>
                    <div class="col"></div>
                  </div>
    
                  <button class="mb-0 btn btn-outline-dark btn-sm" type="button" @click="deleteImage" v-if="sponsorData.imageUrl && sponsorData.imageUrl !== placeholderImageUrl">
                    Remove
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-4 col-lg-8 mt-lg-0">
          <div class="card">
            <div class="card-body">
              <h5 class="font-weight-bolder">Sponsor Information</h5>
              <div class="row">
                <div class="col-12 col-sm-6">
                  <label>Name</label>
                  <input class="form-control" type="text" v-model="sponsorData.name" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
    
    <script setup>
    import { ref, onMounted, watch } from "vue";
    import { useRoute, useRouter } from "vue-router";
    import { doc, getDoc, addDoc, updateDoc, collection } from "firebase/firestore";
    import { ref as storageRef, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
    import { db, storage } from "@/firebase";
    import vueFilePond from 'vue-filepond';
    import 'filepond/dist/filepond.min.css';
    import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
    import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
    import pica from 'pica';
    
    const FilePond = vueFilePond(FilePondPluginImagePreview);
    const route = useRoute();
    const router = useRouter();
    
    const sponsorData = ref({
      name: "",
      imageUrl: "",
      resizedImages: []
    });
    const placeholderImageUrl = "";
    const isUploading = ref(false); // Add this state
    const pondOptions = {
      process: (fieldName, file, metadata, load, error, abort) => {
        isUploading.value = true; // Set uploading status to true
        const originalFile = file;
        Promise.all([
          resizeImage(originalFile, 300),
          resizeImage(originalFile, 600),
          resizeImage(originalFile, 1200)
        ])
        .then(async (resizedImages) => {
          const uploadPromises = resizedImages.map((resizedImage) => uploadToFirebase(resizedImage));
          const urls = await Promise.all(uploadPromises);
      
          sponsorData.value.imageUrl = urls[0];
          sponsorData.value.resizedImages = urls.map((url, index) => ({
            size: [300, 600, 1200][index],
            url
          }));
      
          load(urls[0]);
        })
        .catch((err) => {
          error(err.message);
        })
        .finally(() => {
          isUploading.value = false; // Set uploading status to false
        });
      
        return {
          abort: () => {
            abort();
            isUploading.value = false; // Set uploading status to false
          }
        };
      }
    };
    
    const initializeSponsorData = async () => {
      if (route.params.id) {
        const docRef = doc(db, "sponsors", route.params.id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          sponsorData.value = { id: docSnap.id, ...docSnap.data() };
        }
      }
    };
    
    const resizeImage = (file, size) => {
      const img = new Image();
      const picaInstance = pica();
      
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          img.src = event.target.result;
          img.onload = async () => {
            try {
              const canvas = document.createElement("canvas");
              const scaleFactor = size / img.width;
              canvas.width = size;
              canvas.height = img.height * scaleFactor;
      
              await picaInstance.resize(img, canvas);
              const blob = await picaInstance.toBlob(canvas, "image/jpeg", 0.90);
              const resizedImage = new File([blob], `${file.name}-${size}.jpg`, { type: "image/jpeg" });
      
              resolve(resizedImage);
            } catch (err) {
              reject(err);
            }
          };
          img.onerror = (err) => {
            reject(err);
          };
        };
        reader.onerror = (err) => {
          reject(err);
        };
      
        reader.readAsDataURL(file);
      });
    };
      
    const uploadToFirebase = (file) => {
      const storageReference = storageRef(storage, `sponsors/${file.name}`);
      const uploadTask = uploadBytesResumable(storageReference, file);
      
      return new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          () => {},
          (err) => {
            reject(err);
          },
          async () => {
            try {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              resolve(downloadURL);
            } catch (err) {
              reject(err);
            }
          }
        );
      });
    };
      
    const deleteImage = async () => {
      if (!sponsorData.value.imageUrl || sponsorData.value.imageUrl === placeholderImageUrl) {
        alert("No image to delete");
        return;
      }
      
      const imageRef = storageRef(storage, sponsorData.value.imageUrl.replace(storage.bucket, ""));
      try {
        await deleteObject(imageRef);
      } catch (error) {
        if (error.code !== "storage/object-not-found") {
          console.error("Error removing image:", error);
          return;
        }
      }
      
      for (const { size, url } of sponsorData.value.resizedImages) {
        const resizedImageRef = storageRef(storage, url.replace(storage.bucket, ""));
        try {
          await deleteObject(resizedImageRef);
        } catch (error) {
          if (error.code !== "storage/object-not-found") {
            console.error(`Error removing resized image ${size}:`, error);
            return;
          }
        }
      }
      
      try {
        if (sponsorData.value.id) {
          const sponsorRef = doc(db, "sponsors", sponsorData.value.id);
          await updateDoc(sponsorRef, {
            imageUrl: placeholderImageUrl,
            resizedImages: []
          });
        }
      
        sponsorData.value.imageUrl = placeholderImageUrl;
        sponsorData.value.resizedImages = [];
      } catch (error) {
        console.error("Error updating Firestore document:", error);
      }
    };
      
    const saveSponsor = async () => {
      try {
        if (!sponsorData.value.id) {
          const newDocRef = await addDoc(collection(db, "sponsors"), {
            name: sponsorData.value.name,
            imageUrl: sponsorData.value.imageUrl,
            resizedImages: sponsorData.value.resizedImages
          });
          sponsorData.value.id = newDocRef.id;
        } else {
          const sponsorRef = doc(db, "sponsors", sponsorData.value.id);
          await updateDoc(sponsorRef, {
            name: sponsorData.value.name,
            imageUrl: sponsorData.value.imageUrl,
            resizedImages: sponsorData.value.resizedImages
          });
        }
      
        router.push({ name: "List Sponsors" });
      } catch (error) {
        console.error("Error saving sponsor:", error);
      }
    };
      
    onMounted(initializeSponsorData);
    watch(() => route.params.id, initializeSponsorData);
    </script>
    
    
  <style>
  @import 'filepond/dist/filepond.min.css';
  @import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
  </style>
  