<template>
  <div>
    <!-- This component doesn't need to render anything -->
  </div>
</template>

<script setup>
import { onMounted, onUnmounted } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();

const pages = [
  { name: "Display All Auction Items", delay: 3300000 }, // 55 minutes
  { name: "Display Latest Bids", delay: 240000 }, // 4 minutes
  { name: "Display Summary", delay: 60000 }, // 1 minute
];

let timeoutId = null;

const navigateToPages = (index) => {
  if (index >= pages.length) {
    index = 0; // Loop back to the first page
  }

  const page = pages[index];

  router
    .push({ name: page.name })
    .then(() => {
      timeoutId = setTimeout(() => {
        navigateToPages(index + 1);
      }, page.delay);
    })
    .catch((error) => {
      console.error(`Error navigating to ${page.name}:`, error);
      // Optionally, skip to the next page on error
      navigateToPages(index + 1);
    });
};

onMounted(() => {
  navigateToPages(0);
});

onUnmounted(() => {
  if (timeoutId) {
    clearTimeout(timeoutId);
  }
});
</script>

<style scoped>
/* Add any specific styles if necessary */
</style>